import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Field } from "formik"
import { TextField, Select } from "formik-material-ui"
import { Box, Container, Grid, Typography, FormControl, InputLabel, MenuItem } from "@material-ui/core"

import { AppState } from "../../store"
import { ScreenActions } from "../../store/screen/actions"
import { IScreenType } from "../../interfaces/screen"
import * as Styled from "./styled"

import Header from "./Header"

interface IScreenEditProps {
  fetchScreenTypes: () => void
  screenTypes: IScreenType[]
}

const ScreenEdit = ({ screenTypes, fetchScreenTypes }: IScreenEditProps) => {

  useEffect(() => {

    fetchScreenTypes()

  }, [fetchScreenTypes])

  if (!screenTypes) return null

  return (
    <>
      <Header />
      <Container>
        <Styled.Container>

          <Box pb={4}>
            <Typography variant="h2" gutterBottom>Screen Types</Typography>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="screenTypeId">Select a screen type</InputLabel>
                  <Field
                    component={Select}
                    label="Select a screen type"
                    name="screenTypeId"
                    inputProps={{
                      id: 'screenTypeId',
                    }}
                  >
                    {screenTypes && screenTypes.map((item: IScreenType, index: number) => (
                      <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Typography variant="h2" gutterBottom>Shade</Typography>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Field component={TextField} name="par" label="Par" variant="outlined" type="text" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <Field component={TextField} name="es" label="Es" variant="outlined" type="text" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <Field component={TextField} name="nir" label="Nir" variant="outlined" type="text" fullWidth />
            </Grid>
          </Grid>

        </Styled.Container>
      </Container>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  screenTypes: state.screens.screenTypes,
})

export default connect(
  mapStateToProps,
  {
    fetchScreenTypes: () => ScreenActions.FetchTypes(),
  }
)(ScreenEdit)

