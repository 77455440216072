import { Dispatch } from "redux"
import { KuboStructureService } from "../../services/api/Kubo/StructureService"
import * as Types from "./types"

export const StructureActions = {
  FetchAll: () => {
    return async (dispatch: Dispatch) => {
      const data = await KuboStructureService.FetchAll()
      dispatch(StructureActions.dispatchFetchAll(data))
      return data
    }
  },

  dispatchFetchAll: (data: any) => ({
    type: Types.STRUCTURE_FETCHALL,
    payload: data
  }),
}