import React, { useState } from "react"
import { connect } from 'react-redux'
import * as Styled from "./styled"
import { Container } from "@material-ui/core"
import { AppState } from '../../store'
import { IScenario } from "../../interfaces/scenario"
import { ScenarioActions } from "../../store/scenario/actions"

import EditHeader from "./EditHeader"
import LocationTab from "./LocationTab"
import GreenhouseTab from "./GreenhouseTab"
import CropTab from "./CropTab"
import GraphDataTab from "./GraphDataTab"
import StrategyTab from "./StrategyTab"
import { unflatten } from "flat"
import { useNotify } from "../../Hooks"

interface IScenarioEditProps {
  history: any
  scenario: IScenario
  setFieldValue: any
  handleSubmit: any
  values: any
  setValues: any
  activeTab: string
  setActiveTab: (tab: string) => void
  disabledForm: boolean
}

const ScenarioEdit = (props: IScenarioEditProps) => {
  const notify = useNotify()
  const [openTab, setOpenTab] = useState('tab-location')

  const handleChange = (e: any, tabId: string) => {
    e.preventDefault()
    const { values } = props
    props.setActiveTab('scenario')

    // values-location = null
    // values-location-lat = filled
    // unflatten does not add further depth after null
    delete values["location"]
    const objectValues: any = unflatten(values, {delimiter: "-"})

    switch (tabId) {
      case 'tab-greenhouse':
      case 'tab-graph-data':
      case 'tab-crops':
      case 'tab-strategy':
        if (!objectValues?.location?.city) {
          notify("You must set a location before you continue", "error")
          return false;
        }
      break;
    }

    setOpenTab(tabId)
  }

  const goToGraph = () => {
    props.setActiveTab('graph')
  }

  const activeTabClass = (name: string) => {
    if (name === openTab && props.activeTab === 'scenario') {
      return 'active'
    }
  }

  const { scenario } = props
  let calculation = scenario ? (scenario.scenarioType === 1 ? true : false) : false

  const tabs = [
    {id: 'tab-location', name: 'Location', display: true, component: <LocationTab { ...props }/>},
    {id: 'tab-graph-data', name: 'Graph data', display: !calculation, component: <GraphDataTab {...props} />},
    {id: 'tab-greenhouse', name: 'Greenhouse', display: calculation, component: <GreenhouseTab {...props} />},
    {id: 'tab-crops', name: 'Crops', display: calculation, component: <CropTab {...props} />},
    {id: 'tab-strategy', name: 'Strategy', display: calculation, component: <StrategyTab {...props} />},
  ]

  return (
    <>
      <EditHeader disabledForm={props.disabledForm} />
      <Styled.Container>
        <Container>
          <Styled.NavigationItems>
            { tabs.map((tabItem, key) =>
              <div key={"tabLink" + key}>
                { tabItem.display &&
                  <Styled.Tab
                    key={tabItem.id + key}
                    className={activeTabClass(tabItem.id)}
                    onClick={(e: any) => handleChange(e, tabItem.id)}>
                      {tabItem.name}
                  </Styled.Tab>
                }
              </div>
            )}
            { scenario.state === 'Finished' && (
              <div key="tabLink">
                <Styled.Tab
                  className={props.activeTab === 'graph' ? 'active' : ''}
                  onClick={goToGraph}>
                    Graphs
                </Styled.Tab>
              </div>
            )}
          </Styled.NavigationItems>
        </Container>
      </Styled.Container>

      <Container>
        { tabs.map((tabItem, index) =>
          <div key={"tabContentContainer" + index}>
            { tabItem.display &&
              <Styled.TabContent id={tabItem.id} className={activeTabClass(tabItem.id)}>
                {tabItem.component}
              </Styled.TabContent>
            }
          </div>
        )}
      </Container>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  activeTab: state.scenarios.activeTab,
})

export default connect(
  mapStateToProps,
  {
    setActiveTab: (tab: string) => ScenarioActions.SetActiveTab(tab),
  }
)(ScenarioEdit)

