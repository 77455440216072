import { Dispatch } from "redux"
import { KuboScenarioService } from "../../services/api/Kubo/ScenarioService"
import * as Types from "./types"
import { IContent, IScenario, ILocation } from "../../interfaces/scenario"

export const ScenarioActions = {
  Fetch: (id: number) => {
    return async (dispatch: Dispatch) => {
      const data = await KuboScenarioService.Fetch(id)
      dispatch(ScenarioActions.dispatchFetch(data))
    }
  },

  FetchAll: () => {
    return async (dispatch: Dispatch) => {
      const data = await KuboScenarioService.FetchAll()
      dispatch(ScenarioActions.dispatchFetchAll(data))
    }
  },

  FetchLocations: () => {
    return async (dispatch: Dispatch) => {
      const data = await KuboScenarioService.FetchLocations()
      dispatch(ScenarioActions.dispatchFetchLocations(data))
    }
  },

  FetchPlaceholders: (id: number) => {
    return async (dispatch: Dispatch) => {
      const data = await KuboScenarioService.FetchPlaceholders(id)
      return data
    }
  },

  FetchContent: (id: number) => {
    return async (dispatch: Dispatch) => {
      const data = await KuboScenarioService.FetchContent(id)
      dispatch(ScenarioActions.dispatchFetchContent(data))
    }
  },

  UpdateContent: (content: IContent) => {
    return async (dispatch: Dispatch) => {
      return await KuboScenarioService.UpdateContent(content)
    }
  },

  Create: (scenarioType: "climate"  | "calculation", params: any) => {
    return async (dispatch: Dispatch) => {
      if (scenarioType === 'climate') {
        return await KuboScenarioService.CreateClimateScenario(params)
      } else {
        return await KuboScenarioService.CreateCalculationScenario(params)
      }
    }
  },

  Update: (scenario: IScenario) => {
    return async (dispatch: Dispatch) => {
      return await KuboScenarioService.UpdateScenario(scenario)
    }
  },
  
  Run: (id: number) => {
    return async () => {
      return await KuboScenarioService.RunScenario(id)
    }
  },

  Delete: (id: number) => {
    return async (dispatch: Dispatch) => {
      return await KuboScenarioService.DeleteScenario(id)
    }
  },

  Duplicate: (id: number) => {
    return async (dispatch: Dispatch) => {
      return await KuboScenarioService.DuplicateScenario(id)
    }
  },

  SetLocation: (location: ILocation) => {
    return async (dispatch: Dispatch) => {
      dispatch(ScenarioActions.dispatchSetLocation(location))
    }
  },

  SetActiveTab: (tab: string) => {
    return async (dispatch: Dispatch) => {
      dispatch(ScenarioActions.dispatchActiveTab(tab))
    }
  },

  dispatchActiveTab: (payload: any) => ({
    type: Types.SCENARIO_ACTIVE_TAB,
    payload,
  }),

  dispatchFetch: (data: any) => ({
    type: Types.SCENARIO_FETCH,
    payload: data
  }),

  dispatchFetchAll: (data: any) => ({
    type: Types.SCENARIO_FETCHALL,
    payload: data
  }),

  dispatchFetchLocations: (data: any) => ({
    type: Types.SCENARIO_FETCH_LOCATIONS,
    payload: data
  }),

  dispatchSetLocation: (data: any) => ({
    type: Types.SCENARIO_SET_LOCATION,
    payload: data
  }),

  dispatchFetchContent: (data: any) => ({
    type: Types.SCENARIO_FETCH_CONTENT,
    payload: data
  })

}
