import React from "react"
import _ from 'lodash'
import * as Styled from './styled'

import { IReport, IGraphItem, ITextItem } from '../../interfaces/graph'

import * as Charts from './Charts'
import * as Reports from './Reports'
import TextItem from './TextItem'

interface IGraphListProps {
  listing: IReport | undefined
}

const GraphList = ({ listing }: IGraphListProps) => {
  if (!listing || !listing.graphs) return null

  const graphTemperatureDistribution = _.find<IGraphItem[]>(listing.graphs, { key: "TemperatureDistribution" }) as IGraphItem || {} as IGraphItem
  const graphDayLengthAndRadiation = _.find<IGraphItem[]>(listing.graphs, { key: "DayLengthAndRadiation" }) as IGraphItem || {} as IGraphItem
  const graphHumidityLevel = _.find<IGraphItem[]>(listing.graphs, { key: "HumidityLevel" }) as IGraphItem || {} as IGraphItem
  const graphPrecepitationLevels = _.find<IGraphItem[]>(listing.graphs, { key: "Precipitation" }) as IGraphItem || {} as IGraphItem
  const graphTemperaturesExtremeCold = _.find<IGraphItem[]>(listing.graphs, { key: "TemperaturesExtremeCold" }) as IGraphItem || {} as IGraphItem
  const graphTemperaturesExtremeWarm = _.find<IGraphItem[]>(listing.graphs, { key: "TemperaturesExtremeWarm" }) as IGraphItem || {} as IGraphItem
  const graphTemperatureTrendAverage = _.find<IGraphItem[]>(listing.graphs, { key: "TemperatureTrendAverage" }) as IGraphItem || {} as IGraphItem
  const graphTemperatureTrendMinimum = _.find<IGraphItem[]>(listing.graphs, { key: "TemperatureTrendMinimum" }) as IGraphItem || {} as IGraphItem
  const graphTemperatureTrendMaximum = _.find<IGraphItem[]>(listing.graphs, { key: "TemperatureTrendMaximum" }) as IGraphItem || {} as IGraphItem
  const graphTemperatureHumidityScatterDay = _.find<IGraphItem[]>(listing.graphs, { key: "TemperatureHumidityScatterDay" }) as IGraphItem || {} as IGraphItem
  const graphTemperatureHumidityScatterNight = _.find<IGraphItem[]>(listing.graphs, { key: "TemperatureHumidityScatterNight" }) as IGraphItem || {} as IGraphItem
  const graphRadiationOnTheCrop = _.find<IGraphItem[]>(listing.graphs, { key: "HeatEnergyRequirement" }) as IGraphItem || {} as IGraphItem
  const graphEquipmentHeatDelivery = _.find<IGraphItem[]>(listing.graphs, { key: "EquipmentHeatDelivery" }) as IGraphItem || {} as IGraphItem
  const graphEquipmentContributionHeat = _.find<IGraphItem[]>(listing.graphs, { key: "EquipmentContributionHeat" }) as IGraphItem || {} as IGraphItem
  const graphPrimarySourceHeatDelivery = _.find<IGraphItem[]>(listing.graphs, { key: "PrimarySourceHeatDelivery" }) as IGraphItem || {} as IGraphItem
  const graphNaturalGasDemand = _.find<IGraphItem[]>(listing.graphs, { key: "NaturalGasDemand" }) as IGraphItem || {} as IGraphItem
  const graphCO2Demand = _.find<IGraphItem[]>(listing.graphs, { key: "CO2Demand" }) as IGraphItem || {} as IGraphItem
  const graphEquipmentElectricalDelivery = _.find<IGraphItem[]>(listing.graphs, { key: "EquipmentElectricalDelivery" }) as IGraphItem || {} as IGraphItem
  const graphPrimarySourceElectricityDelivery = _.find<IGraphItem[]>(listing.graphs, { key: "PrimarySourceElectricalDelivery" }) as IGraphItem || {} as IGraphItem
  const graphEquipmentContributionElectricalDemand = _.find<IGraphItem[]>(listing.graphs, { key: "EquipmentContributionElectricalDemand" }) as IGraphItem || {} as IGraphItem
  const graphEnergyAndCostBalance = _.find<IGraphItem[]>(listing.graphs, { key: "EnergyAndCostBalance" }) as IGraphItem || {} as IGraphItem

  const textGeneralText1 = _.find<ITextItem[]>(listing.texts, { key: "GENERALTEXT_1", visible: true}) as ITextItem || {} as ITextItem
  const textGeneralText2 = _.find<ITextItem[]>(listing.texts, { key: "GENERALTEXT_2", visible: true}) as ITextItem || {} as ITextItem
  const textGeneralText3 = _.find<ITextItem[]>(listing.texts, { key: "GENERALTEXT_3", visible: true}) as ITextItem || {} as ITextItem
  const textGeneralText4 = _.find<ITextItem[]>(listing.texts, { key: "GENERALTEXT_4", visible: true}) as ITextItem || {} as ITextItem


  return (
    <Styled.Wrapper>
      { textGeneralText1 && <TextItem {...textGeneralText1} pageBreakAfter /> }
      { textGeneralText2 && <TextItem {...textGeneralText2} /> }
      {/* GRAPH 1 */}
      { graphRadiationOnTheCrop.resultData && <Reports.RadiationOnTheCrop {...graphRadiationOnTheCrop}  /> }
      {/* GRAPH 2 */}
      { graphEquipmentHeatDelivery.resultData && <Reports.EquipmentHeatDelivery {...graphEquipmentHeatDelivery} /> } 
      {/* GRAPH 3 */}
      { graphEquipmentContributionHeat.resultData && <Reports.HeatEnergyPie {...graphEquipmentContributionHeat} />}
      {/* GRAPH 4 */}
      { graphPrimarySourceHeatDelivery.resultData && <Reports.HeatPrimarySource {...graphPrimarySourceHeatDelivery} /> }
      {/* GRAPH 5 */}
      { graphNaturalGasDemand.resultData && <Reports.NaturalGasDemand {...graphNaturalGasDemand} /> }
      {/* GRAPH 6 */}
      { graphCO2Demand.resultData && <Reports.CO2Demand {...graphCO2Demand} /> }
      
      { textGeneralText3 && <TextItem {...textGeneralText3} /> }
      {/* GRAPH 7 */}
      { graphEquipmentElectricalDelivery.resultData && <Reports.ElectricityRequirement {...graphEquipmentElectricalDelivery} /> }
      {/* GRAPH 8 */}
      { graphEquipmentContributionElectricalDemand.resultData && <Reports.ElectricityPie {...graphEquipmentContributionElectricalDemand} /> } 
      {/* GRAPH 9 */}
      { graphPrimarySourceElectricityDelivery.resultData && <Reports.ElectricityPrimarySource {...graphPrimarySourceElectricityDelivery} />}  
      {/* GRAPH 10 */}
      { graphEnergyAndCostBalance.resultData && <Reports.EnergyCosts {...graphEnergyAndCostBalance} />  }
      
      { textGeneralText4 && <TextItem {...textGeneralText4} /> }
      {/* GRAPH 11 */}
      { graphTemperatureDistribution.resultData && <Charts.TemperatureDistribution {...graphTemperatureDistribution} /> }
      {/* GRAPH 12 */}
      { graphDayLengthAndRadiation.resultData && <Charts.DayLengthAndRadiation {...graphDayLengthAndRadiation} /> }
      {/* GRAPH 13 */}
      { graphHumidityLevel.resultData && <Charts.HumidityLevels {...graphHumidityLevel} /> }
      {/* GRAPH 14 */}
      { graphPrecepitationLevels.resultData && <Charts.PrecepitationLevels {...graphPrecepitationLevels} /> }
      {/* GRAPH 15 */}
      { graphTemperaturesExtremeCold.resultData && <Charts.TemperaturesExtremeCold {...graphTemperaturesExtremeCold} /> }
      {/* GRAPH 16 */}
      { graphTemperaturesExtremeWarm.resultData && <Charts.TemperaturesExtremeWarm {...graphTemperaturesExtremeWarm} /> }
      {/* GRAPH 17 */}
      { graphTemperatureTrendAverage.resultData && <Charts.TemperatureTrendAverage {...graphTemperatureTrendAverage} /> }
      {/* GRAPH 18 */}
      { graphTemperatureTrendMinimum.resultData && <Charts.TemperatureTrendMinimum {...graphTemperatureTrendMinimum} /> }
      {/* GRAPH 19 */}
      { graphTemperatureTrendMaximum.resultData && <Charts.TemperatureTrendMaximum {...graphTemperatureTrendMaximum} /> }
      {/* GRAPH 20 */}
      { graphTemperatureHumidityScatterDay.resultData && <Charts.TemperatureHumidity {...graphTemperatureHumidityScatterDay} /> }
      {/* GRAPH 21 */}
      { graphTemperatureHumidityScatterNight.resultData && <Charts.TemperatureHumidity {...graphTemperatureHumidityScatterNight} /> }
      
    </Styled.Wrapper>
  )
}

export default GraphList
