import React from 'react'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import { monthColumns } from '../../../../helpers'
import * as Styled from '../../styled'
import { IGraphItem } from '../../../../interfaces/graph'

interface ICO2DemandRow {
  month: number
  co2Available: number
  co2Missing: number
  co2Desired: number
}

export const CO2Demand = ({ resultData: data, description, name, pageBreakAfter }: IGraphItem) => {

  let responseObj: any = {}
  responseObj.cols = [
    { id: 'month', label: 'Month', type: 'date' },
    { id: 'co2Available', label: 'Available CO2', type: 'number' },
    { id: 'tooltip1', label: null, role: 'tooltip', 'p': { 'html': true } },
    { id: 'co2Missing', label: 'Additional CO2 Required', type: 'number' },
    { id: 'tooltip2', label: null, role: 'tooltip', 'p': { 'html': true } },
    { id: 'co2Desired', label: 'Desired CO2', type: 'number' },
    { id: 'tooltip3', label: null, role: 'tooltip', 'p': { 'html': true } },
  ]

  responseObj.rows = data.map((obj: ICO2DemandRow) => ({
    c: [
      { v: new Date('2000/' + obj.month + '/01') },
      { v: obj.co2Available },
      { v: `<div style="padding:5px; white-space: nowrap;">
              <strong>Avail: ${obj.co2Available > 0 ? Number(obj.co2Available).toFixed(3) : 0}</strong><br />
              Add: ${(obj.co2Missing > 0 ? Number(obj.co2Missing).toFixed(3) : 0)}<br />
              Desired: ${(obj.co2Desired > 0 ? Number(obj.co2Desired).toFixed(3) : 0)}<br />
            </div>` },
      { v: obj.co2Missing },
      { v: `<div style="padding:5px; white-space: nowrap;">
              Avail: ${obj.co2Available > 0 ? Number(obj.co2Available).toFixed(3) : 0}<br />
              <strong>Add: ${(obj.co2Missing > 0 ? Number(obj.co2Missing).toFixed(3) : 0)}</strong><br />
              Desired: ${(obj.co2Desired > 0 ? Number(obj.co2Desired).toFixed(3) : 0)}<br />
            </div>` },
      { v: obj.co2Desired },
      { v: `<div style="padding:5px; white-space: nowrap;">
              Avail: ${obj.co2Available > 0 ? Number(obj.co2Available).toFixed(3) : 0}<br />
              Add: ${(obj.co2Missing > 0 ? Number(obj.co2Missing).toFixed(3) : 0)}<br />
              <strong>Desired: ${(obj.co2Desired > 0 ? Number(obj.co2Desired).toFixed(3) : 0)}</strong><br />
            </div>` },
    ],
  }))

  const options = {
    height: 400,
    width: 900,
    hAxis: {
      title: 'Month',
      titleTextStyle: {color: '#333'},
      format: 'MMM',
    },
    seriesType: 'area',
    colors: ['gold', 'grey'],
    isStacked: true,
    vAxis: {
      title: 'CO2 [Kg/m2]',
    },
    series: {2: {type: 'line'}},
    tooltip: { isHtml: true },
  }

  let dataTable: any = {}
  dataTable.cols = [
    {type: 'string', label: 'Source', id: '0'},
    ...monthColumns,
  ]

  dataTable.rows = []

  dataTable.rows.push({
    c: [
      {v: 'Available CO2'},
      ...data.map((month: ICO2DemandRow) => ({ v: month.co2Available })),
    ]
  })
  dataTable.rows.push({
    c: [
      {v: 'Additional CO2 Required'},
      ...data.map((month: ICO2DemandRow) => ({ v: month.co2Missing })),
    ]
  })
  dataTable.rows.push({
    c: [
      {v: 'Desired CO2'},
      ...data.map((month: ICO2DemandRow) => ({ v: month.co2Desired })),
    ]
  })

  const optionsTable = {
    width: 900,
    allowHtml: true,
    alternatingRowStyle: true,
    cssClassNames: {headerRow: 'bigAndBoldClass', tableRow: 'tableRow', oddTableRow: 'oddRows'},
  }

  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          height={400}
          width={900}
          data={responseObj}
          loader={<div>Loading Chart</div>}
          chartType="ComboChart"
          options={options}
        />
      </Box>
      <Box pb={2}>
        <Chart
          height="auto"
          width={900}
          data={dataTable}
          loader={<div>Loading Chart</div>}
          chartType="Table"
          options={optionsTable}
        />
      </Box>
    </Styled.Container>
  )
}