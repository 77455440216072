import * as React from "react"
import { connect } from "react-redux"
import styled, { AnyStyledComponent } from "styled-components"
import { Container, Grid } from "@material-ui/core"

import { AppState } from "../store"
import LoginForm from "../components/LoginForm"
import Header from "../components/Header"
import { IUser } from "../interfaces/user"

interface ILoginProps {
  history: any
  user: IUser
}

const Heading: AnyStyledComponent = styled.h1`
  ${({ theme }) => `
    font-family: 'Avenir Next Heavy', Arial, sans-serif;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 0.19px;
    color: ${ theme.palette.primary.main };
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 4em;
  `}
`

class Login extends React.Component<ILoginProps> {
  componentDidMount() {
    if (this.props.user.isLoggedIn) {
      this.props.history.push("/");
    }
  }

  render() {
    const { history } = this.props
    return (
      <>
        <Header history={history}/>
        <Container maxWidth="lg">
          <Grid container justify="center">
            <Grid item sm={4}>
              <Heading>Please login</Heading>
              <LoginForm history={this.props.history}/>
            </Grid>
          </Grid>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.user.user
});

export default connect(
  mapStateToProps
)(Login);
