import _ from "lodash"
import React, { useState, useEffect } from "react"
import * as Yup from "yup"

import { connect } from "react-redux"
import { Button, Grid } from "@material-ui/core"
import { Formik, Form } from "formik"
import { useNotify } from "../Hooks"

import { IScreen, IScreenType } from "../interfaces/screen"
import { AppState } from "../store"
import { NotificationState } from "../store/notification/types"
import Hero from "../components/Hero"
import Header from "../components/Header"
import { ScreenActions } from "../store/screen/actions"
import ScreenEdit from "../components/ScreenEdit"
import ErrorPage from '../pages/Error'

import { unflatten } from 'flat'

interface IScreenProps {
  match: any
  history: any
}

interface IStateProps {
  fetchScreen: (id: number) => Promise<any>
  fetchScreenTypes: () => void
  updateScreen: any
}

interface IDispatchProps {
  notification: NotificationState
  screen: IScreen | undefined
  screenTypes: IScreenType[]
}

type TCombinedProps = IScreenProps & IStateProps & IDispatchProps

const screenSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, 'too long')
    .required('Required'),
  note: Yup.string()
    .max(255, 'too long')
    .nullable(),
  es: Yup.number()
    .min(0)
    .required('Required'),
  par: Yup.number()
    .min(0)
    .required('Required'),
  nir: Yup.number()
    .min(0)
    .required('Required'),
})

const Screen = ({ 
  fetchScreen,
  fetchScreenTypes,
  history, 
  match, 
  notification,
  screen, 
  screenTypes, 
  updateScreen,
}: TCombinedProps) => {
  const [newItem, setNewItem] = useState(false)
  const [ready, setReady] = useState(false)
  const [error, setError] = useState(false)
  const notify = useNotify()

  useEffect(() => {
    fetchScreenTypes()
    if (!isNaN(match.params.id)) {
      fetchScreen(match.params.id).then(() => {
        setReady(true)
      }).catch(() => {
        setError(true)
      })
    } else {
      setNewItem(true)
    }
  }, [])

  const getFirstScreenType = () => {
    const firstScreenType = _.head(screenTypes)
    return firstScreenType && firstScreenType.id.toString()
  }

  const getScreenTypeId = (type: string) => {
    const screenType = screenTypes.find((item: IScreenType) => item.name.toLowerCase() === type)
    return screenType && screenType.id
  }

  const getInitialValues = (screen?: IScreen) => {
    return _.assign({
      id: 0,
      name: '',
      note: '',
      es: '',
      par: '',
      nir: '',
      screenTypeId: getScreenTypeId(match.params.type) || getFirstScreenType(),
      saveAndrun: false,
    }, screen)
  }

  if (error) {
    return <ErrorPage match={match} history={history} />
  }

  return (
    <>
      <Header history={history}/>
      { (ready || newItem) && 

        <Formik
          initialValues={getInitialValues(screen)}
          validationSchema={screenSchema}
          onSubmit={(values) => {
            const data = unflatten(values, {delimiter: '-'})
            updateScreen(data).then(() => {
              // redirect to overview + notification
              history.goBack();
              notify('Screen has been saved', 'success')
            })
          }}>
            {({ setFieldValue, handleSubmit, ...props }) => (
              <Form>
                <Hero title={screen ? "Edit screen" : "Create screen"}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} className="cta cta--secondary">
                      <Button onClick={history.goBack} variant="contained" color="secondary" fullWidth>Go back</Button>
                    </Grid>
                    <Grid item xs={6} className="cta">
                      <Button type="submit" variant="contained" color="secondary" fullWidth>Save screen</Button>
                    </Grid>
                  </Grid>
                </Hero>

                <section>
                  <ScreenEdit />
                </section>
              </Form>
            )}
        </Formik>
      }
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  notification: state.notifications,
  screen: state.screens.screen,
  screenTypes: state.screens.screenTypes,
})

export default connect(
  mapStateToProps,
  {
    fetchScreen: (id: number) => ScreenActions.Fetch(id),
    fetchScreenTypes: () => ScreenActions.FetchTypes(),
    updateScreen: (screen: IScreen) => ScreenActions.CreateOrEdit(screen),
  }
)(Screen)
