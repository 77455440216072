import { Dispatch } from "redux"
import { KuboGraphService } from "../../services/api/Kubo/GraphService"
import * as Types from "./types"

export const GraphActions = {
  Fetch: (token: string) => {
    return async (dispatch: Dispatch) => {
      const data = await KuboGraphService.Fetch(token)
      dispatch(GraphActions.dispatchFetch(data))
    }
  },

  // FetchAll: () => {
  //   return async (dispatch: Dispatch) => {
  //     const data = await KuboGraphService.FetchAll()
  //     dispatch(GraphActions.dispatchFetchAll(data))
  //   }
  // },

  dispatchFetch: (data: any) => ({
    type: Types.GRAPH_FETCH,
    payload: data
  }),

  // dispatchFetchAll: (data: any) => ({
  //   type: Types.GRAPH_FETCHALL,
  //   payload: data
  // })
}
