import React from 'react'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import * as Styled from '../../styled'
import { IGraphItem } from '../../../../interfaces/graph'

interface ITemperatureDistributionRow {
  average: number
  maximum: number
  median: number
  minimum: number
  month: number
  quarter1: number
  quarter3: number
}

export const TemperatureDistribution = ({ resultData: data, description, name, pageBreakAfter }: IGraphItem) => {
  if (!data) return null
  let responseObj: any = {};

  responseObj.cols = [
    {id: 'month', label: 'Month', type: 'date'},
    {id: 'series0', label: 'median', type: 'number'},
    {id: 'tooltip', label: null, role: 'tooltip'},
    {id: 'min', type: 'number', role: 'interval'},
    {id: 'Q1', type: 'number', role: 'interval'},
    {id: 'median', type: 'number', role: 'interval'},
    {id: 'Q3', type: 'number', role: 'interval'},
    {id: 'max', type: 'number', role: 'interval'},
    {id: 'medianAnnotation', type: 'number', role: 'annotation'},
  ];

  const now = new Date()

  responseObj.rows = data.map((obj: ITemperatureDistributionRow) => ({
    c: [
      {v: new Date(now.getFullYear() + '/' + obj.month + '/01') },
      {v: obj.median},
      {v: 'median: ' + obj.median },
      {v: obj.minimum},
      {v: obj.quarter1},
      {v: obj.median},
      {v: obj.quarter3},
      {v: obj.maximum},
      {v: obj.median},
    ],
  }));

  const options = {
    title: 'Weather Summary Box Plot',
    legend: 'none',
    vAxis: {
      title: 'Temperature [°C]',
      gridlines: {count: 8}, // count=(MaxValue - MinValue) / StepSize
    },
    width: 900,
    height: 400,
    hAxis: {
        format: 'MMM',
        title: 'Month',
        gridlines: {count: 12},
    },
    lineWidth: 0,
    series: [{'color': '#D3362D'}],
    intervals: {
        barWidth: 1,
        boxWidth: 1,
        lineWidth: 2,
        style: 'boxes',
    },
    interval: {
        max: {
            style: 'bars',
            fillOpacity: 1,
            color: '#777',
        },
        min: {
            style: 'bars',
            fillOpacity: 1,
            color: '#777',
        },
    },
};
  
  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          height={400}
          width={900}
          data={responseObj}
          loader={<div>Loading Chart</div>}
          chartType="LineChart"
          options={options}
        />
      </Box>
    </Styled.Container>
  )
}