import React from 'react'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import * as Styled from '../../styled'
import { IGraphItem } from '../../../../interfaces/graph'

interface IHumidityLevelsRow {
  month: number
  relativeHumidityDay: number
  relativeHumidityNight: number
}

export const HumidityLevels = ({ resultData: data, description, name, pageBreakAfter }: IGraphItem) => {

  let responseObj: any = {};
  responseObj.cols = [
    {id: 'month', label: 'Month', type: 'date'},
    {id: 'rhDay', label: 'RH Day', type: 'number'},
    { id: 'tooltip1', label: null, role: 'tooltip', 'p': { 'html': true } },
    {id: 'annotationRHDay', label: 'annotation RH Day', type: 'number', role: 'annotation'},
    {id: 'rhNight', label: 'RH Night', type: 'number'},
    { id: 'tooltip2', label: null, role: 'tooltip', 'p': { 'html': true } },
    {id: 'annotationRHNight', label: 'annotation RH Night', type: 'number', role: 'annotation'},
  ];
  responseObj.rows = data.map((obj: IHumidityLevelsRow) => ({
    c: [
      {v: new Date('2000/' + obj.month + '/01')},
      {v: obj.relativeHumidityDay},
      {v: 'Day: ' + obj.relativeHumidityDay},
      {v: obj.relativeHumidityDay},
      {v: obj.relativeHumidityNight},
      {v: 'Night: ' + obj.relativeHumidityNight},
      {v: obj.relativeHumidityNight},
    ],
  }));

  const options = {
    chart: {
      title: 'Relative Humidity Overview',
    },
    width: 900,
    height: 400,
    dataOpacity: 0.5,
    bar: {
      groupWidth: '70%',
    },
    series: {
      0: {color: '#e4d600', visibleInLegend: true, pointsVisible: true},
      1: {color: '#6eaeef', visibleInLegend: true},
    },
    hAxis: {
      title: 'Month',
      format: 'MMM',
      gridlines: {count: 12},
    },
    legend: {
      position: 'top',
    },
    annotations: {
      datum: {
        stem: {
          color: 'transparent',
          length: 1,
          style: 'stem',
        },
      },
      textStyle: {
        fontName: 'Arial',
        fontSize: 14,
        bold: false,
        color: '#000001',
      },
    },
    vAxis: {
      title: 'Relative Humidity [%]',
      viewWindow: {
        min: 0,
        max: 100,
      },
      // ticks: [0, 25, 50, 75, 100], // display labels
    },
  };
  
  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          data={responseObj}
          loader={<div>Loading Chart</div>}
          width={900}
          height={400}
          chartType="ColumnChart"
          options={options}
        />
      </Box>
    </Styled.Container>
  )
}