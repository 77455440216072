import styled, { AnyStyledComponent } from "styled-components";

export const NavigationItems: AnyStyledComponent = styled.nav`
  ${({ theme }) => `
    display: flex;
    align-items: flex-end;
    height: 100%;
    justify-content: flex-start;

    &:hover.dropdown .backdrop {
      display: block;
    }

    .nav__link {
      font-size: 16px;
      padding-bottom: 1em;
      padding-left: 1em;
      padding-right: 1em;
      display: inline-block;
      color: #fff;
      text-decoration: none;
      border-bottom: 4px solid #003C68;
      opacity: 70%;
      cursor: pointer;
      flex: 0 0 200px;
      text-align: center;

      &.active {
        border-bottom: 4px solid #FFF;
        opacity: 100%;
      }

      &:active,
      &:hover {
        border-bottom: 4px solid #FFF;
        opacity: 100%;
      }

      &.dropdown {
        display: flex;
        align-items: flex-end;
        justify-content: space-evenly;
        z-index: 110;

        &:active .dropdown__menu,
        &:hover .dropdown__menu {
          display: block;
        }

        .dropdown__menu {
          display: none;
          top: 90px;
          background-color: #FFFFFF;
          position: absolute;
          left: 0;
          width: 100%;
          padding: 2em;
          line-height: 24px;
          z-index: 110;
          align-items: center;

          div {
            .dropdown__menu__item {
              color: ${ theme.palette.primary.main };
              text-align: center;
              line-height: 2em;
            }
          }
        }
      }
    }

    .backdrop {
      display: none;
      background-color: #333;
      z-index: 100;
      opacity: 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &.show {
        opacity: 50%;
        display: block;
      }
    }
  `}
`
