import { Http } from "../../Http"
import { KuboStrategyModel } from "./types"

export const KuboStrategyService = {
  FetchAll: async (): Promise<KuboStrategyModel.GetStrategysResponse> => {
    return await Http.Request<KuboStrategyModel.GetStrategysResponse>('GET', '/api/Strategy')
  },

  Fetch: async (id: number): Promise<KuboStrategyModel.GetStrategyResponse> => {
    return await Http.Request<KuboStrategyModel.GetStrategyResponse>('GET', '/api/Strategy/' + id)
  }
}
