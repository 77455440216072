import React from "react"
import ReactDOM from "react-dom"
import { ConfirmProvider } from 'material-ui-confirm'
import { Provider } from "react-redux"
import { MuiThemeProvider, CssBaseline } from "@material-ui/core"
import { ThemeProvider } from "styled-components"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { SnackbarProvider } from 'notistack'
import Notifier from './components/Notifier'
import theme from "./theme"
import configureStore from "./store/"
import TagManager from 'react-gtm-module'
import * as serviceWorker from "./serviceWorker"
import PrivateRoute from "./components/PrivateRoute"
import Error from './pages/Error'
import Login from "./pages/Login"
import Report from './pages/Report'
import Scenarios from "./pages/Scenarios"
import Screen from "./pages/Screen"
import Screens from "./pages/Screens"
import Scenario from "./pages/Scenario"
import IsAuthenticated from "./components/IsAuthenticated"

const store = configureStore();

const GTM_CODE = process.env.REACT_APP_GTM_CODE || ''
const tagManagerArgs = {
  gtmId: `${GTM_CODE}`,
}

class Root extends React.Component {

  componentDidMount() {
    TagManager.initialize(tagManagerArgs)
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <ConfirmProvider>
                <SnackbarProvider>
                  <IsAuthenticated>
                    <CssBaseline />
                    <Notifier />

                    <Switch>
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/report/:token?" component={Report} />
                      <PrivateRoute exact path="/scenario/:id/" component={Scenario} />
                      <PrivateRoute exact path="/screen/create/:type/" component={Screen} />
                      <PrivateRoute exact path="/screen/:id" component={Screen} />
                      <PrivateRoute exact path="/screens/:type" component={Screens} />
                      <PrivateRoute exact path="/screens/" component={Screens} />
                      <PrivateRoute exact path="/" component={Scenarios} />
                      <Route path="*" component={Error} />
                    </Switch>
                  </IsAuthenticated>
                </SnackbarProvider>
              </ConfirmProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </Provider>
    )
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
