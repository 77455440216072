import _ from "lodash"
import * as React from "react"
import * as Styled from "./styled"
import { Field } from "formik"
import { Grid, Typography } from "@material-ui/core"
import { TextField } from "formik-material-ui"
import { ExpandMore } from "@material-ui/icons"

interface IDefaultPanelProps {
  prefix: string
  name: string
  fields: any
  expanded?: boolean
  disabledForm: boolean
}

class DefaultPanel extends React.Component<IDefaultPanelProps> {
  render() {
    const { name, prefix, fields, expanded, disabledForm } = this.props
    return (
      <DefaultPanelContainer expanded={expanded}>
        <DefaultPanelHeader prefix={prefix} name={name} icon={expanded} />
        <DefaultPanelBody>
          <Grid container spacing={2}>
            {
              _.map(fields, (value: any, title: string) => {
                return (
                  <Grid item xs={3} key={prefix + "-" + title}>
                    <Field component={TextField} name={prefix + "-" + title} label={title} variant="outlined" fullWidth disabled={disabledForm} />
                  </Grid>
                )
              })
            }
          </Grid>
        </DefaultPanelBody>
      </DefaultPanelContainer>
    )
  }
}

interface IDefaultPanelContainer { children: any, expanded?: boolean }
const DefaultPanelContainer = ({children, expanded}: IDefaultPanelContainer) => (
  <Styled.ExpansionPanelContainer expanded={expanded}>{children}</Styled.ExpansionPanelContainer>
)

interface IDefaultPanelHeaderProps { prefix: string, name: string, icon?: boolean}
const DefaultPanelHeader = ({prefix, name, icon}: IDefaultPanelHeaderProps) => ( 
  <Styled.ExpansionPanelHeader expandIcon={icon ?? <ExpandMore />} aria-controls={ prefix + "content"} id={ prefix + "header"}>
    <Typography variant="h2" color="primary">{ name }</Typography>
  </Styled.ExpansionPanelHeader>
)

interface IDefaultPanelBodyProps { children: any }
const DefaultPanelBody = ({children}: IDefaultPanelBodyProps) => (
  <Styled.ExpansionPanelBody>{ children }</Styled.ExpansionPanelBody>
)

export { DefaultPanelContainer, DefaultPanel, DefaultPanelHeader, DefaultPanelBody }