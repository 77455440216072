import { Http } from "../../Http"
import { KuboScreenModel } from "./types"

export const KuboScreenService = {
  Fetch: async (id: number): Promise<KuboScreenModel.GetScreenResponse> => {
    return await Http.Request<KuboScreenModel.GetScreenResponse>('GET', '/api/screen/' + id)
  },

  FetchTypes: async (): Promise<KuboScreenModel.GetScreensResponse> => {
    return await Http.Request<KuboScreenModel.GetScreensResponse>('GET', '/api/screen/GetScreenTypes')
  },
  
  FetchByType: async (type: string): Promise<KuboScreenModel.GetScreensResponse> => {
    return await Http.Request<KuboScreenModel.GetScreensResponse>('GET', `/api/screen/${type}`)
  },

  Update: async(id: number, data: any): Promise<KuboScreenModel.CreateOrEditResponse> => {
    return await Http.Request<KuboScreenModel.CreateOrEditResponse>(id ? 'PUT' : 'POST', '/api/screen/', undefined, JSON.stringify(data))
  },

  Duplicate: async (id: number): Promise<KuboScreenModel.DuplicateResponse> => {
    return await Http.Request<KuboScreenModel.DuplicateResponse>('POST', '/api/screen/Duplicate', undefined, JSON.stringify({ id }))
  },

  Delete: async (id: number): Promise<KuboScreenModel.DeleteResponse> => {
    return await Http.Request<KuboScreenModel.DeleteResponse>('DELETE', '/api/screen/' + id)
  },
}
