import { Dispatch } from "redux"
import { KuboClientService } from "../../services/api/Kubo/ClientService"
import * as Types from "./types"

export const ClientActions = {
  FetchAll: () => {
    return async (dispatch: Dispatch) => {
      const data = await KuboClientService.FetchAll()
      dispatch(ClientActions.dispatchFetchAll(data))
      return true
    }
  },

  Create: (params: any) => {
    return async (dispatch: Dispatch) => {
      const data = await KuboClientService.Create(params)
      dispatch(ClientActions.dispatchCreate(data))
      return { id: data }
    }
  },

  dispatchFetchAll: (data: any) => ({
    type: Types.CLIENT_FETCHALL,
    payload: data
  }),

  dispatchCreate: (data: any) => ({
    type: Types.CLIENT_CREATE,
    payload: data
  })
}