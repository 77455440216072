import * as React from "react";
import { Container, Grid, Typography } from "@material-ui/core";

import * as Styled from "./styled";

interface IHeroProps {
  title: string
  children?: any
  description?: string
}

class Hero extends React.Component<IHeroProps> {
  render() {
    const { title, children, description } = this.props

    return (
      <React.Fragment>
        <Styled.HeroWrapper>
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={children ? 6 : 12}>
                <Styled.Title variant="h1" color="primary" dangerouslySetInnerHTML={{ __html: title }} />
              </Grid>
              {children && <Grid item xs={6}>{children}</Grid>}
            </Grid>
            { description && (
              <Typography variant="body1">{description}</Typography>
            )}
          </Container>
        </Styled.HeroWrapper>
      </React.Fragment>
    )
  }
}

export default Hero
