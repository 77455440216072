import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { Button as MuiButton } from '@material-ui/core';

const Button = ({ children, ...rest }: any) => (
  <MuiButton component={RouterLink} {...rest}>
    {children}
  </MuiButton>
)

export default Button