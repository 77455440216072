import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import { connect } from "react-redux"
import { Box, Container, CircularProgress, Fade, Grid } from "@material-ui/core"

import { GraphActions } from '../store/graph/actions'
import { IReport } from '../interfaces/graph'
import { AppState } from "../store"

import Header from "../components/Header"
import GraphList from "../components/GraphList"
import ReportFirstPage from '../components/ReportFirstPage'
import ErrorPage from '../pages/Error'

interface IReportProps {
  fetchReport: (token: string) => Promise<any>
  match: any
  history: any
  report: IReport | undefined 
}

const PrintContainer = styled(Container)`
  @media print {
    padding: 0;    
  }
`

const Report = ({ 
  fetchReport, 
  report, 
  match, 
  history,
}: IReportProps) => {
  const [ loading, setLoading ] = useState(true)
  const [ error, setError ] = useState(false)

  useEffect(() => {
    if (match.params.token) {
      fetchReport(match.params.token).then(() => {
        setLoading(false)
      }).catch(() => {
        setError(true)
      })
    }
  }, [fetchReport, match])

  const { title, client } = report || {}  

  if (error) {
    return <ErrorPage match={match} history={history} />
  }

  return (
    <>
      <ReportFirstPage />
      <Header history={history} title={title} client={client} report={true}/>

      <PrintContainer maxWidth="lg">
        <Box py={2}>
          <Grid container alignItems="center" justify="center" style={{ minHeight: 'calc(100vh - 350px)'}}>
            <Grid item>
              <Fade
                in={loading}
                style={{
                  transitionDelay: loading ? '800ms' : '0ms',
                }}
                unmountOnExit
              >
                <CircularProgress />
              </Fade>
            </Grid>
              
            {report && (
              <Grid item xs={12}>
                <GraphList listing={report} />
              </Grid>
            )}
          </Grid>
        </Box>
      </PrintContainer>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  report: state.graphs.report,
})

export default connect(
  mapStateToProps,
  {
    fetchReport: (token: string) => GraphActions.Fetch(token),
  }
)(Report)
