import * as Types from "./types"

const initialState: Types.ScreenState = {
  list: [],
  screen: undefined,
  screenTypes: [],
}

export function screenReducer(
  state = initialState,
  action: Types.ScreenActionTypes
): Types.ScreenState {
  switch (action.type) {

    case Types.SCREEN_FETCH:
      return {
        ...state,
        screen: action.payload
      }

    case Types.SCREEN_FETCHTYPES:
      return {
        ...state,
        screenTypes: action.payload,
      }

    case Types.SCREEN_FETCHBYTYPE:

      return {
        ...state,
        screen: undefined,
        list: action.payload,
      }

    default:
      return state;
  }
}
