import _ from "lodash"
import * as React from "react"
import * as Styled from "./styled"
import { IScenario } from "../../../interfaces/scenario"
import { Grid, Button } from "@material-ui/core"

import Structure from "./Structure"
import Screens from "./Screens"
import { ArtificialLights } from "./ArtificialLights"
import { Prices } from "./Prices"
import { Climate } from "./Climate"

interface IGreenhouseTabProps {
  scenario: IScenario
  setFieldValue: any
  disabledForm: boolean
}

interface IGreenhouseTabState {
  openTabId: string
}

class GreenhouseTab extends React.Component<IGreenhouseTabProps, IGreenhouseTabState> {
  constructor(props: any) {
    super(props)
    this.state = {
      openTabId: 'tab-structure'
    }
  }

  handleChange(e: any, tabId: any) {
    e.preventDefault()
    this.setState({
      openTabId: tabId
    })
  }

  getTabId (direction: "previous" | "next") {
    const { openTabId } = this.state
    const list = this.greenhouseTabs()

    const result: any = [];
    _.transform(list, function(no, item, key) {
      if (item.id === openTabId) {
        let iterator = key+1
        if (direction === "previous") {
          iterator = key-1
        }

        if (list[iterator]) {
          result.push(list[iterator])
          return false
        }
      }
    })

    return result[0] ? result[0].id : false
  }

  greenhouseTabs() {
    const { scenario, setFieldValue, disabledForm } = this.props

    return [
      {
        id: 'tab-structure',
        name: 'Structure',
        content: <Structure structure={scenario.greenhouse.structure} setFieldValue={setFieldValue} disabledForm={disabledForm} />
      }, {
        id: 'tab-screens',
        name: 'Screens',
        content: <Screens screenValues={scenario.greenhouse.screenValues} setFieldValue={setFieldValue} disabledForm={disabledForm} />
      }, {
        id: 'tab-lights',
        name: 'Artificial lights',
        content: <ArtificialLights artificialLights={scenario.greenhouse.artificialLights} disabledForm={disabledForm} />
      }, {
        id: 'tab-prices',
        name: 'Prices',
        content: <Prices prices={scenario.greenhouse.prices} disabledForm={disabledForm} />
      }, {
        id: 'tab-climate',
        name: 'Climate property',
        content: <Climate climateProperty={scenario.greenhouse.climateProperties} disabledForm={disabledForm} />
      }
    ]
  }

  render() {
    const { openTabId } = this.state
    const activeTabClass = (name: string) => {
      if (name === openTabId) {
        return 'active'
      }
    }

    return (
      <>
        <Styled.TabHeader>
          {
            this.greenhouseTabs().map((tabItem, i) =>
              <Styled.Tab
                  key={tabItem.id + i}
                  className={activeTabClass(tabItem.id)}
                  onClick={(e: any) => this.handleChange(e, tabItem.id)}>
                { tabItem.name }
              </Styled.Tab>
            )
          }
        </Styled.TabHeader>

        <Styled.TabContent>
          {
            this.greenhouseTabs().map((tabItem: any, key: any) =>
              <React.Fragment key={"tab-" + tabItem.id + key}>
                { tabItem.id === openTabId &&
                  <Grid container spacing={2}>
                    { tabItem.content }
                  </Grid>
                }
              </React.Fragment>
            )
          }

          <Grid container spacing={2}>
            <Grid item xs={2}>
              { false !== this.getTabId('previous') &&
                <Styled.Btn
                  type="button"
                  onClick={(e: any) => this.handleChange(e, this.getTabId('previous'))}
                  variant="contained"
                  color="primary"
                  disableElevation
                  fullWidth
                >Previous step</Styled.Btn>
              }
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={2}>
              { false !== this.getTabId('next') &&
                <Button
                  type="button"
                  onClick={(e: any) => this.handleChange(e, this.getTabId('next'))}
                  variant="contained"
                  color="secondary"
                  disableElevation
                  fullWidth
                >Next step</Button>
              }
            </Grid>
          </Grid>
        </Styled.TabContent>
      </>
    )
  }
}

export default GreenhouseTab
