import React from 'react'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import { monthColumns } from '../../../../helpers'
import * as Styled from '../../styled'
import { IGraphItem } from '../../../../interfaces/graph'

interface IEquipmentHeatDeliveryRow {
  month: number
  totalHeatAL: number
  qrPipeAirFinal: number
  quPipeAirFinal: number
  qsPipeAirFinal: number
  qhBFinal: number
}

export const EquipmentHeatDelivery = ({ resultData: data, description, name, pageBreakAfter }: IGraphItem) => {

  let responseObj: any = {};
  responseObj.cols = [];
  responseObj.cols.push({ id: 'month', label: 'Month', type: 'date' });

  const maxAHU: number = Math.max.apply(null, data.map((obj: IEquipmentHeatDeliveryRow) => obj.qhBFinal));
  const maxRP: number = Math.max.apply(null, data.map((obj: IEquipmentHeatDeliveryRow) => obj.qrPipeAirFinal));
  const maxUP: number = Math.max.apply(null, data.map((obj: IEquipmentHeatDeliveryRow) => obj.quPipeAirFinal));
  const maxSP: number = Math.max.apply(null, data.map((obj: IEquipmentHeatDeliveryRow) => obj.qsPipeAirFinal));
  const maxAL: number = Math.max.apply(null, data.map((obj: IEquipmentHeatDeliveryRow) => obj.totalHeatAL));

  if (maxAHU > 0) {
    responseObj.cols.push({ id: 'qHBFinal', label: 'AHU Heat Block', type: 'number' })
    responseObj.cols.push({ id: 'tooltip1', label: null, role: 'tooltip', 'p': { 'html': true } })
  }
  if (maxRP > 0) {
    responseObj.cols.push({ id: 'qRPipeAirFinal', label: 'Rail Pipe', type: 'number' })
    responseObj.cols.push({ id: 'tooltip2', label: null, role: 'tooltip', 'p': { 'html': true } })
  }
  if (maxUP > 0) {
    responseObj.cols.push({ id: 'qUPipeAirFinal', label: 'Upper Pipe', type: 'number' })
    responseObj.cols.push({ id: 'tooltip3', label: null, role: 'tooltip', 'p': { 'html': true } })
  }
  if (maxSP > 0) {
    responseObj.cols.push({ id: 'qSPipeAirFinal', label: 'Snow Pipe', type: 'number' })
    responseObj.cols.push({ id: 'tooltip4', label: null, role: 'tooltip', 'p': { 'html': true } })
  }
  if (maxAL > 0) {
    responseObj.cols.push({ id: 'totalHeatAL', label: 'Artificial Lights', type: 'number' })
    responseObj.cols.push({ id: 'tooltip5', label: null, role: 'tooltip', 'p': { 'html': true } })
  }

  const now = new Date()

  responseObj.rows = data.map((obj: IEquipmentHeatDeliveryRow) => {
    let row = []
    row.push({ v: new Date(now.getFullYear() + '/' + obj.month + '/01') })

    const tooltip = `<div style="padding: 5px; white-space: nowrap">
      ${maxAHU > 0 ? 'Heat Block: ' + (obj.qhBFinal > 0 ? obj.qhBFinal.toFixed(3) : 0) + '<br />' : ''}
      ${maxRP > 0 ? 'Rail pipe: ' + (obj.qrPipeAirFinal > 0 ? obj.qrPipeAirFinal.toFixed(3) : 0) + '<br />' : ''}
      ${maxUP > 0 ? 'Upper pipe: ' + (obj.quPipeAirFinal > 0 ? obj.quPipeAirFinal.toFixed(3) : 0) + '<br />' : ''}
      ${maxSP > 0 ? 'Snow pipe: ' + (obj.qsPipeAirFinal > 0 ? obj.qsPipeAirFinal.toFixed(3) : 0) + '<br />' : ''}
      ${maxAL > 0 ? 'AL: ' + (obj.totalHeatAL > 0 ? obj.totalHeatAL.toFixed(3) : 0) + '<br />' : ''}
    </div>`

    if (maxAHU > 0) {
      row.push({ v: obj.qhBFinal })
      row.push({ v: tooltip })
    }
    if (maxRP > 0) {
      row.push({ v: obj.qrPipeAirFinal })
      row.push({ v: tooltip })
    }
    if (maxUP > 0) {
      row.push({ v: obj.quPipeAirFinal })
      row.push({ v: tooltip })
    }
    if (maxSP > 0) {
      row.push({ v: obj.qsPipeAirFinal })
      row.push({ v: tooltip })
    }
    if (maxAL > 0) {
      row.push({ v: obj.totalHeatAL })
      row.push({ v: tooltip })
    }

    return { c: row }
  });

  const options = {
    width: 900,
    height: 600,
    seriesType: 'area',
    isStacked: true,
    gridlines: 'white',
    series: {},
    vAxes: {
      0: {
        title: 'Heat Energy [MJ/m2]',
        gridlines: {color: '#EAECEE'},
      },
    },
    hAxis: {
      format: 'MMM',
      title: 'Month',
      gridlines: {color: 'white'},
    },
    tooltip: { isHtml: true },
  };


  let dataTable: any = {}
  dataTable.cols = [
    {type: 'string', label: 'Equipment', id: '0'},
    ...monthColumns,
  ]

  dataTable.rows = []

  if (maxAHU > 0) {
    dataTable.rows.push({
      c: [
        { v: 'AHU Heat Block' },
        ...data.map((month: IEquipmentHeatDeliveryRow) => ({ v: month.qhBFinal })),
      ]
    })
  }
  if (maxRP > 0) {
    dataTable.rows.push({
      c: [
        { v: 'Rail Pipe' },
        ...data.map((month: IEquipmentHeatDeliveryRow) => ({ v: month.qrPipeAirFinal })),
      ]
    })
  }
  if (maxUP > 0) {
    dataTable.rows.push({
      c: [
        { v: 'Upper Pipe' },
        ...data.map((month: IEquipmentHeatDeliveryRow) => ({ v: month.quPipeAirFinal })),
      ]
    })
  }
  if (maxSP > 0) {
    dataTable.rows.push({
      c: [
        { v: 'Snow Pipe' },
        ...data.map((month: IEquipmentHeatDeliveryRow) => ({ v: month.qsPipeAirFinal })),
      ]
    })
  }
  if (maxAL > 0) {
    dataTable.rows.push({
      c: [
        { v: 'Artificial Lights' },
        ...data.map((month: IEquipmentHeatDeliveryRow) => ({ v: month.totalHeatAL })),
      ]
    })
  }
  
  const optionsTable = {
    width: 900,
    allowHtml: true,
    alternatingRowStyle: true,
    cssClassNames: {headerRow: 'bigAndBoldClass', tableRow: 'tableRow', oddTableRow: 'oddRows'},
  };
  
  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          height={600}
          width={900}
          data={responseObj}
          loader={<div>Loading Chart</div>}
          chartType="AreaChart"
          options={options}
        />
      </Box>
      <Box pb={2}>
        <Chart
          height="auto"
          width={900}
          data={dataTable}
          loader={<div>Loading Chart</div>}
          chartType="Table"
          options={optionsTable}
        />
      </Box>
    </Styled.Container>
  )
}