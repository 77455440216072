import React from 'react'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import { monthColumns } from '../../../../helpers'
import * as Styled from '../../styled'
import { IGraphItem } from '../../../../interfaces/graph'

interface IHeatPrimarySourceRow {
  heatPrimaryFinal: number
  month: number
}

export const HeatPrimarySource = ({ resultData: data, description, name, pageBreakAfter }: IGraphItem) => {

  let responseObj: any = {};
  responseObj.cols = [
    { id: 'month', label: 'Month', type: 'date' },
    { id: 'value', label: 'Boiler [MJ/m2]', type: 'number' },
    { id: 'tooltip', label: null, role: 'tooltip' },
  ];

  responseObj.rows = data.map((obj: IHeatPrimarySourceRow) => ({
    c: [
      { v: new Date('2000/' + obj.month + '/01') },
      { v: obj.heatPrimaryFinal },
      { v: Number(obj.heatPrimaryFinal).toFixed(3) },
    ],
  }));

  const options = {
    height: 400,
    width: 900,
    hAxis: {
      title: 'Month',
      titleTextStyle: {color: '#333'},
      format: 'MMM',
    },
    vAxis: {
      title: 'Heat Demand Primary Equipment [MJ/m2]',
      titleTextStyle: {color: '#333'},
    },
  };


  let dataTable: any = {}
  dataTable.cols = [
    {type: 'string', label: 'Equipment', id: '0'},
    ...monthColumns,
  ]

  // calculate sum
  let boilerSum = 0
  const boilerData = data.map((month: IHeatPrimarySourceRow) => {
    boilerSum += month.heatPrimaryFinal
    return { v: month.heatPrimaryFinal }
  })

  dataTable.rows = []
  dataTable.rows.push({
    c: [
      {v: 'Boiler'},
      ...boilerData,
      {v: boilerSum},
    ]
  })

  const optionsTable = {
    width: 900,
    allowHtml: true,
    alternatingRowStyle: true,
    cssClassNames: {headerRow: 'bigAndBoldClass', tableRow: 'tableRow', oddTableRow: 'oddRows'},
  };
  
  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          height={400}
          width={900}
          data={responseObj}
          loader={<div>Loading Chart</div>}
          chartType="ColumnChart"
          options={options}
        />
      </Box>
      <Box pb={2}>
        <Chart
          height="auto"
          width={900}
          data={dataTable}
          loader={<div>Loading Chart</div>}
          chartType="Table"
          options={optionsTable}
        />
      </Box>
    </Styled.Container>
  )
}