import { Http } from "../../Http"
import { KuboUserModel } from "./types"

export const KuboUserService = {
  Authenticate: async (username: string, password: string): Promise<KuboUserModel.GetAuthenticateResponse | KuboUserModel.BaseResponse> => {
    let body = Http.filter({
      grant_type: 'password',
      Username: username,
      Password: password
    })

    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    return await Http.Request<KuboUserModel.GetAuthenticateResponse>('POST', '/token', undefined, body, headers)
  },

  Fetch: async (): Promise<KuboUserModel.GetUserResponse> => {
    return await Http.Request<KuboUserModel.GetUserResponse>('GET', '/api/User');
  },

  Logout: async (): Promise<KuboUserModel.BaseResponse> => {
    let response: KuboUserModel.BaseResponse
    let body = {
      token: localStorage.getItem('refreshToken')
    }

    try {
      response = await Http.Request<KuboUserModel.BaseResponse>('POST', '/api/User/Logout', undefined, JSON.stringify(body))
    } catch (error) {
      response = {} as KuboUserModel.BaseResponse
    }
    return response
  }
}
