import * as Types from "./types"
import { IContent, IScenario, IPreviousLocation } from "../../interfaces/scenario"

const initialState: Types.ScenarioState = {
  list: [] as IScenario[],
  scenario: {} as IScenario,
  locations: [] as IPreviousLocation[],
  content: {} as IContent,
  activeTab: 'scenario',
}

export function scenarioReducer(
  state = initialState,
  action: Types.ScenarioActionTypes
): Types.ScenarioState {
  switch (action.type) {

    case Types.SCENARIO_FETCH:
      return {
        ...state,
        scenario: action.payload
      }

    case Types.SCENARIO_FETCHALL:
      return {
        ...state,
        list: action.payload
      }

    case Types.SCENARIO_SET_LOCATION:
      const { latitude, longitude, city, country } = action.payload

      return {
        ...state,
        scenario: {
          ...state.scenario,
          location: {
            latitude,
            longitude,
            city,
            country,
          }
        }
      }
    
    case Types.SCENARIO_FETCH_LOCATIONS: 
      return {
        ...state,
        locations: action.payload,
      }
    
    case Types.SCENARIO_FETCH_CONTENT: 
      return {
        ...state,
        content: action.payload,
      }
    
    case Types.SCENARIO_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      }

    default:
      return state;
  }
}
