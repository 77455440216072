import * as Types from "./types"

const initialState: Types.GraphState = {
  list: [],
  report: undefined,
}

export function graphReducer(
  state = initialState,
  action: Types.GraphActionTypes
): Types.GraphState {
  switch (action.type) {

    case Types.GRAPH_FETCH:
      return {
        ...state,
        report: action.payload
      }

    case Types.GRAPH_FETCHALL:
      return {
        ...state,
        list: action.payload,
      }

    default:
      return state;
  }
}
