import { IGraph, IReport } from "../../interfaces/graph";

export interface GraphState {
  list: IGraph[]
  report: IReport | undefined
}

export const GRAPH_FETCH = "GRAPH_FETCH"
export const GRAPH_FETCHALL = "GRAPH_FETCHALL"

interface IGraphFetchAction {
  type: typeof GRAPH_FETCH
  payload: IReport
}

interface IGraphFetchAllAction {
  type: typeof GRAPH_FETCHALL
  payload: IGraph[]
}

export type GraphActionTypes = IGraphFetchAction
                                | IGraphFetchAllAction
