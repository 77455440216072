import styled, { AnyStyledComponent } from "styled-components"

export const Wrapper = styled.div`
  @page { 
    @bottom-center { content: element(footer) }
  }
`

export const Container: AnyStyledComponent = styled.div`
  ${({ after = 'auto' }: any) => `
    @media print {
      min-width: 1000px;
      page-break-inside: avoid;
      page-break-after: ${after || ''};
      padding-left: 40px;
      padding-top: 40px;
      padding-right: 40px;
    }
  `}

  .google-visualization-table-table th {
    padding: 2px 2px;
  }
  .google-visualization-table-table td {
    padding: 0 2px;
  }

  .google-visualization-formatters-bars {
    float: none;
    display: inline-block;
    text-align: left;
    width: 100%;
  }

  .google-visualization-formatters-bars > span {
    background-image: url('/assets/img/bar_w.png');
  }

  .google-visualization-formatters-bars > .google-charts-bar-s {
    background-image: url('/assets/img/bar_s.png');
  }
  .google-visualization-formatters-bars > .google-charts-bar-b {
    background-image: url('/assets/img/bar_b.png');
  }
  .google-visualization-formatters-bars > .google-charts-bar-r {
    background-image: url('/assets/img/bar_r.png');
  }
  .google-visualization-formatters-bars > .google-charts-bar-w {
    background-image: url('/assets/img/bar_w.png');
  }
`