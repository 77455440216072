import styled, { AnyStyledComponent } from "styled-components"
import { Button } from "@material-ui/core"

export const TabHeader: AnyStyledComponent = styled.main`
  ${({ theme }) => `
    background-color: ${ theme.palette.primary.light };
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-end;
    height: 100%;
    justify-content: space-around;
  `}
`

export const Tab: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    font-size: 14px;
    line-height: 20px;
    padding: 7px 20px 8px;
    flex: 1 1 auto;
    color: #fff;
    text-decoration: none;
    opacity: 70%;
    cursor: pointer;
    text-align: center;

    &.active,
    &:active,
    &:hover {
      background-color: ${ theme.palette.primary.main };
      opacity: 100%;
    }
  `}
`

export const TabContent: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    background-color: #fff;
    padding: 2em;

    &.active {
      display: block;
    }
  `}
`

export const Btn: AnyStyledComponent = styled(Button)`
  ${({ theme }) => `
      background-color: #F2F2F2;
      color: ${ theme.palette.primary.main };

      &:active,
      &:hover {
        background-color: #C2C2C2;
      }
  `}
`
