import React from 'react'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import * as Styled from '../../styled'
import { IGraphItem } from '../../../../interfaces/graph'

interface ITemperatureTrendAverageMonth {
  year: number
  temperature: number
}

interface ITemperatureTrendAverageRow {
  monthNo: number
  monthData: ITemperatureTrendAverageMonth[]
}

export const TemperatureTrendAverage = ({ resultData: data, description, name, pageBreakAfter }: IGraphItem) => {
  if (!data) return null

  const availableYears = data && data[0].monthData.map((item: any) => item.year)

  let responseObj: any = {};
  responseObj.cols = [
    {id: 'month', label: 'Month', type: 'date'},
    ...availableYears.reduce((acc: any, cur: any, i: number) => { 
      return [
        ...acc,
        {id: `Y${i+1}`, label: cur, type: 'number'},
        {id: `tooltip${i+1}`, label: null, role: 'tooltip'}
      ]
     }, []),
  ]

  responseObj.rows = data.map((obj: ITemperatureTrendAverageRow) => ({
    c: [
      {v: new Date('2000/' + obj.monthNo + '/01')},
      ...obj.monthData.reduce((acc: any, cur: any, i: number) => { 
        return [
          ...acc,
          { v: cur.temperature },
          { v: cur.year + ': ' + cur.temperature },
        ]
      }, []),
    ],
  }));

  const options = {
    chart: {
      title: 'Temperature Overview',
    },
    width: 900,
    height: 400,
    dataOpacity: 0.5,
    bar: {
        groupWidth: '85%',
    },
    /* series: {
    0: {color: '#e4d600', visibleInLegend: true},
    1: {color: '#6eaeef', visibleInLegend: true}
  },  */
    hAxis: {
        title: 'Month',
        format: 'MMM',
        gridlines: {count: 11},
    },
    /*   annotations: {
      datum: {
        stem: {
          color: 'transparent',
          length: 1,
          style: 'stem'
        },
      }, */
    textStyle: {
        fontName: 'Arial',
        fontSize: 14,
        bold: false,
        color: '#000001',
    },
    vAxis: {
        title: 'Temperature [°C]',
    },
  };
  
  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          data={responseObj}
          loader={<div>Loading Chart</div>}
          width={900}
          height={400}
          chartType="ColumnChart"
          options={options}
        />
      </Box>
    </Styled.Container>
  )
}