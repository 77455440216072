import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';

const FormikAutocomplete = ({ textFieldProps, ...props }: any) => {
  const { form: { setTouched, setFieldValue } } = props
  const { error, helperText, ...field } = fieldToTextField(props)
  const { name } = field
  const filter = createFilterOptions()

  return (
    <Autocomplete
      {...props}
      {...field}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      onChange={ (_: any, value: any) => setFieldValue(name, value) }
      onBlur={ () => setTouched({ ['' + name]: true }) }
      renderInput={ props => (
        <TextField {...props} {...textFieldProps} helperText={helperText} error={error} />
      )}
    />
  );
}

export default FormikAutocomplete;