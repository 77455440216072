import _ from "lodash"
import React, { useState, useEffect } from "react"

import { connect } from "react-redux"
import { ScreenActions } from "../../../../store/screen/actions"
import { Field } from "formik"
import { TextField } from "formik-material-ui"
import { DefaultPanelContainer, DefaultPanelHeader, DefaultPanelBody } from "../../DefaultPanel"
import {
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core"

interface IScreenProps {
  screenValues: any
  loadScreensByType: any
  fetchScreenDetails: any
  setFieldValue: any
  disabledForm: boolean
}

const Screens = ({screenValues, loadScreensByType, fetchScreenDetails, setFieldValue, disabledForm}: IScreenProps) => {
  const prefix = "greenhouse-screenValues-";
  const [shadeScreens, setShadeScreens] = useState(null)
  const [shadeScreenValues, setShadeScreenValues] = useState(screenValues.shadeScreen)

  const [thermalScreens, setThermalScreens] = useState(null)
  const [thermalScreenValues, setThermalScreenValues] = useState(screenValues.thermalScreen)

  const [blackoutScreens, setBlackoutScreens] = useState(null)
  const [blackoutScreenValues, setBlackoutScreenValues] = useState(screenValues.blackoutScreen)

  useEffect(() => {
    loadScreensByType('Shade').then((res: any) => {
      setShadeScreens(res)
    })

    loadScreensByType('Thermal').then((res: any) => {
      setThermalScreens(res)
    })

    loadScreensByType('Blackout').then((res: any) => {
      setBlackoutScreens(res)
    })
  }, [loadScreensByType])

  const handleChange = (e: any, type: 'shade' | 'thermal' | 'blackout') => {
    const id = e.target.value
    if (id) {
      fetchScreenDetails(id).then((res: any) => {
        switch (type) {
          case 'shade':
            setShadeScreenValues({
              par: res.par,
              nir: res.nir,
              es: res.es
            })
            setFieldValue(prefix + "shadeScreen-par", res.par)
            setFieldValue(prefix + "shadeScreen-nir", res.nir)
            setFieldValue(prefix + "shadeScreen-es", res.es)
            break
          case 'thermal':
            setThermalScreenValues({
              par: res.par,
              nir: res.nir,
              es: res.es
            })
            setFieldValue(prefix + "thermalScreen-par", res.par)
            setFieldValue(prefix + "thermalScreen-nir", res.nir)
            setFieldValue(prefix + "thermalScreen-es", res.es)
            break
          case 'blackout':
            setBlackoutScreenValues({
              par: res.par,
              nir: res.nir,
              es: res.es
            })
            setFieldValue(prefix + "blackoutScreen-par", res.par)
            setFieldValue(prefix + "blackoutScreen-nir", res.nir)
            setFieldValue(prefix + "blackoutScreen-es", res.es)
            break
          default: break
        }
      })
    }
  }

  return (
    <>
      <DefaultPanelContainer>
        <DefaultPanelHeader prefix={prefix + "shadeScreen"} name="Shade screens" />
        <DefaultPanelBody>
          <Grid container spacing={2}>
            <Grid item xs={3}>
            { shadeScreens ?
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="selectShadeScreen">Select a shade screen</InputLabel>
                <Select labelId="selectShadeScreen" label="Select a shade screen" name="shade" onChange={(e: any) => handleChange(e, 'shade')} disabled={disabledForm}>
                  <MenuItem value={undefined}>Select a shade screen</MenuItem>
                  {
                    _.map(shadeScreens, (item: any, key: number) => {
                      return <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              : "Loading..."}
            </Grid>

            {
              _.map(shadeScreenValues, (value: any, title: string) => {
                return (
                  <Grid item xs={3} key={prefix + "shadeScreen-" + title}>
                    <Field component={TextField} name={prefix + "shadeScreen-" + title} label={title} variant="outlined" fullWidth disabled={disabledForm} />
                  </Grid>
                )
              })
            }
          </Grid>
        </DefaultPanelBody>
      </DefaultPanelContainer>

      <DefaultPanelContainer>
        <DefaultPanelHeader prefix={prefix + "thermalScreen"} name="Thermal screens" />
        <DefaultPanelBody>
          <Grid container spacing={2}>
            <Grid item xs={3}>
            { thermalScreens ?
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="selectThermalScreen">Select a thermal screen</InputLabel>
                <Select labelId="selectThermalScreen" label="Select a thermal screen" name="thermal" onChange={(e: any) => handleChange(e, 'thermal')} disabled={disabledForm}>
                  <MenuItem value={undefined}>Select a thermal screen</MenuItem>
                  {
                    _.map(thermalScreens, (item: any, key: number) => {
                      return <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              : "Loading..."}
            </Grid>

            {
              _.map(thermalScreenValues, (value: any, title: string) => {
                return (
                  <Grid item xs={3} key={prefix + "thermalScreen-" + title}>
                    <Field component={TextField} name={prefix + "thermalScreen-" + title} label={title} variant="outlined" fullWidth disabled={disabledForm} />
                  </Grid>
                )
              })
            }
          </Grid>
        </DefaultPanelBody>
      </DefaultPanelContainer>

      <DefaultPanelContainer>
        <DefaultPanelHeader prefix={prefix + "blackoutScreen"} name="Blackout screens" />
        <DefaultPanelBody>
          <Grid container spacing={2}>
            <Grid item xs={3}>
            { blackoutScreens ?
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="selectBlackoutScreen">Select a blackout screen</InputLabel>
                <Select labelId="selectBlackoutScreen" label="Select a blackout screen" name="blackout" onChange={(e: any) => handleChange(e, 'blackout')} disabled={disabledForm}>
                  <MenuItem value={undefined}>Select a blackout screen</MenuItem>
                  {
                    _.map(blackoutScreens, (item: any, key: number) => {
                      return <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              : "Loading..."}
            </Grid>

            {
              _.map(blackoutScreenValues, (value: any, title: string) => {
                return (
                  <Grid item xs={3} key={prefix + "blackoutScreen-" + title}>
                    <Field component={TextField} name={prefix + "blackoutScreen-" + title} label={title} variant="outlined" fullWidth disabled={disabledForm} />
                  </Grid>
                )
              })
            }
          </Grid>
        </DefaultPanelBody>
      </DefaultPanelContainer>
    </>
  )
}

export default connect(
  null,
  {
    loadScreensByType: (type: string) => ScreenActions.FetchByType(type),
    fetchScreenDetails: (id: number) => ScreenActions.Fetch(id)
  }
)(Screens)
