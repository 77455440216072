import React, { useState, useEffect } from "react"
import _ from "lodash"
import { connect } from "react-redux"
import { format } from "date-fns";
import { useConfirm } from 'material-ui-confirm';

import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Box,
  Tooltip,
  Grid,
  Link,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from "@material-ui/core"

import { AppState } from "../../store"
import { ClientActions } from "../../store/client/actions"
import { ScenarioActions } from "../../store/scenario/actions"
import * as Styled from "./styled"
import { IUser } from "../../interfaces/user"
import { DownloadScenarioDialog } from '../DownloadScenarioDialog'

interface IStateProps {
  clients: any
  scenarios: any
  user: IUser
}

interface IDispatchProps {
  loadClients: any
  loadScenarios: any
  deleteScenario: any
  duplicateScenario: any
}

type TCombinedProps = IStateProps & IDispatchProps

const ScenariosTable = ({
  clients,
  scenarios,
  user,
  loadClients,
  loadScenarios,
  deleteScenario,
  duplicateScenario,
}: TCombinedProps) => {
  const [scenariosFiltered, setScenariosFiltered] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [showDownloadDialog, setShowDownloadDialog] = useState(-1)
  const confirm = useConfirm();

  useEffect(() => {
    loadClients();
    loadScenarios()
  }, [loadClients, loadScenarios])

  useEffect(() => {
    setScenariosFiltered(scenarios)
  }, [scenarios])

  const filterScenarios = (e: any) => {
    e.preventDefault()
    const selected = e.target.value

    let filteredScenarios = scenarios
    if (selected !== "") {
      filteredScenarios = _.filter(scenarios, (data: any) => {
        return data.clientId === selected
      })
    }

    setSelectedClient(selected)
    setScenariosFiltered(filteredScenarios)
  }

  const handleDeleteScenario = (e: any, id: number) => {
    e.preventDefault();
    confirm({
      confirmationText: 'Yes',
      cancellationText: 'No',
      title: 'Are you sure you want to delete this scenario?'
    })
      .then(() => {
        deleteScenario(id).then(() => {
          loadScenarios()
        })
      })
  }

  const handleDuplicateScenario = (e: any, id: number) => {
    e.preventDefault();
    duplicateScenario(id).then(() => {
      loadScenarios()
    })
  }

  const closeDownloadDialog = () => {
    setShowDownloadDialog(-1)
  }

  return (
    <React.Fragment>
      <Box mt={4}>
        <Grid container direction="row-reverse">
          <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="selectedClient">Select a client</InputLabel>
              <Select labelId="selectedClient" label="Select a client" onChange={(e: any) => filterScenarios(e)} value={selectedClient}>
                <MenuItem value="">Select no client</MenuItem>
                {clients && clients.map((item: any, key: number) =>
                  <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <TableContainer>
          <Table>
            <TableHead>
              <Styled.ThRow>
                <Styled.Th></Styled.Th>
                <Styled.Th>Name</Styled.Th>
                <Styled.Th>Created</Styled.Th>
                <Styled.Th>Client</Styled.Th>
                <Styled.Th align="right">Type</Styled.Th>
                <Styled.Th align="right">Document</Styled.Th>
                <Styled.Th align="right">Actions</Styled.Th>
              </Styled.ThRow>
            </TableHead>
            <TableBody>
              {scenariosFiltered && scenariosFiltered.map((item: any, key: number) =>
                <Styled.TdRow key={key}>
                  <Styled.Td orb="true">
                    <Tooltip title={item.state}>
                      {item.state === "Draft" ?
                        <Styled.Orb type="draft"></Styled.Orb>
                        : item.state === "Collecting weather data" ?
                          <Styled.Orb type="collecting"></Styled.Orb>
                          : item.state === "Data collected" ?
                            <Styled.Orb type="collected"></Styled.Orb>
                            : item.state === "Report is generated" || item.state === "Finished" ?
                              <Styled.Orb type="generated"></Styled.Orb>
                              : <></>}
                    </Tooltip>
                  </Styled.Td>

                  <Styled.Td><strong>{item.name}</strong></Styled.Td>
                  <Styled.Td>{format(Date.parse(item.creationDate), 'd MMMM yyyy HH:mm')}</Styled.Td>
                  <Styled.Td>{item.clientName}</Styled.Td>
                  <Styled.Td align="right">{item.scenarioType}</Styled.Td>
                  <Styled.Td align="right">
                    {item.state === "Report is generated" || item.state === "Finished" ? (
                      <Link href={`/report/${item.token}`}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >Show report</Link>
                    ) : ""}
                  </Styled.Td>
                  <Styled.Td align="right">
                    {item.state === "Report is generated" || item.state === "Finished" ? (
                      <a href="#download-scenario" onClick={() => setShowDownloadDialog(item.id)}>
                        <img src="/assets/img/icon-download.svg" width="25" height="25" className="td__icon" alt="Download scenario" />
                      </a>
                    ) : ''}

                    <Link href={`/scenario/${item.id}`}>
                      <img src="/assets/img/icon-edit.svg" width="25" height="25" className="td__icon" alt="Edit scenario" />
                    </Link>

                    {item.scenarioType !== 'Climate profile' && user.permissions.canCreateGreenhouseCalculationScenario && (
                      <a href="#duplicate-scenario" onClick={(e: any) => handleDuplicateScenario(e, item.id)}>
                        <img src="/assets/img/icon-copy.svg" width="25" height="25" className="td__icon" alt="Duplicate scenario" />
                      </a>
                    )}

                    <a href="#delete-scenario" onClick={(e: any) => handleDeleteScenario(e, item.id)}>
                      <img src="/assets/img/icon-delete.svg" width="25" height="25" className="td__icon" alt="Delete scenario" />
                    </a>
                  </Styled.Td>
                </Styled.TdRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {showDownloadDialog > -1 && (
        <DownloadScenarioDialog id={showDownloadDialog} onClose={closeDownloadDialog} />
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state: AppState) => ({
  clients: state.clients && state.clients.list,
  scenarios: state.scenarios.list,
  user: state.user.user,
})

export default connect(
  mapStateToProps,
  {
    loadClients: () => ClientActions.FetchAll(),
    loadScenarios: () => ScenarioActions.FetchAll(),
    deleteScenario: (id: number) => ScenarioActions.Delete(id),
    duplicateScenario: (id: number) => ScenarioActions.Duplicate(id)
  }
)(ScenariosTable)
