import styled, { AnyStyledComponent } from "styled-components"
import { 
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
 } from '@material-ui/core/'

export const Panel: AnyStyledComponent = styled(ExpansionPanel)`
  ${({ theme }) => `
    border: 1px solid rgba(0, 0, 0, .125);
    box-shadow: none;

    &:not(last-child) {
      border-bottom: 0,
    }

    &:before {
      display: none;
    }

    &.Mui-expanded {
      margin: auto;
    }
  `}
`
export const PanelTitle: AnyStyledComponent = styled(Typography)`
  flex: 1;
  padding: 12px 7px;
`

export const PanelSummary: AnyStyledComponent = styled(ExpansionPanelSummary)`
  ${({ theme }) => `
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    margin-bottom: -1px;
    min-height: 56px;

    &.Mui-expanded {
      min-height: 56px;
    }
    > .MuiExpansionPanelSummary-content {
      align-items: center;
      margin: 0;
    }
  `}
`

export const PanelDetails: AnyStyledComponent = styled(ExpansionPanelDetails)`
  ${({ padding }: any) => `
    display: block;
    padding: ${padding ? '20px' : '10px 0'};
    li {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  `}
`

export const PlaceholderKey: AnyStyledComponent = styled(Typography)`
  overflow:auto;
`

export const TabContent: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    background-color: #fff;
    margin: 2em 0 4em 0;
    display: block;
  `}
`
