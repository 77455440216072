import { IUser } from "../../interfaces/user"

export interface IToken {
  access_token: string,
  refresh_token: string
}

export interface UserState {
  tokens: IToken
  user: IUser
}

export const USER_LOADING = "USER_LOADING";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_FETCH = "USER_FETCH";

interface IUserLoginAction {
  type: typeof USER_LOGIN;
  payload: IToken;
}

interface IUserFetchAction {
  type: typeof USER_FETCH;
  payload: IUser;
}

interface IUserLogoutAction {
  type: typeof USER_LOGOUT;
}

interface IUserLoadingAction {
  type: typeof USER_LOADING;
  payload: boolean;
}

export type UserActionTypes = IUserLoginAction | IUserLogoutAction | IUserFetchAction | IUserLoadingAction;
