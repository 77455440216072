import React, { useEffect, useState } from "react";
import qs from "query-string";
import { useFormik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Switch,
  MenuItem,
  TextField,
  Grid,
  FormControlLabel,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import utils from "@date-io/date-fns";
import moment from "moment";
import { KuboScenarioService } from "../../services/api/Kubo/ScenarioService";

const { isAfter, isBefore } = new utils();

interface IProps {
  id: number;
  onClose: () => void;
}

const hours = [
  0,
  1,
  2,
  3,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
];

export const DownloadScenarioDialog: React.FC<IProps> = ({ id, onClose }) => {
  const [allowedFromDate, setAllowedFromDate] = useState(new Date());
  const [allowedToDate, setAllowedToDate] = useState(new Date());
  const [touchFrom, setTouchFrom] = useState(false);
  const [touchTo, setTouchTo] = useState(false);

  const formik = useFormik({
    initialValues: {
      fromDate: null,
      toDate: null,
      fromHour: 0,
      toHour: 23,
      use24HoursADay: false,
    },
    validate: (values: any) => {
      const errors: { [key: string]: string } = {};

      if (values.fromDate && moment(values.fromDate).isValid() === false) {
        errors.fromDate =
          "Fill in a correct date in the format of day/month/year";
      }

      if (values.toDate && moment(values.toDate).isValid() === false) {
        errors.toDate =
          "Fill in a correct date in the format of day/month/year";
      }

      if (values.fromDate && isBefore(values.fromDate, allowedFromDate)) {
        errors.fromDate =
          "From date cannot be before " +
          moment(allowedFromDate).format("YYYY-MM-DD");
      }

      if (touchFrom === true && values.fromDate === null) {
        errors.fromDate =
          "Fill in a correct date in the format of day/month/year";
      }

      if (touchTo === true && values.toDate === null) {
        errors.toDate =
          "Fill in a correct date in the format of day/month/year";
      }

      if (values.toDate && isAfter(values.toDate, allowedToDate)) {
        errors.fromDate =
          "From date cannot be before " +
          moment(allowedToDate).format("YYYY-MM-DD");
      }

      if (values.fromDate && values.toDate) {
        if (isAfter(values.fromDate, values.toDate)) {
          errors.fromDate = "From date cannot be after the to date.";
        }

        if (isBefore(values.toDate, values.fromDate)) {
          errors.toDate = "To date cannot be before the from date.";
        }
      }

      if (values.fromHour > values.toHour) {
        errors.fromHour = "From hour cannot be after to hour.";
      }

      if (values.toHour < values.fromHour) {
        errors.toHour = "To hour cannot be before from hour.";
      }

      return errors;
    },
    onSubmit: async (values: any, { setSubmitting }) => {
      if (values.use24HoursADay) {
        delete values.fromHour;
        delete values.toHour;
      }

      try {
        const query = {
          ...values,
          scenarioId: id,
          fromDate: moment(values.fromDate).format("YYYY-MM-DD"),
          toDate: moment(values.toDate).format("YYYY-MM-DD"),
        };

        window.open(
          `${process.env.REACT_APP_API_URL
          }/api/weatherdata/Download?${qs.stringify(query)}`,
          "_blank"
        );

        onClose();
      } catch (e) {
        console.error(e);
      }

      setSubmitting(false);
    },
  });

  useEffect(() => {
    const fn = async () => {
      try {
        const {
          fromDate,
          toDate,
        } = await KuboScenarioService.FetchScenarioReportRange(id);
        setAllowedFromDate(new Date(fromDate));
        setAllowedToDate(new Date(toDate));

        formik.setFieldValue("fromDate", new Date(fromDate));
        formik.setFieldValue("toDate", new Date(toDate));
      } catch (e) {
        console.error(e);
      }
    };

    fn();
  }, [id]);

  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <DialogTitle>Download source data of scenario</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={utils}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="From date"
                  name="fromDate"
                  minDate={allowedFromDate}
                  maxDate={allowedToDate}
                  value={formik.values.fromDate}
                  onChange={(date: MaterialUiPickersDate) => {
                    setTouchFrom(true);
                    formik.setFieldValue("fromDate", date);
                  }}
                  error={!!formik.errors.fromDate}
                  helperText={formik.errors.fromDate}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="To date"
                  name="toDate"
                  minDate={allowedFromDate}
                  maxDate={allowedToDate}
                  value={formik.values.toDate}
                  onChange={(date: MaterialUiPickersDate) => {
                    setTouchTo(true);
                    formik.setFieldValue("toDate", date);
                  }}
                  error={!!formik.errors.toDate}
                  helperText={formik.errors.toDate}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item>
                <TextField
                  select
                  disabled={formik.values.use24HoursADay}
                  name="fromHour"
                  value={formik.values.fromHour}
                  onChange={formik.handleChange}
                  margin="normal"
                  label="From"
                  error={
                    !formik.values.use24HoursADay && !!formik.errors.fromHour
                  }
                  helperText={
                    !formik.values.use24HoursADay ? formik.errors.fromHour : ""
                  }
                >
                  {hours.map((hour: number, i: number) => (
                    <MenuItem key={i} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  select
                  disabled={formik.values.use24HoursADay}
                  name="toHour"
                  value={formik.values.toHour}
                  onChange={formik.handleChange}
                  margin="normal"
                  label="To"
                  error={
                    !formik.values.use24HoursADay && !!formik.errors.toHour
                  }
                  helperText={
                    !formik.values.use24HoursADay ? formik.errors.toHour : ""
                  }
                >
                  {hours.map((hour: number, i: number) => (
                    <MenuItem key={i} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      name="use24HoursADay"
                      checked={formik.values.use24HoursADay}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Use 24 hours a day"
                />
              </Grid>
            </Grid>
          </form>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={formik.submitForm}
          disabled={!formik.isValid && formik.isSubmitting}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};
