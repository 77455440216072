import React from 'react'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import { monthColumns } from '../../../../helpers'
import * as Styled from '../../styled'
import { IGraphItem } from '../../../../interfaces/graph'

interface IElectricityRequirementRow {
  ecB: number
  efan: number
  generalEquipment: number
  month: number
  paLCanIncd: number
}

export const ElectricityRequirement = ({ resultData: data, description, name, pageBreakAfter }: IGraphItem) => {

  let responseObj: any = {};
  responseObj.cols = [];
  responseObj.cols.push({id: 'month', label: 'Month', type: 'date'});

  const maxAL: number = Math.max.apply(null, data.map((obj: IElectricityRequirementRow) => obj.paLCanIncd));
  const maxCB: number = Math.max.apply(null, data.map((obj: IElectricityRequirementRow) => obj.ecB));
  const maxGE: number = Math.max.apply(null, data.map((obj: IElectricityRequirementRow) => obj.generalEquipment));
  const maxAHU: number = Math.max.apply(null, data.map((obj: IElectricityRequirementRow) => obj.efan));

  if (maxAL > 0) {
    responseObj.cols.push({id: 'pALCanIncd', label: 'Artificial Lights', type: 'number'})
    responseObj.cols.push({id: 'tooltip1', label: null, role: 'tooltip', 'p': { 'html': true } })
  }
  if (maxCB > 0) {
    responseObj.cols.push({id: 'eCB', label: 'Cold Block', type: 'number'})
    responseObj.cols.push({id: 'tooltip2', label: null, role: 'tooltip', 'p': { 'html': true } })
  }
  if (maxGE > 0) {
    responseObj.cols.push({id: 'generalEquipment', label: 'General Equipment', type: 'number'})
    responseObj.cols.push({id: 'tooltip3', label: null, role: 'tooltip', 'p': { 'html': true } })
  }
  if (maxAHU > 0) {
    responseObj.cols.push({id: 'eFan', label: 'AHU', type: 'number'})
    responseObj.cols.push({id: 'tooltip4', label: null, role: 'tooltip', 'p': { 'html': true } })
  }

  const now = new Date()

  responseObj.rows = data.map((obj: IElectricityRequirementRow) => {
    let row = []
    row.push({ v: new Date(now.getFullYear() + '/' + obj.month + '/01') })

    const tooltip = `<div style="padding: 5px; white-space: nowrap">
      ${maxAL > 0 ? 'AL: ' + (obj.paLCanIncd > 0 ? obj.paLCanIncd.toFixed(3) : 0) + '<br />' : ''}
      ${maxCB > 0 ? 'Cold Block: ' + (obj.ecB > 0 ? obj.ecB.toFixed(3) : 0) + '<br />' : ''}
      ${maxGE > 0 ? 'General Equipment: ' + (obj.generalEquipment > 0 ? obj.generalEquipment.toFixed(3) : 0) + '<br />' : ''}
      ${maxAHU > 0 ? 'AHU: ' + (obj.efan > 0 ? obj.efan.toFixed(3) : 0) + '<br />' : ''}
    </div>`
    
    if (maxAL > 0) {
      row.push({ v: obj.paLCanIncd })
      row.push({ v: tooltip })
    }
    if (maxCB > 0) {
      row.push({ v: obj.ecB })
      row.push({ v: tooltip })
    }
    if (maxGE > 0) {
      row.push({ v: obj.generalEquipment })
      row.push({ v: tooltip })
    }
    if (maxAHU > 0) {
      row.push({ v: obj.efan })
      row.push({ v: tooltip })
    }

    return { c: row }
  })

  const options = {
    width: 900,
    height: 400,
    seriesType: 'area',
    isStacked: true,
    gridlines: 'white',
    series: {},
    vAxes: {
      0: {
        title: 'Electrical Requirement [KWh/m2]',
        gridlines: {color: '#EAECEE'},
      },
    },
    hAxis: {
      format: 'MMM',
      title: 'Month',
      gridlines: {color: 'white'},
    },
    tooltip: { isHtml: true },
  };

  let dataTable: any = {}
  dataTable.cols = [
    {type: 'string', label: 'Equipment', id: '0'},
    ...monthColumns,
  ]

  dataTable.rows = []

  if (maxAL > 0) {
    dataTable.rows.push({
      c: [
        { v: 'Artificial Lights' },
        ...data.map((month: IElectricityRequirementRow) => ({ v: month.paLCanIncd })),
      ]
    })
  }
  if (maxCB > 0) {
    dataTable.rows.push({
      c: [
        { v: 'Cold Block' },
        ...data.map((month: IElectricityRequirementRow) => ({ v: month.ecB })),
      ]
    })
  }
  if (maxGE > 0) {
    dataTable.rows.push({
      c: [
        { v: 'General Equipment' },
        ...data.map((month: IElectricityRequirementRow) => ({ v: month.generalEquipment })),
      ]
    })
  }
  if (maxAHU > 0) {
    dataTable.rows.push({
      c: [
        { v: 'AHU' },
        ...data.map((month: IElectricityRequirementRow) => ({ v: month.efan })),
      ]
    })
  }

  const optionsTable = {
    width: 900,
    allowHtml: true,
    alternatingRowStyle: true,
    cssClassNames: {headerRow: 'bigAndBoldClass', tableRow: 'tableRow', oddTableRow: 'oddRows'},
  };
  
  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          height={400}
          width={900}
          data={responseObj}
          loader={<div>Loading Chart</div>}
          chartType="AreaChart"
          options={options}
        />
      </Box>
      <Box pb={2}>
        <Chart
          height="auto"
          width={900}
          data={dataTable}
          loader={<div>Loading Chart</div>}
          chartType="Table"
          options={optionsTable}
        />
      </Box>
    </Styled.Container>
  )
}