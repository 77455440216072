import { Dispatch } from "redux"
import * as Types from "./types"

export const NotificationActions = {
  Enqueue: (notification: any) => {
    return async (dispatch: Dispatch) => {
      const key = notification.options && notification.options.key;

      dispatch({
        type: Types.ENQUEUE_SNACKBAR,
        notification: {
          ...notification,
          key: key || new Date().getTime() + Math.random(),
        }
      })
    }
  },

  Close: (key: number) => {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: Types.CLOSE_SNACKBAR,
        dismissAll: !key, // dismiss all if no key has been defined
        key,
      })
    }
  },

  Remove: (key: number) => {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: Types.REMOVE_SNACKBAR,
        key,
      })
    }
  },
}
