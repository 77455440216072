import React from "react"
import { connect } from "react-redux"

import { AppState } from "../../../store"
import Map from '../../Map'
import { IScenario } from "../../../interfaces/scenario"
import { Field } from "formik"

interface ILocationTabProps {
  scenario: IScenario
  location: any
  setFieldValue: any
  disabledForm: boolean
}

const LocationTab = ({scenario, setFieldValue, location, disabledForm}: ILocationTabProps) => {
  const initial = {
    latitude: scenario?.location?.latitude ?? 52.15611113,
    longitude: scenario?.location?.longitude ?? 5.3878266,
    city: scenario?.location?.city ?? '',
    country: scenario?.location?.country ?? '',
  }

  return (
    <>
      <Field type="hidden" name="location-id" label={"id"} />
      <Field type="hidden" name="location-city" label={"city"} />
      <Field type="hidden" name="location-country" label={"country"} />

      <Map
        center={{lat: initial.latitude, lng: initial.longitude}}
        city={initial.city}
        country={initial.country}
        zoom={15}
        disabledForm={disabledForm}
        setFieldValue={setFieldValue}
      />
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  location: state.scenarios.scenario.location,
})

export default connect(
  mapStateToProps,
)(LocationTab)
