import _ from "lodash"
import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { connect } from "react-redux"
import { useConfirm } from 'material-ui-confirm';
import { Button, Grid } from "@material-ui/core"
import { Formik, Form } from "formik"
import { IScenario } from "../interfaces/scenario"
import { AppState } from "../store"
import { ScenarioActions } from "../store/scenario/actions"
import { CropActions } from "../store/crop/actions"
import Hero from "../components/Hero"
import Header from "../components/Header"
import ScenarioEdit from "../components/ScenarioEdit"
import { useNotify } from "../Hooks"
import GraphTab from "../components/ScenarioEdit/GraphTab"
import ErrorPage from '../pages/Error'

import { unflatten } from "flat"

interface IProps {
  match: any
  history: any
}

interface IStateProps {
  scenario: IScenario | undefined
}

interface IDispatchProps {
  fetchAllCrops: () => void
  fetchScenario: (id: number) => Promise<any>
  updateScenario: (data: any) => Promise<any>
  runScenario: (id: number) => Promise<any>
}

type TCombinedProps = IProps & IStateProps & IDispatchProps

const scenarioSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, 'too long')
    .required('Required'),
  note: Yup.string()
    .max(255, 'too long')
    .nullable()
})

const Scenario = ({
  scenario,
  fetchAllCrops,
  fetchScenario,
  updateScenario,
  runScenario,
  match,
  history,
}: TCombinedProps) => {
  const [ready, setReady] = useState(false)
  const [error, setError] = useState(false)
  const notify = useNotify()
  const confirm = useConfirm()

  useEffect(() => {
    fetchAllCrops()
    fetchScenario(match.params.id).then(() => {
      setReady(true)
    }).catch(() => {
      setError(true)
    })
  }, [match, fetchAllCrops, fetchScenario])

  const getInitialValues = (scenario: IScenario) => {
    let result: any = {}

    const initialValues = (data: any, prnt ?: string): any => {
      if (typeof data === 'object' && data !== null) {
        _.map(_.keys(data), (title, i, values) => {
          const parentTitle = prnt ? prnt + '-' : ''
          return initialValues(data[title], parentTitle + title)
        })
      } else {
        const ignoreKeys = [
          'greenhouse-screenValues-thermalScreen',
          'greenhouse-screenValues-blackoutScreen',
          'greenhouse-screenValues-shadeScreen'
        ];
        let ignoredKeyExists = _.find(ignoreKeys, (key: string) => { return key === prnt })

        if (typeof prnt === 'string' && !ignoredKeyExists) {
          result[prnt] = data
        }
      }
      return result
    }

    return _.assign(initialValues(scenario), {
      id: scenario.id,
      name: scenario.name,
      notes: scenario.notes ? scenario.notes : '',
      createdBy: scenario.createdBy,
      saveAndRun: false,
    })
  }

  const handleReturn = (touched: any) => {
    // if inputs have been touched, show confirm
    if (Object.keys(touched).length > 0) {
      confirm({ 
        confirmationText: 'Yes',
        cancellationText: 'No',
        title: 'Possible changes will be discarded, do you want to continue?'
      })
        .then(() => { 
          history.push("/")
      })
    } else {
      history.push("/")
    }
  }

  if (error) {
    return <ErrorPage match={match} history={history} />
  }

  return (
    <>
      <Header history={history} />
      { ready && scenario && (
        <>
          <Formik
            initialValues={getInitialValues(scenario)}
            validationSchema={scenarioSchema}
            onSubmit={(values, {setSubmitting}) => {

              // values-location = null
              // values-location-lat = filled
              // unflatten does not add further depth after null
              delete values["location"]
              const data: any = unflatten(values, {delimiter: "-"})

              function handleError(error: any) {
                notify(error, "error")

                setSubmitting(false)
              }

              // if save and run, then first ask for confirmation
              if (values.saveAndRun) {
                confirm({ 
                  confirmationText: 'Yes',
                  cancellationText: 'No',
                  title: 'When you run the scenario, it is no longer possible to make changes. Are you sure?'
                })
                  .then(() => { 
                    updateScenario(data).then(() => {
                      runScenario(values.id).then((result) => {
                        if (result.isSuccess) {
                          history.push("/")
                          notify("Scenario scheduled for run!", "success")

                          setSubmitting(false)
                        } else {
                          notify("Errror: " + result.failureReason, "error")

                          setSubmitting(false)
                        }
                      }).catch((e: any) => {
                        handleError(e)
                      })
                    }).catch((e: any) => {
                      handleError(e.message)
                    })
                })

              } else {
                // just save
                updateScenario(data).then(() => {
                  // history.push("/")
                  notify("Scenario saved", "success")

                  setSubmitting(false)
                }).catch((e: any) => {
                  handleError(e.message)
                })
              }
              
            }}>
              {({ setFieldValue, handleSubmit, ...props }) => (
                <Form>
                  <Hero title="Scenarios">
                    { scenario.state === 'Draft' ? (
                    <Grid container spacing={2}>
                      <Grid item xs={4} className="cta">
                        <Button onClick={() => handleReturn(props.touched)} fullWidth>Back to overview</Button>
                      </Grid>
                      <Grid item xs={4} className="cta cta--secondary">
                        <Button variant="contained" onClick={(e: any)=>{
                            setFieldValue('saveAndRun',true)
                            handleSubmit(e)
                          }}
                          color="secondary" fullWidth>Save and run</Button>
                      </Grid>
                      <Grid item xs={4} className="cta">
                        <Button variant="contained" onClick={(e: any)=>{
                            setFieldValue('saveAndRun', false)
                            handleSubmit(e)
                          }}
                          color="secondary" fullWidth>Save scenario</Button>
                      </Grid>
                    </Grid>
                    ) : (
                    <Grid container spacing={2} justify="flex-end">
                      <Grid item xs={6} className="cta cta--secondary">
                        <Button href="/" variant="contained" color="secondary" fullWidth>Back to overview</Button>
                      </Grid>
                    </Grid>
                    ) }
                  </Hero>

                  <section>
                    <ScenarioEdit 
                      scenario={scenario} 
                      setFieldValue={setFieldValue} 
                      handleSubmit={handleSubmit} 
                      history={history} 
                      disabledForm={scenario.state !== 'Draft'} 
                      {...props}
                    />
                  </section>
                </Form>
              )}
          </Formik>
          { scenario.state === 'Finished' && (
            <GraphTab history={history} scenario={scenario} />
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  scenario: state.scenarios.scenario,
})

export default connect(
  mapStateToProps,
  {
    fetchAllCrops: () => CropActions.FetchAll(),
    fetchScenario: (id: number) => ScenarioActions.Fetch(id),
    updateScenario: (scenario: IScenario) => ScenarioActions.Update(scenario),
    runScenario: (id: number) => ScenarioActions.Run(id),
  }
)(Scenario)
