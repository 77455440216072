import React from 'react'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import { monthColumns } from '../../../../helpers'
import * as Styled from '../../styled'
import { IGraphItem } from '../../../../interfaces/graph'

interface IRadiationOnTheCropRow {
  avgDailyRadSum: number
  avgDailySLRadSum: number
  monthNo: number
}

export const RadiationOnTheCrop = ({ resultData: data, description, name, pageBreakAfter }: IGraphItem) => {

  let responseObj: any = {};
  responseObj.cols = [
    { id: 'month', label: 'Month', type: 'date' },
    { id: 'solarRad', label: 'Solar Radiation [J/cm2/day]', type: 'number' },
    { id: 'tooltip1', label: null, role: 'tooltip', 'p': { 'html': true } },
    { id: 'alRad', label: 'SL Radiation [J/cm2/day]', type: 'number' },
    { id: 'tooltip2', label: null, role: 'tooltip', 'p': { 'html': true } },
  ];
  responseObj.rows = data.map((obj: IRadiationOnTheCropRow) => ({
    c: [
      { v: new Date('2000/' + obj.monthNo + '/01') },
      { v: obj.avgDailyRadSum },
      { v: '<div style="padding:5px; white-space: nowrap;"><strong>Solar: ' + (obj.avgDailyRadSum > 0 ? Number(obj.avgDailyRadSum).toFixed(3) : 0) + '</strong><br />SL: ' + (obj.avgDailySLRadSum > 0 ? Number(obj.avgDailySLRadSum).toFixed(3) : 0) + '</div>' },
      { v: obj.avgDailySLRadSum },
      { v: '<div style="padding:5px; white-space: nowrap;">Solar: ' + (obj.avgDailyRadSum > 0 ? Number(obj.avgDailyRadSum).toFixed(3) : 0) + '<br /><strong>SL: ' + (obj.avgDailySLRadSum > 0 ? Number(obj.avgDailySLRadSum).toFixed(3) : 0) + '</strong></div>' },
    ],
  }));

  const options = {
    height: 400,
    width: 900,
    hAxis: {
      title: 'Month',
      titleTextStyle: {color: '#333'},
      format: 'MMM',
    },
    seriesType: 'area',
    colors: ['gold', 'grey'],
    isStacked: true,
    vAxis: {
      title: 'PAR Radiation [J/cm2/day]',
    },
    tooltip: { isHtml: true },
  };


  let dataTable: any = {}
  dataTable.cols = [
    {type: 'string', label: 'Source', id: '0'},
    ...monthColumns,
  ]

  dataTable.rows = []

  dataTable.rows.push({
    c: [
      {v: 'Solar Radiation [J/cm2/day]'},
      ...data.map((month: IRadiationOnTheCropRow) => ({ v: month.avgDailyRadSum })),
    ]
  })

  dataTable.rows.push({
    c: [
      {v: 'SL Radiation [J/cm2/day]'},
      ...data.map((month: IRadiationOnTheCropRow) => ({ v: month.avgDailySLRadSum })),
    ]
  })

  const optionsTable = {
    width: 900,
    allowHtml: true,
    alternatingRowStyle: true,
    cssClassNames: {headerRow: 'bigAndBoldClass', tableRow: 'tableRow', oddTableRow: 'oddRows'},
  };
  
  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          height={400}
          width={900}
          data={responseObj}
          loader={<div>Loading Chart</div>}
          chartType="AreaChart"
          options={options}
        />
      </Box>
      <Box pb={2}>
        <Chart
          height="auto"
          width={900}
          data={dataTable}
          loader={<div>Loading Chart</div>}
          chartType="Table"
          options={optionsTable}
        />
      </Box>
    </Styled.Container>
  )
}