import React from 'react'
import _ from 'lodash'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import * as Styled from '../../styled'

interface IElectricityPieRow {
  ecB: number
  efan: number
  generalEquipment: number
  month: number
  paLCanIncd: number
}

export const ElectricityPie = ({ resultData: data, description, name }: any) => {

  let responseObj: any = {}
  responseObj.cols = [
    {type: 'string', label: 'Equipment'},
    {type: 'number', label: 'Consumption kWh/m2'},
  ]

  // calculate sums
  const sumPAL = _.sumBy(data, (item: IElectricityPieRow) => item.paLCanIncd)
  const sumECB = _.sumBy(data, (item: IElectricityPieRow) => item.ecB)
  const sumGE = _.sumBy(data, (item: IElectricityPieRow) => item.generalEquipment)
  const sumEFan = _.sumBy(data, (item: IElectricityPieRow) => item.efan)

  responseObj.rows = []
  responseObj.rows.push({
    c: [{ v: 'Artificial Lights' }, { v: sumPAL } ]
  })
  responseObj.rows.push({
    c: [{ v: 'Cold Block' }, { v: sumECB } ]
  })
  responseObj.rows.push({
    c: [{ v: 'General Equipment' }, { v: sumGE } ]
  })
  responseObj.rows.push({
    c: [{ v: 'AHU' }, { v: sumEFan } ]
  })

  const options = {
    width: 600,
    height: 500,
    chartArea: 100,
    title: 'Electricity Requirement KWh/m2',
    legend: {
      position: 'bottom',
    },
    pieSliceTextStyle: {fontSize: 12, bold: true},
    sliceVisibilityThreshold: 0.0001,
  };
  
  return (
    <Styled.Container>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          height={500}
          width={600}
          data={responseObj}
          loader={<div>Loading Chart</div>}
          chartType="PieChart"
          options={options}
        />
      </Box>
    </Styled.Container>
  )
}