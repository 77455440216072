import { Http } from "../../Http"
import { KuboGraphModel } from "./types"

export const KuboGraphService = {
  Fetch: async (token: string): Promise<KuboGraphModel.GetGraphResponse> => {
    return await Http.Request<KuboGraphModel.GetGraphResponse>('GET', '/api/report/' + token)
  },

  FetchAll: async (): Promise<KuboGraphModel.GetGraphsResponse> => {
    return await Http.Request<KuboGraphModel.GetGraphsResponse>('GET', '/api/graph')
  },
}
