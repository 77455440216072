import { IClient, IClientList } from "../../interfaces/client"

export interface IClientState {
  client?: IClient
  list?: IClientList
}

export const CLIENT_CREATE = "CLIENT_CREATE"
export const CLIENT_FETCHALL = "CLIENT_FETCHALL"

interface IFetchClients {
  type: typeof CLIENT_FETCHALL;
  payload: IClientList;
}

export type ClientActionTypes = IFetchClients;
