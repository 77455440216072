import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { notificationReducer } from "./notification/reducers"
import { userReducer } from "./user/reducers"
import { clientReducer } from "./client/reducers"
import { scenarioReducer } from "./scenario/reducers"
import { cropReducer } from "./crop/reducers"
import { screenReducer } from "./screen/reducers"
import { graphReducer } from "./graph/reducers"
import { strategyReducer } from "./strategy/reducers"

export const rootReducer = combineReducers({
  notifications: notificationReducer,
  user: userReducer,
  clients: clientReducer,
  scenarios: scenarioReducer,
  crops: cropReducer,
  screens: screenReducer,
  graphs: graphReducer,
  strategies: strategyReducer,
})

export type AppState = ReturnType<typeof rootReducer>

export default function configureStore() {
  const middlewares = [thunkMiddleware]
  const middleWareEnhancer = applyMiddleware(...middlewares)

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  return store
}
