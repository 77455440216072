import { Http } from "../../Http"
import { KuboClientModel } from "./types"

export const KuboClientService = {
  FetchAll: async (): Promise<KuboClientModel.GetClientsResponse> => {
    return await Http.Request<KuboClientModel.GetClientsResponse>('GET', '/api/Client')
  },

  Create: async (name: string): Promise<KuboClientModel.CreateClientResponse> => {
    let data = {name}
    return await Http.Request<KuboClientModel.CreateClientResponse>('POST', '/api/Client', undefined, JSON.stringify(data))
  }
}
