import * as React from "react"
import CloseIcon from '@material-ui/icons/HighlightOff';

import * as Styled from "./styled"

interface IModalContainerProps {
  children: any
  onClose: () => void
}

const ModalContainer = ({ onClose, children, ...rest}: IModalContainerProps) => (
  <Styled.Container {...rest}>
    {onClose && (  
    <Styled.CloseIcon aria-label="close" onClick={onClose}>
      <CloseIcon />
    </Styled.CloseIcon>
    )}
    { children }
  </Styled.Container>
)

export default ModalContainer