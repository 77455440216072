import _ from "lodash"
import * as React from "react"
import { Field } from "formik"
import { Grid } from "@material-ui/core"
import { TextField } from "formik-material-ui"

import { DefaultPanelContainer, DefaultPanelHeader, DefaultPanelBody } from "../../DefaultPanel"

const ArtificialLightsValidationRules = {}

interface IGreenhouseArtificialLights {
  led: any
  hps: any
}

interface IArtificialLightsProps {
  artificialLights: IGreenhouseArtificialLights
  disabledForm: boolean
}

class ArtificialLights extends React.Component <IArtificialLightsProps> {
  render() {
    const { disabledForm, artificialLights: { led, hps } } = this.props

    return (
      <>
        <DefaultPanelContainer>
          <DefaultPanelHeader prefix="al-led" name="Led" />
          <DefaultPanelBody>
            <Grid container spacing={2}>
              {
                _.map(led, (value: any, name: string) => {
                  return (
                    <Grid item xs={3} key={name}>
                      <Field component={TextField} name={"greenhouse-artificialLights-led-" + name} label={name} variant="outlined" fullWidth defaultValue={value} disabled={disabledForm} />
                    </Grid>
                  )
                })
              }
            </Grid>
          </DefaultPanelBody>
        </DefaultPanelContainer>

        <DefaultPanelContainer>
          <DefaultPanelHeader prefix="al-hps" name="HPS" />
          <DefaultPanelBody>
            <Grid container spacing={2}>
              {
                _.map(hps, (value: any, name: string) => {
                  return (
                    <Grid item xs={3} key={name}>
                      <Field component={TextField} name={"greenhouse-artificialLights-hps-" + name} label={name} variant="outlined" fullWidth defaultValue={value} disabled={disabledForm} />
                    </Grid>
                  )
                })
              }
            </Grid>
          </DefaultPanelBody>
        </DefaultPanelContainer>
      </>
    )
  }
}

export { ArtificialLights, ArtificialLightsValidationRules }