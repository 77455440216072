export const monthColumns = [
  {type: 'number', label: 'Jan', id: '1'},
  {type: 'number', label: 'Feb', id: '2'},
  {type: 'number', label: 'Mar', id: '3'},
  {type: 'number', label: 'Apr', id: '4'},
  {type: 'number', label: 'May', id: '5'},
  {type: 'number', label: 'Jun', id: '6'},
  {type: 'number', label: 'Jul', id: '7'},
  {type: 'number', label: 'Aug', id: '8'},
  {type: 'number', label: 'Sep', id: '9'},
  {type: 'number', label: 'Oct', id: '10'},
  {type: 'number', label: 'Nov', id: '11'},
  {type: 'number', label: 'Dec', id: '12'},
]

// export function getLabelOfKey(key: string) {
//   switch (key) {
//     case 'powerFactor': 
//       return 'Power factor'
//     case 'roofSlope':
//       return 'Roof slope' 
//     default: 
//       return key
//   }
// }