import styled, { AnyStyledComponent } from "styled-components"
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core"

export const ExpansionPanelContainer: AnyStyledComponent = styled(ExpansionPanel)`
  ${({ theme }) => `
    box-shadow: none;
    width: 100%;

    &:before {
        height: 0;
        top: 0;
        display: none;
    }

    &:last-child {
        margin-bottom: 4em;
    }
  `}
`

export const ExpansionPanelHeader: AnyStyledComponent = styled(ExpansionPanelSummary)`
  ${({ theme }) => `
    border: 0
  `}
`

export const ExpansionPanelBody: AnyStyledComponent = styled(ExpansionPanelDetails)`
  ${({ theme }) => `
    padding: 2em;
  `}
`