import React from "react"
import { connect } from "react-redux"
import { UserActions } from "../../store/user/actions"
import { withRouter } from "react-router-dom"

interface IState {
  ready: boolean
}

interface IIsAuthenticatedProps {
  children: any
  reloadAuth: any
  loadUser: any
  history: any
  location: any
  match: any
}

interface IDispatchProps {
  reloadAuth:  () => void
  loadUser: () => void
}

type TCombinedType = IIsAuthenticatedProps & IDispatchProps

class IsAuthenticated extends React.Component<TCombinedType, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      ready: false,
    }
  }

  componentDidMount() {
    this.props.reloadAuth().then((res: boolean) => {
      if (res) {
        this.props.loadUser().then(() => {
          this.setState({
            ready: true,
          })
        }).catch((e: any) => {
          if (e.status === 401) {
            this.props.history.push('/login')
            window.location.reload()
          }
        })
      } else {
        this.setState({
          ready: true,
        })
      }
    })
  }

  render() {
    return this.state.ready && this.props.children
  }
}

const IsAuthenticatedWithRouter = withRouter(IsAuthenticated)

const dispatchMapsToProps: IDispatchProps = ({
  reloadAuth: () => UserActions.ReloadAuth(),
  loadUser: () => UserActions.Fetch()
})

export default connect(
  null,
  dispatchMapsToProps,
)(IsAuthenticatedWithRouter)
