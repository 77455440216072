import * as React from "react"
import { Container, Grid } from "@material-ui/core"

import { Field } from "formik"
import { TextField } from "formik-material-ui"

import * as Styled from "./styled"

interface IProps {
  disabledForm: boolean
}

const EditHeader = ({ disabledForm }: IProps) => (
  <Styled.Container>
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Field component={TextField} name="name" label="Scenario title" variant="outlined" fullWidth disabled={disabledForm} />
        </Grid>
        <Grid item xs={3}>
          <Field component={TextField} name="createdBy" label="Scenario creator" variant="outlined" fullWidth disabled />
        </Grid>
        <Grid item xs={6}>
          <Field component={TextField} name="notes" label="Notes" variant="outlined" fullWidth disabled={disabledForm} />
        </Grid>
      </Grid>
    </Container>
  </Styled.Container>
)

export default EditHeader
