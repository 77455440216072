import { Http } from "../../Http"
import { KuboCropModel } from "./types"

export const KuboCropService = {
  FetchAll: async (): Promise<KuboCropModel.GetCropsResponse> => {
    return await Http.Request<KuboCropModel.GetCropsResponse>('GET', '/api/Crop')
  },

  Fetch: async (id: number): Promise<KuboCropModel.GetCropResponse> => {
    return await Http.Request<KuboCropModel.GetCropResponse>('GET', '/api/Crop/' + id)
  }
}
