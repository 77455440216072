import { Dispatch } from "redux"
import { KuboStrategyService } from "../../services/api/Kubo/StrategyService"
import * as Types from "./types"

export const StrategyActions = {
  FetchAll: () => {
    return async (dispatch: Dispatch) => {
      const data = await KuboStrategyService.FetchAll()
      dispatch(StrategyActions.dispatchFetchAll(data))
    }
  },

  Fetch: (id: number) => {
    return async (dispatch: Dispatch) => {
      return await KuboStrategyService.Fetch(id)
    }
  },

  dispatchFetchAll: (data: any) => ({
    type: Types.STRATEGY_FETCHALL,
    payload: data
  }),
}