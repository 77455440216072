import styled from "styled-components"

export const Wrapper = styled.div`
  // preloading...
  background: url(/assets/img/print.jpg) no-repeat;
  height: 100vh;
  display: none;

  @media print {
    background: none;
    display: block;
    page-break-inside: avoid;
    page-break-after: always;
  }

  @page {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
`

export const FrontPage = styled.div`
  background: url(/assets/img/print.jpg) no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`