import styled, { AnyStyledComponent } from "styled-components"

export const Title: AnyStyledComponent = styled.h2`
  ${({ theme }) => `
    color: ${ theme.palette.primary.main };
    font-size: 18px;
    padding: 0;
    margin: 0 0 1em 0;
  `}
`

export const SubTitle: AnyStyledComponent = styled.p`
  ${({ theme }) => `
    padding: 0;
    margin: 0 0 1em 0;
  `}
`

export const FormContainer: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    .MuiButton-root,
    .MuiTextField-root {
      width: 100%;
      margin-top: 1em;
    }

    .MuiButton-root {
      padding: .5em;
      text-align: left;
    }

    .MuiInputBase-root {
      font-family: "Avenir Next Regular", Arial, sans-serif;
      font-size: 16px;
    }
    .MuiButton-label {
      display: block;
      color: ${ theme.palette.primary.main };
      font-family: "Avenir Next Medium", Arial, sans-serif;
      font-weight: 500;
      text-transform: none;
      font-size: 16px;
      text-align: center;
    }
  `}
`

export const ChoiceContainer: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    display: flex;
    background-color: #eee;
    padding: 4px;
    border-radius: 6px;
    margin-top: 1em;
    margin-bottom: 1em;
  `}
`

export const Choice: AnyStyledComponent = styled.span`
  ${({ theme }) => `
    transition: 0.3s;
    padding: 4px 6px;
    align-items: center;
    flex: 1 1 50%;
    text-align:center;
    border-radius: 6px;
    cursor: pointer;
    margin-left: 2px;
    margin-right: 0px;

    &:first-child {
      margin-left: 0px;
      margin-right: 2px;
    }

    &.active:hover,
    &.active {
      color: #fff;
      background-color: ${ theme.palette.primary.main };
    }

    &:hover {
      background-color: #ccc;
    }
  `}
`