import { IScreen, IScreenType } from "../../interfaces/screen";

export interface ScreenState {
  screen: IScreen | undefined
  list: IScreen[]
  screenTypes: IScreenType[]
}

export const SCREEN_FETCH = "SCREEN_FETCH"
export const SCREEN_FETCHTYPES = "SCREEN_FETCHTYPES"
export const SCREEN_FETCHBYTYPE = "SCREEN_FETCHBYTYPE"

// export const SCENARIO_CREATE = "SCENARIO_CREATE"
// export const SCENARIO_DELETE = "SCENARIO_DELETE"
// export const SCENARIO_DUPLICATE = "SCENARIO_DUPLICATE"

interface IScreenFetchAction {
  type: typeof SCREEN_FETCH
  payload: IScreen
}

interface IScreenFetchTypesAction {
  type: typeof SCREEN_FETCHTYPES
  payload: IScreen[]
}

interface IScreenFetchByTypeAction {
  type: typeof SCREEN_FETCHBYTYPE
  payload: IScreen[]
}

export type ScreenActionTypes = IScreenFetchAction
                                | IScreenFetchTypesAction
                                | IScreenFetchByTypeAction
