import { ICropList } from "../../interfaces/crop"

export interface ICropState {
  list?: ICropList
}

export const CROP_FETCHALL = "CROP_FETCHALL"

interface IFetchCrops {
  type: typeof CROP_FETCHALL;
  payload: ICropList;
}

export type CropActionTypes = IFetchCrops;
