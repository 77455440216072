import _ from "lodash"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { StructureActions } from "../../../../store/structure/actions"

import { Field } from "formik"
import {
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core"

import { TextField } from "formik-material-ui"

import { DefaultPanel, DefaultPanelContainer, DefaultPanelHeader, DefaultPanelBody } from "../../DefaultPanel"

interface IGreenhouseStructure {
  design: any
  heating: {
    railPipe: any
    upperPipe: any
    snowPipe: any
  }
  airHandlingUnit: any
  primaryEquipment: any
}

interface IStructureProps {
  structure: IGreenhouseStructure
  disabledForm: boolean
  setFieldValue: any
  loadDesignTypes: any
}

const Structure = ({structure, setFieldValue, disabledForm, loadDesignTypes}: IStructureProps) => {
  const [ designTypes, setDesignTypes ] = useState([])

  const handleChange = (e: any) => {
    setFieldValue("greenhouse-structure-design-type", e.target.value)
  }

  useEffect(() => {
    loadDesignTypes().then((res: any) => {
      setDesignTypes(res)
    })
  }, [loadDesignTypes])

  return (
    <>
      <DefaultPanelContainer>
        <DefaultPanelHeader prefix="greenhouse-structure-design" name="Design" />
        <DefaultPanelBody>
          <Grid container spacing={2}>
            {
              _.map(structure.design, (value: any, title: string) => {
                if (title === "id") {
                  return (
                    <Field type="hidden" component={TextField} name={"greenhouse-structure-design-" + title} key={"greenhouse-structure-design-" + title} disabled={disabledForm} />
                  )
                } else {
                  if (title === "type") {
                    return (
                      <Grid item xs={3} key={"greenhouse-structure-design-" + title}>
                        <Select labelId="selectDesignType" label="Type" fullWidth={true} defaultValue={structure.design.type} variant="outlined" onChange={(e: any) => handleChange(e)} name="greenhouse-structure-design-type" disabled={disabledForm}>
                          <MenuItem value={undefined}>Select a type</MenuItem>
                          {
                            _.map(designTypes, (item: any, key: number) => {
                              if (item.name === structure.design.type) {
                                return <MenuItem value={item.name} key={key} selected={true}>{item.name}</MenuItem>
                              } else {
                                return <MenuItem value={item.name} key={key}>{item.name}</MenuItem>
                              }
                            })
                          }
                        </Select>
                      </Grid>
                    )
                  } else {
                    return (
                      <Grid item xs={3} key={"greenhouse-structure-design-" + title}>
                        <Field component={TextField} name={"greenhouse-structure-design-" + title} label={title} variant="outlined" fullWidth disabled={disabledForm} />
                      </Grid>
                    )
                  }
                }
              })
            }
          </Grid>
        </DefaultPanelBody>
      </DefaultPanelContainer>

      <DefaultPanelContainer>
        <DefaultPanelHeader prefix="st1" name="Heating" />
        <DefaultPanelBody>
          <Grid container spacing={2}>
          {
            _.map(structure.heating, (fields: any, name: string) => {
              return (
                <Grid item xs={6} key={name}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <strong>{ name }</strong>
                    </Grid>
                    {
                      _.map(fields, (value: any, subTitle: string) => {
                        return (
                          <Grid item xs={3} key={name + "-" + subTitle}>
                            <Field component={TextField} name={"greenhouse-structure-heating-" + name + "-" + subTitle} label={subTitle} variant="outlined" fullWidth defaultValue={value} disabled={disabledForm} />
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Grid>
              )
            })
          }
          </Grid>
        </DefaultPanelBody>
      </DefaultPanelContainer>

      <DefaultPanel name="AHU" fields={structure.airHandlingUnit} prefix="greenhouse-structure-airHandlingUnit" disabledForm={disabledForm} />
      <DefaultPanel name="Primary Sources" fields={structure.primaryEquipment} prefix="greenhouse-structure-primaryEquipment" disabledForm={disabledForm} />
    </>
  )
}

export default connect(
  null,
  {
    loadDesignTypes: (type: string) => StructureActions.FetchAll(),
  }
)(Structure)
