import { Http } from "../../Http"
import { KuboScenarioModel } from "./types"

export const KuboScenarioService = {
  Fetch: async (id: number): Promise<KuboScenarioModel.GetScenarioResponse> => {
    return await Http.Request<KuboScenarioModel.GetScenarioResponse>('GET', '/api/Scenario/' + id)
  },

  FetchAll: async (): Promise<KuboScenarioModel.GetScenarioResponse> => {
    return await Http.Request<KuboScenarioModel.GetScenarioResponse>('GET', '/api/Scenario')
  },

  FetchLocations: async (): Promise<KuboScenarioModel.GetLocationsResponse> => {
    return await Http.Request<KuboScenarioModel.GetLocationsResponse>('GET', '/api/Location/')
  },

  FetchPlaceholders: async (id: number): Promise<KuboScenarioModel.GetPlaceholdersResponse> => {
    return await Http.Request<KuboScenarioModel.GetPlaceholdersResponse>('GET', '/api/report/placeholders/' + id)
  },

  FetchContent: async (id: number): Promise<KuboScenarioModel.GetContentResponse> => {
    return await Http.Request<KuboScenarioModel.GetContentResponse>('GET', '/api/report/content/' + id)
  },

  UpdateContent: async (data: any): Promise<KuboScenarioModel.UpdateContentResponse> => {
    return await Http.Request<KuboScenarioModel.UpdateContentResponse>('PUT', '/api/report/content/', undefined, JSON.stringify(data))
  },

  CreateClimateScenario: async (data: any): Promise<KuboScenarioModel.CreateScenarioResponse> => {
    return await Http.Request<KuboScenarioModel.CreateScenarioResponse>('POST', '/api/scenario/ClimateScenario', undefined, JSON.stringify(data))
  },

  CreateCalculationScenario: async (data: any): Promise<KuboScenarioModel.CreateScenarioResponse> => {
    return await Http.Request<KuboScenarioModel.CreateScenarioResponse>('POST', '/api/scenario/CalculationScenario', undefined, JSON.stringify(data))
  },

  UpdateScenario: async (data: any): Promise<KuboScenarioModel.UpdateScenarioResponse> => {
    return await Http.Request<KuboScenarioModel.UpdateScenarioResponse>('PUT', '/api/scenario/', undefined, JSON.stringify(data))
  },

  RunScenario: async (id: number): Promise<KuboScenarioModel.RunScenarioResponse> => {
    return await Http.Request<KuboScenarioModel.RunScenarioResponse>('GET', '/api/scenario/RunScenario/' + id, undefined)
  },

  DuplicateScenario: async (id: number): Promise<KuboScenarioModel.DuplicateScenarioResponse> => {
    return await Http.Request<KuboScenarioModel.DuplicateScenarioResponse>('POST', '/api/scenario/Duplicate', undefined, JSON.stringify({ id }))
  },

  DeleteScenario: async (id: number): Promise<KuboScenarioModel.DeleteScenarioResponse> => {
    return await Http.Request<KuboScenarioModel.DeleteScenarioResponse>('DELETE', '/api/scenario/' + id)
  },

  FetchScenarioReportRange: async (scenarioId: number): Promise<KuboScenarioModel.FetchScenarioReportRangeResponse> => {
    return await Http.Request<KuboScenarioModel.FetchScenarioReportRangeResponse>('GET', '/api/weatherdata/DateRangeForScenario', { scenarioId })
  },
}
