import styled, { AnyStyledComponent } from "styled-components"
import { Typography } from '@material-ui/core'

export const HeaderWrapper: AnyStyledComponent = styled.header`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
    color: #fff;
  `}
`

export const Logo: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    height: 90px;
    img {
      width: 75px;
    }
  `}
`

export const Meta: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: flex-start;
    height: 90px;
    justify-content: flex-end;
    line-height: 3em;
    font-size: 12px;

    .name {
      display: inline-flex;
      align-items: center;
      opacity: 70%;
      img {
        margin-right: 1em;
      }
    }

    .divider {
      opacity: 70%;
      padding: 0 10px;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-decoration: none;
      opacity: 70%;

      &:active,
      &:hover {
        opacity: 100%;
      }
    }
  `}
`

export const Title: AnyStyledComponent = styled(Typography)`
  line-height: 35px;
  margin: 0;
`

export const SubTitle: AnyStyledComponent = styled(Typography)`
  margin: 0;
`