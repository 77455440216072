import { Dispatch } from "redux"
import { KuboCropService } from "../../services/api/Kubo/CropService"
import * as Types from "./types"

export const CropActions = {
  FetchAll: () => {
    return async (dispatch: Dispatch) => {
      const data = await KuboCropService.FetchAll()
      dispatch(CropActions.dispatchFetchAll(data))
    }
  },

  Fetch: (id: number) => {
    return async (dispatch: Dispatch) => {
      return await KuboCropService.Fetch(id)
    }
  },

  dispatchFetchAll: (data: any) => ({
    type: Types.CROP_FETCHALL,
    payload: data
  }),
}