import styled, { AnyStyledComponent } from "styled-components"
import { TextField as MuiTextField } from "@material-ui/core"

export const CrossHair: AnyStyledComponent = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100;
  
  & > .line { 
    &:before, &:after {
      background: #808080;
      content: '';
      display: block;
      position: absolute;
    }
  }

  & > .line--x { 
    &:before, &:after {
      height: 1px; 
      top: 50%;
      width: 50%;
    }

    &:before {
      left: -18px;
    }
    
    &:after {
      right: -18px;
    }
  }

  & > .line--y { 
    &:before, &:after {
      height: 50%;
      left: 50%;
      width: 1px; 
    }

    &:before {
      bottom: -18px;
    }
    
    &:after {
      top: -18px;
    }
  }

  & > i {
    border: 2px solid #D0021B;
    border-radius: 100%;
    display: block;
    height: 36px;
    width: 36px;
  }
`


export const TextField: AnyStyledComponent = styled(MuiTextField)`
  .MuiFormLabel-root {
    z-index: 1;
    pointer-events: none;
    transform: translate(14px, 14px) scale(1);
  }
  .MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(.75);
  }
  input {
    padding: 12px 10px;
  }
`