import * as React from "react"
import { DefaultPanel } from "../../DefaultPanel"

const PricesValidationRules = {}

interface IPricesProps {
  prices: any
  disabledForm: boolean
}

class Prices extends React.Component <IPricesProps> {
  render() {
    const { disabledForm, prices } = this.props
    return (
      <DefaultPanel name={"Prices"} fields={prices} prefix="greenhouse-prices" expanded={true} disabledForm={disabledForm} />
    )
  }
}

export { Prices, PricesValidationRules }