import * as React from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { UserActions } from "../../store/user/actions";

import * as Yup from "yup";
import * as Styled from "./styled";

interface ILoginFormProps {
  authenticate: any,
  loadUser: any,
  history: any
}

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .max(255, 'too long')
    .required('Required'),
  password: Yup.string()
    .required('Required'),
})

class LoginForm extends React.Component<ILoginFormProps> {
  render() {
    return (
      <Styled.FormContainer>
        <Formik
          initialValues={{
            username: '',
            password: ''
          }}
          validationSchema={loginSchema}
          onSubmit={(values, {setSubmitting}) => {
            this.props.authenticate(values.username, values.password).then((res: boolean) => {
              if (true === res) {
                this.props.loadUser().then(() => {
                  this.props.history.push('/')
                })
              }
              setSubmitting(false)
            })
          }}>
            {({ errors, touched }) => (
              <Form>
                <Field component={TextField} name="username" label="Username" variant="outlined" type="text" />
                <Field component={TextField} name="password"  label="Password" variant="outlined" type="password" />
                <Button type="submit" variant="contained" color="secondary" disableElevation>
                  Login
                </Button>
              </Form>
            )}
        </Formik>
      </Styled.FormContainer>
    );
  }
}

export default connect(
  null,
  {
    authenticate: (username: string, password: string) => UserActions.Authenticate(username, password),
    loadUser: () => UserActions.Fetch()
  }
)(LoginForm);
