import React from 'react'
import _ from 'lodash'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import * as Styled from '../../styled'
import { IGraphItem } from '../../../../interfaces/graph'

interface IHeatEnergyPieRow {
  month: number
  qhBFinal: number
  qrPipeAirFinal: number
  qsPipeAirFinal: number
  quPipeAirFinal: number
  totalHeatAL: number
}

export const HeatEnergyPie = ({ resultData: data, description, name }: IGraphItem) => {

  let responseObj: any = {}
  responseObj.cols = [
    {type: 'string', label: 'Equipment'},
    {type: 'number', label: 'Consumption kWh/m2'},
  ]

  // calculate sums
  const sumQHB = _.sumBy(data, (item: IHeatEnergyPieRow) => item.qhBFinal)
  const sumQR = _.sumBy(data, (item: IHeatEnergyPieRow) => item.qrPipeAirFinal)
  const sumQS = _.sumBy(data, (item: IHeatEnergyPieRow) => item.qsPipeAirFinal)
  const sumQU = _.sumBy(data, (item: IHeatEnergyPieRow) => item.quPipeAirFinal)
  const sumTotal = _.sumBy(data, (item: IHeatEnergyPieRow) => item.totalHeatAL)

  responseObj.rows = []
  responseObj.rows.push({
    c: [{ v: 'AHU Heat Block' }, { v: sumQHB } ]
  })
  responseObj.rows.push({
    c: [{ v: 'Rail Pipe' }, { v: sumQR } ]
  })
  responseObj.rows.push({
    c: [{ v: 'Snow Pipe' }, { v: sumQS } ]
  })
  responseObj.rows.push({
    c: [{ v: 'Upper Pipe' }, { v: sumQU } ]
  })
  responseObj.rows.push({
    c: [{ v: 'Artificial Lights' }, { v: sumTotal } ]
  })

  const options = {
    width: 600,
    height: 500,
    chartArea: 100,
    title: 'Heat Energy Requirement MJ/m2',
    legend: {
        position: 'bottom',

    },
    pieSliceTextStyle: {fontSize: 12, bold: true},
    sliceVisibilityThreshold: 0.0001,
  };
  
  return (
    <Styled.Container>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          height={500}
          width={600}
          data={responseObj}
          loader={<div>Loading Chart</div>}
          chartType="PieChart"
          options={options}
        />
      </Box>
    </Styled.Container>
  )
}