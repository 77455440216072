import { HttpModel } from './types'

export const Http = {
  Request: async <A>(
    methodType: string,
    endpoint: string,
    queryParams?: HttpModel.IRequestQueryParams,
    payload?: any,
    headers?: HttpModel.IRequestHeaders,
    attempt: number = 1
  ): Promise<A> => {
    return new Promise((resolve, reject) => {
      let query = Http.filter(queryParams);

      let requestUrl = `${process.env.REACT_APP_API_URL}${endpoint}`
      if (undefined !== query) {
        requestUrl = `${requestUrl}?${query}`
      }

      if (undefined === headers) {
        headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        } as HttpModel.IRequestHeaders;
      }

      fetch(`${requestUrl}`, {
        method: `${methodType}`,
        cache: 'no-cache',
        redirect: 'follow',
        headers: headers,
        body: payload
      })
        .then(async (response: any) => {
          let success = response.status >= 200 && response.status < 300;
          let tokenHasExpired = response.status === 401

          if (success) {
            const content = await response.text()

            try {
              const json = content.length > 0 ? JSON.parse(content) : {};
              return resolve(json)
            } catch (e) {
              return resolve(content);
            }
          }

          let refreshToken = localStorage.getItem('refreshToken');

          if (tokenHasExpired && refreshToken && attempt <= 2) {
            let reAuthBody = Http.filter({
              grant_type: "refresh_token",
              refresh_token: refreshToken
            })

            let reAuthHeaders = {
              'Content-Type': 'application/x-www-form-urlencoded'
            }

            const data: any = await Http.Request<any>('POST', '/token', undefined, reAuthBody, reAuthHeaders, attempt++).then((data) => {
              localStorage.setItem('accessToken', data.access_token)
              localStorage.setItem('refreshToken', data.refresh_token)

              // Replace the specific auth header from the initial headers
              if (headers !== undefined) {
                headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
              }

              return Http.Request(methodType, endpoint, queryParams, payload, headers, attempt);
            }).catch((e) => {
              console.log(e)
            })

            if (data) {
              return resolve(data)
            }

            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
          }

          const errorContent = await response.text()
          const errorJSON = errorContent.length > 0 ? JSON.parse(errorContent) : {};

          return reject(errorJSON)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  },

  filter: (data?: any) => {
    if (data) {
      let params = new URLSearchParams();
      Object.keys(data).forEach(function (key: string) {
        params.append(key, data[key]);
      })
      return params
    }
  }
}
