import * as Types from "./types"

const initialState: Types.ICropState = {
  list: undefined
};

export function cropReducer(
  state = initialState,
  action: Types.CropActionTypes
): Types.ICropState {
  switch (action.type) {

    case Types.CROP_FETCHALL:
      return {
        list: action.payload
      }

    default:
      return state;
  }
}
