import _ from "lodash"
import React, { useState, useEffect } from "react"
import * as Styled from './styled'

import { connect } from "react-redux"
import { AppState } from "../../../store"
import { flatten, unflatten } from "flat"

import { StrategyActions } from "../../../store/strategy/actions"
import { IScenario } from "../../../interfaces/scenario"
import { FastField as Field } from "formik"
import { TextField } from "formik-material-ui"
import { DefaultPanelContainer, DefaultPanelHeader, DefaultPanelBody } from "../DefaultPanel"

import {
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Button,
} from "@material-ui/core"

interface IStrategyTabProps {
  scenario: IScenario
  strategies: any
  fetchStrategies: any
  fetchStrategyValues: any
  values: any
  setValues: any
  setFieldValue: any
  handleSubmit: any
  disabledForm: boolean
}

const StrategyTab = ({
  scenario, 
  strategies, 
  fetchStrategies, 
  fetchStrategyValues, 
  values, 
  setValues, 
  setFieldValue, 
  handleSubmit,
  disabledForm,
}: IStrategyTabProps) => {
  const prefix = "strategy";
  const [strategyValues, setStrategyValues] = useState(scenario.strategy)
  const [strategyLoading, setStrategyLoading] = useState(false)

  useEffect(() => {
    fetchStrategies()
  }, [fetchStrategies])

  const translateDisplayName = (name: string = ""):string => {
    switch (name) {
      case '-artificialLight': return 'Artificial Light'
      case '-screen': return 'Screen'
      case '-climate': return 'Climate'
      case '-hourlyTargets': return 'Hourly Targets'
      default: return name
    }
  }

  const displayFields = (value: any, title: string = "", key = "", depth: number) => {
    return (
      <React.Fragment key={prefix + title + "-" + key}>
        {
          (typeof value === "object" && value !== null) ?
            renderFieldContainer(value, title + '-' + key, depth+1)
          :
            <Grid item xs={3}>
              <Field component={TextField} name={prefix + title + '-' + key} label={key} variant="outlined" fullWidth disabled={disabledForm} />
            </Grid>
        }
      </React.Fragment>
    )
  }

  const handleChange = (e: any) => {
    const id = e.target.value
    setStrategyValues(undefined)
    setStrategyLoading(true)

    // unflatten, set new form fields re-set
    const formValues: any = unflatten(values, { delimiter: "-" })
    formValues.strategy = {}

    fetchStrategyValues(id).then((res: any) => {
      // update current state object for field loop
      setStrategyValues(res)
      setStrategyLoading(false)

      // update formik values with new retrieved items
      formValues.strategy = res;
      const flatFormValues = flatten(formValues, { delimiter: "-" })
      setValues(flatFormValues);
    })
  }

  const renderFieldContainer = (data: any, title: string = "", depth: number = 0) => {
    let ret = (
      <>
        { (depth %2 !== 0) ?
          <React.Fragment key={"strategy-container-" + prefix + title + depth}>
            <DefaultPanelContainer>
              <DefaultPanelHeader prefix={prefix + title} name={title ? translateDisplayName(title) : data.name} />
              <DefaultPanelBody>
                <Grid container spacing={2}>
                  {
                    _.map(data, (value: any, key: string) => {
                      return displayFields(value, title, key, depth)
                    })
                  }
                </Grid>
              </DefaultPanelBody>
            </DefaultPanelContainer>
          </React.Fragment>
        : 
          <Box style={{ width: '100%'}} py={2} key={"strategy-container-" + prefix + title + depth}>
            <Grid container spacing={2}>
              {title.indexOf('-screen-') > -1 && (
                <Styled.ScreenTitle variant="h3" color="textPrimary">{title.replace('-screen-', '')}</Styled.ScreenTitle>
              )}
              {title.indexOf('-climate-') > -1 && (
                <Styled.ScreenTitle variant="h3" color="textPrimary">{title.replace('-climate-', '')}</Styled.ScreenTitle>
              )}
              {
                _.map(data, (value: any, key: string) => {
                  return displayFields(value, title, key, depth)
                })
              }
            </Grid>
          </Box>
        }
      </>
    )
    return ret
  }

  return (
    <Box pt={4} p={4}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
        { strategies ?
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="selectStrategy">Select a strategy</InputLabel>
            <Select labelId="selectStrategy" label="Select a strategy" onChange={handleChange} disabled={disabledForm}>
              {
                _.map(strategies, (item: any, key: number) => {
                  if (item.name !== (scenario.strategy?.name)) {
                    return <MenuItem value={item.id} key={"strategy-dropdown-" + key}>{item.name}</MenuItem>
                  } else {
                    if (item)
                      return <MenuItem value={item.id} key={"strategy-dropdown-" + key} selected>{item.name}</MenuItem>
                  }
                })
              }
            </Select>
          </FormControl>
          : "Loading..."}
        </Grid>
      </Grid>

      <Grid container spacing={0}>
        { strategyValues ?
          <Box mt={2}>
            { renderFieldContainer(strategyValues) }

            { !disabledForm  && (
              <Grid container>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <Button variant="contained" onClick={(e: any)=>{
                    setFieldValue('saveAndRun',true)
                    handleSubmit(e)
                  }}
                  color="secondary" fullWidth>Save and run</Button>
                </Grid>
              </Grid>
            )}
          </Box>
        :
          <Box p={2} pt={2}>{ strategyLoading ? 'Loading...' : '' }</Box>
        }
      </Grid>
    </Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  strategies: state.strategies.list
});

export default connect(
  mapStateToProps,
  {
    fetchStrategies: () => StrategyActions.FetchAll(),
    fetchStrategyValues: (id: number) => StrategyActions.Fetch(id)
  }
)(StrategyTab)