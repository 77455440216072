import { IContent, ILocation, IScenario, IPreviousLocation } from "../../interfaces/scenario";

export interface ScenarioState {
  scenario: IScenario
  list: IScenario[]
  locations: IPreviousLocation[]
  content: IContent
  activeTab: string
}

export const SCENARIO_FETCHALL = "SCENARIO_FETCHALL"
export const SCENARIO_FETCH = "SCENARIO_FETCH"
export const SCENARIO_FETCH_LOCATIONS = "SCENARIO_FETCH_LOCATIONS"

export const SCENARIO_CREATE = "SCENARIO_CREATE"
export const SCENARIO_DELETE = "SCENARIO_DELETE"
export const SCENARIO_DUPLICATE = "SCENARIO_DUPLICATE"

export const SCENARIO_SET_LOCATION = "SCENARIO_SET_LOCATION"
export const SCENARIO_FETCH_CONTENT = "SCENARIO_FETCH_CONTENT"
export const SCENARIO_ACTIVE_TAB = "SCENARIO_ACTIVE_TAB"

interface IScenarioFetchAction {
  type: typeof SCENARIO_FETCH
  payload: IScenario
}

interface IScenarioFetchAllAction {
  type: typeof SCENARIO_FETCHALL
  payload: IScenario[]
}

interface IScenarioSetLocationAction {
  type: typeof SCENARIO_SET_LOCATION
  payload: ILocation
}

interface IScenarioFetchLocationsAction {
  type: typeof SCENARIO_FETCH_LOCATIONS
  payload: IPreviousLocation[]
}

interface IScenarioFetchContentAction {
  type: typeof SCENARIO_FETCH_CONTENT
  payload: IContent
}

interface IScenarioActiveTabAction {
  type: typeof SCENARIO_ACTIVE_TAB
  payload: string
}

export type ScenarioActionTypes = IScenarioFetchAction
                                  | IScenarioFetchAllAction
                                  | IScenarioSetLocationAction
                                  | IScenarioFetchLocationsAction
                                  | IScenarioFetchContentAction
                                  | IScenarioActiveTabAction
