import * as React from "react"
import { DefaultPanel } from "../../DefaultPanel"

const ClimateValidationRules = {}

interface IClimateProps {
  climateProperty: any
  disabledForm: boolean
}

class Climate extends React.Component <IClimateProps> {
  render() {
    const { climateProperty, disabledForm } = this.props
    return (
      <DefaultPanel name="Climate Property" fields={ climateProperty } prefix="greenhouse-climateProperties" expanded={true} disabledForm={disabledForm} />
    )
  }
}

export { Climate, ClimateValidationRules }