import React from 'react'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import * as Styled from '../../styled'
import { IGraphItem } from '../../../../interfaces/graph'

interface IDayLengthAndRadiationRow {
  dayHours: number
  month: number
  nightHours: number
  radiation: number
}

export const DayLengthAndRadiation = ({ resultData: data, description, name, pageBreakAfter }: IGraphItem) => {
  if (!data) return null

  let responseObj: any = {};
  responseObj.cols = [
    { id: 'month', label: 'Month', type: 'date' },
    { id: 'daylength', label: 'Daylength', type: 'number' },
    { id: 'tooltip1', label: null, role: 'tooltip', 'p': { 'html': true } },
    { id: 'nigthlength', label: 'Nightlength', type: 'number' },
    { id: 'tooltip2', label: null, role: 'tooltip', 'p': { 'html': true } },
    { id: 'radiation', label: 'Radiation', type: 'number' },
    { id: 'tooltip3', label: null, role: 'tooltip', 'p': { 'html': true } },
    { id: 'annotationRad', label: 'annotationRad', type: 'number', role: 'annotation' },
  ];
  responseObj.rows = data.map((obj: IDayLengthAndRadiationRow) => ({
    c: [
      {v: new Date('2000/' + obj.month + '/01')},
      {v: obj.dayHours},
      {v: 'Daylength: ' + (obj.dayHours ? obj.dayHours.toFixed(0) : 0) },
      {v: obj.nightHours},
      {v: 'Nightlength: ' + (obj.nightHours ? obj.nightHours.toFixed(0) : 0) },
      {v: obj.radiation ? obj.radiation.toFixed(0) : 0},
      {v: 'Radiation: ' + (obj.radiation ? obj.radiation.toFixed(0) : 0) },
      {v: obj.radiation ? obj.radiation.toFixed(0) : 0},
    ],
  }));

  const options = {
    height: 400,
    width: 900,
    seriesType: 'area',
    isStacked: true,
    hAxis: {
        title: 'Month',
        titleTextStyle: {color: '#333'},
        format: 'MMM',
        gridlines: {count: 12},
    },
    vAxes: {
        0: {
            title: 'Hours [h]',
            // maxValue: 31,
            viewWindow: {
                min: 0,
                max: 24,
            },
            ticks: [0, 4, 8, 12, 16, 20, 24], // display labels
        },
        1: {
            title: 'Radiation Sum [J/cm2/day] ',
            maxValue: 4000,
            gridlines: {
                count: 0,
            },
        },
    },
    series: {
        2: {
            type: 'line',
            targetAxisIndex: 1,
            pointShape: 'circle',
            pointSize: 5,
            annotations: {
                textStyle: {fontSize: 16, color: 'black'},
                stem: {color: 'transparent'},
            },
        },
    },
};
  
  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          data={responseObj}
          loader={<div>Loading Chart</div>}
          width={900}
          height={400}
          chartType="ComboChart"
          options={options}
        />
      </Box>
    </Styled.Container>
  )
}