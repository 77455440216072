import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#76B0E0',
      main: '#003C68',
    },
    secondary: {
      main: '#FFCC01',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#F2F2F2',
    },
    text: {
      primary: '#262626',
      secondary: '#76B0E0',
    },
  },
  spacing: 12,
  typography: {
    fontFamily: "'AvenirNextLTW01-Regular', 'Arial', sans-serif",
    fontSize: 16,
    h1: {
      fontFamily: "'Avenir Next LT W01 Bold', sans-serif",
      fontSize: 26,
      fontWeight: 500,
      lineHeight: '45px',
      letterSpacing: '0.19px',
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: "'Avenir Next LT W01 Demi', sans-serif",
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
    },
    h3: {
      fontFamily: "'Avenir Next LT W01 Demi', sans-serif",
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '24px',
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      lineHeight: '20px',
    },
    button: {
      fontFamily: "'AvenirNextLTW01-Medium', sans-serif",
      fontSize: 16,
      fontWeight: 500,
      textTransform: 'initial',
    },
    caption: {
      fontFamily: "'Avenir Next LT W01 Demi', sans-serif",
    }
  },
  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none',
        padding: '8px 16px 9px 16px',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        }
      },
    },
    MuiInputBase: {
      root: { 
        backgroundColor: '#ffffff',
        '&.Mui-disabled': {
          backgroundColor: 'transparent'
        }
      },
      input: {
        fontFamily: "'AvenirNextLTW01-Regular', 'Arial', sans-serif",
        '&.Mui-disabled': {
          color: '#333333',
        }
      },
    },
    MuiListItemText: {
      secondary: {
        color: '#807F80',
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '1em',
      }
    },
    MuiExpansionPanel: {
      root: {
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: 48,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 0,
        '&.Mui-expanded': {
          minHeight: 48,
        }
      },
      content: {
        '&.Mui-expanded': {
          marginBottom: 12,
          marginTop: 12,
        },
      }
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
      },
    },
  },
});

/*
font-family:'AvenirNextLTW01-Regular';
font-family:'AvenirNextLTW01-Medium';
font-family:'Avenir Next LT W01 Demi';
font-family:'Avenir Next LT W01 Bold';
*/

export default theme;
