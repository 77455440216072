import React, { Fragment } from "react"
import { format } from "date-fns";
import { Divider, List, ListItemText, Typography } from '@material-ui/core'

import { IPreviousLocation } from '../../../interfaces/scenario'
import * as Styled from "./styled"

interface IPreviousLocationsProps {
  items: IPreviousLocation[]
  onClick: (coordinates: any, searchValue: string) => void
  setFormFieldValue: any
}

const PreviousLocations = ({ items, onClick, setFormFieldValue }: IPreviousLocationsProps) => {

  if (!items || items.length === 0) return null

  const handleClick = (item: IPreviousLocation) => {
    const coordinates = { lat: item.latitude, lng: item.longitude }
    setFormFieldValue(coordinates, item.id)
    onClick(coordinates, `${item.city}, ${item.country}`)
  }

  return (
    <Styled.Wrapper>
      <Typography color="primary" variant="h2">Previous locations</Typography>
      <Styled.Scroller>
        <List>
          {items && items.map((item: IPreviousLocation, index: number) => (
            <Fragment key={index}>
              {index > 0 && <Divider />}
              <Styled.ListItem
                button
                onClick={() => handleClick(item)}>
                <ListItemText
                  primary={
                    <>
                      <Typography component="span" variant="body2">{`${item.city} / ${item.country}  - `}</Typography>
                      <Styled.LatLong component="span" variant="body2">{`${item.latitude}, ${item.longitude}`}</Styled.LatLong>
                    </>
                  }
                  secondary={`Latest collection: ${format(Date.parse(item.latestCollectionDate), 'd MMMM yyyy HH:mm')}`}
                />
              </Styled.ListItem>
            </Fragment>
          ))}
        </List>
      </Styled.Scroller>
    </Styled.Wrapper>
  )
}

export default PreviousLocations