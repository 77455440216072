import styled, { AnyStyledComponent } from "styled-components"
import { TableCell as MuiTableCell } from '@material-ui/core'
import { TableRow as MuiTableRow } from '@material-ui/core'

export const Container: AnyStyledComponent = styled.div`
  ${({ before }: any) => `
    @media print {
      ${before === 'always' ? 'page-break-before: always;' : ''}
      page-break-inside: avoid;
      min-width: 1000px;
      padding-left: 40px;
      padding-top: 40px;
      padding-right: 40px;
    }
  `}
`

export const TableContainer: AnyStyledComponent = styled.div`
  border-top: 1px solid #91acdc;
  border-left: 1px solid #91acdc;
`

export const Th: AnyStyledComponent = styled(MuiTableCell)`
  ${({ bgcolor, vertical, width }: any) => `
    background-color: ${bgcolor || 'transparent'};
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-size: 11px;
    padding: ${vertical ? '5px 0' : '5px 2px'};
    text-align: center;
    width: ${vertical ? '4%' : 'auto'};

    &:last-child {
      border-right: 1px solid #91acdc;
    }

    ${vertical === 'true' ? `
      height: 180px;
      white-space: nowrap;
      > div {
        display: inline-block;
        font-size: 14px;
        text-align: left;
        transform-origin: left;
        transform: translate(${width === '2x' ? '20px' : '10px'}, 80px) rotate(270deg);
        width: 20px;
      }
    ` : ``}
    
    ${width === '2x' ? `
      width: 8%;
      > div {
        align-items: center;
        display: flex;
        flex-direction: row;
        width: 40px;
      }
    ` : ``}

  `}
`
export const Td: AnyStyledComponent = styled(MuiTableCell)`
  ${({ perc, percbg, vertical, theme }: any) => `
    background-color: #ffffff;
    border-bottom: 1px solid #91acdc;
    border-right: 1px solid #91acdc;
    font-size: 12px;
    padding: 5px 2px;
    position: relative;
    text-align: ${perc ? 'right' : 'center'};

    span {
      color: #000;
      position: relative;
    }

    ${perc ? `
      &:before {
        background-color: ${percbg};
        content: '';
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        height: 100%;
        width: ${perc}%;
      }
    ` : ``}
  `}
`

export const TableRow: AnyStyledComponent = styled(MuiTableRow)`
  ${({ total, theme }: any) => `
    &:nth-child(odd) td {
      background-color: ${total === 'true' ? '#ffffff' : '#d9e1f2'};
    }

    td {
      background-color: ${total === 'true' ? '#ffffff' : 'transparent'};
      font-family: ${total === 'true' ? theme.typography.h2.fontFamily : 'inherit'};
      font-weight: ${total === 'true' ? 'bold': 'normal'};
    }
  `}
`