import React from 'react'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import { monthColumns } from '../../../../helpers'
import * as Styled from '../../styled'
import { IGraphItem } from '../../../../interfaces/graph'

interface IPrecepitationLevelsRow {
  monthNo: number
  precepitation: number
  days: number
}

export const PrecepitationLevels = ({ resultData: data, description, name, pageBreakAfter }: IGraphItem) => {

  let responseObj: any = {};
  responseObj.cols = [
    {id: 'month', label: 'Month', type: 'date'},
    {id: 'precipitation', label: 'Precipitation [mm]', type: 'number'},
    { id: 'tooltip1', label: null, role: 'tooltip', 'p': { 'html': true } },
    {id: 'barStyle', type: 'string', role: 'style'},
    {id: 'days', label: 'Days [d]', type: 'number'},
    { id: 'tooltip2', label: null, role: 'tooltip', 'p': { 'html': true } },
    {id: 'pointStyle', type: 'string', role: 'style'},
  ];

  responseObj.rows = data.map((obj: IPrecepitationLevelsRow) => ({
    c: [
      {v: new Date('2000/' + obj.monthNo + '/01')},
      {v: obj.precepitation},
      {v: 'Precipitation: ' + obj.precepitation},
      {v: 'bar { fill-color: #99ccff; fill-opacity: 0.2; stroke-color: #0099cc; stroke-width: 4}'},
      {v: obj.days},
      {v: 'Days: ' + obj.days},
      {v: 'point { visible: true; size: 7; shape-type: square; fill-color: red; stroke-color: #3333cc; stroke-width: 2}'},
    ],
  }));

  const options = {
    //       hAxis: {title: 'Month',  titleTextStyle: {color: '#333'}},
    //       chartArea: { width: '70%', height: '70%'},
    width: 900,
    height: 400,
    legend: {position: 'top'},
    seriesType: 'bars',
    //       color: 'black',
    isStacked: true,
    series: {
        0: {color: '#0099cc'},
        1: {
            type: 'line',
            targetAxisIndex: 1,
            color: '#3333cc',
            lineWidth: 4,
        },
    },
    hAxis: {
        title: 'Month',
        format: 'MMM',
        gridlines: {count: 12},
    },
    vAxes: {
        0: {
            title: 'Precipitation [mm]',
            titleTextStyle: {
                fonstSize: 10,
                bold: true,
            },
            //                 maxValue: 24,
        },
        1: {
            title: 'Number of Days',
            titleTextStyle: {
                fonstSize: 10,
                bold: true,
            },
            //                 maxValue: 4000
        },
    },
  };

  let dataTable: any = {}
  dataTable.cols = [
    { type: 'string', label: 'Source', id: '0' },
    ...monthColumns,
  ];

  dataTable.rows = []

  dataTable.rows.push({
    c: [
      {v: 'Precipitation [mm]'},
      ...data.map((month: IPrecepitationLevelsRow) => ({ v: month.precepitation })),
    ]
  })

  dataTable.rows.push({
    c: [
      {v: 'Days [d]', p: { background: 'red'}},
      ...data.map((month: IPrecepitationLevelsRow) => ({ v: month.days })),
    ]
  })
  
  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          data={responseObj}
          loader={<div>Loading Chart</div>}
          width={900}
          height={400}
          chartType="ColumnChart"
          options={options}
        />
      </Box>
      <Box pb={2}>
        <Chart
          data={dataTable}
          loader={<div>Loading Chart</div>}
          width={900}
          height="auto"
          chartType="Table"
          options={{showRowNumber: false, width: 900}}
        />
      </Box>
    </Styled.Container>
  )
}