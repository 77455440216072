import * as React from "react"
import { Container, Grid, Modal, Button, Box } from "@material-ui/core"

import { NotificationState } from "../store/notification/types"

import Header from "../components/Header"
import Hero from "../components/Hero"
import ModalContainer from "../components/ModalContainer"
import ScenariosTable from "../components/ScenariosTable"
import ScenarioCreate from "../components/ScenarioCreate"

interface IScenariosProps {
  notification: NotificationState
  history: any
}

class Scenarios extends React.Component<IScenariosProps, { showModal: boolean }> {
  constructor(props: any) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  displayModal(display: boolean) {
    this.setState({
      showModal: display
    })
  }

  render() {
    const { history } = this.props
    return (
      <Box mb={6}>
        <Header history={history} />
        <Hero title="Scenarios">
          <Grid container alignItems="center">
            <Grid item xs={6} className="cta"></Grid>
            <Grid item xs={6} className="cta">
              <Button type="button" variant="contained" color="secondary" disableElevation fullWidth onClick={() => this.displayModal(true)}>Create scenario</Button>
            </Grid>
          </Grid>
        </Hero>

        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12}>
              <ScenariosTable />
            </Grid>
          </Grid>
        </Container>

        <Modal
          open={this.state.showModal}
          onClose={this.displayModal.bind(this, false)}
          >
          <ModalContainer onClose={this.displayModal.bind(this, false)}>
            <ScenarioCreate history={this.props.history} />
          </ModalContainer>
        </Modal>
      </Box>
    )
  }
}

export default Scenarios
