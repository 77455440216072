import React from "react"
import { IUser } from "../../interfaces/user"
import { connect } from "react-redux"
import { AppState } from "../../store"
import { Route, Redirect } from "react-router-dom"

interface IPrivateRouteProps {
  user: IUser,
  path: any,
  component: any,
  exact?: any
}

class PrivateRoute extends React.Component<IPrivateRouteProps> {
  render() {
    const { component: Component, ...rest } = this.props

    if (!this.props.user || this.props.user.isLoggedIn !== true) {
      return <Redirect to="/login" />
    }

    return (
      <Route
        {...rest}
        render={props => (
          this.props.user.isLoggedIn ? <Component {...props} /> : null
        )}
      />
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.user.user
});

export default connect(
  mapStateToProps
)(PrivateRoute);
