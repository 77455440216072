import * as React from "react"
import * as Yup from "yup"
import * as Styled from "./styled"

import { connect } from "react-redux"
import { Formik, Form, Field } from "formik"
import { Button } from "@material-ui/core"
import { TextField } from "formik-material-ui"

import { AppState } from "../../store"
import { ScenarioActions } from "../../store/scenario/actions"
import { ClientActions } from "../../store/client/actions"

import FormikAutocomplete from "../FormikAutocomplete"
import { IUser } from "../../interfaces/user"

interface IScenarioFormProps {
  history: any
}

interface IStateProps {
  clients: any
  user: IUser
}

interface IDispatchProps {
  createScenario: any
  createClient: any
}

type TCombinedProps = IScenarioFormProps & IStateProps & IDispatchProps

const scenarioSchema = Yup.object().shape({
  client: Yup.string()
    .nullable()
    .required('Please select or add a client'),
  name: Yup.string()
    .max(50)
    .required('Please fill out the scenario name')
})

class ScenarioCreate extends React.Component<TCombinedProps, { scenarioType: 'climate' | 'calculation' }> {
  constructor(props: any) {
    super(props)
    this.state = {
      scenarioType: 'climate'
    }
  }

  setScenarioType(type: 'climate' | 'calculation') {
    this.setState({
      scenarioType: type
    })
  }

  handleSubmission(values: any) {
    const { scenarioType } = this.state

    let data = {
      name: values.name,
      clientId: values.client.id
    }

    if (!values.client.id) {
      return this.props.createClient(values.client.inputValue).then((res: any) => {
        data.clientId = res.id
        return this.props.createScenario(scenarioType, data)
      })
    }

    return this.props.createScenario(scenarioType, data)
  }

  render() {
    const { scenarioType } = this.state
    const { clients, user } =  this.props

    return (
      <>
        <Styled.Title>Create scenario</Styled.Title>
        <Styled.SubTitle>To create a new scenario, please fill out the title of the scenario.</Styled.SubTitle>
        <Styled.FormContainer>
          <Formik
            initialValues={{
              client: '',
              name: ''
            }}
            validationSchema={scenarioSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              this.handleSubmission(values)
                .then((res: any) => {
                  this.props.history.push('/scenario/' + res.createdScenarioId)
                })
                .catch((e: any) => {
                  setSubmitting(false)
                  setFieldError('name', 'Name is not unique')
                })
            }}>
              {({ errors, touched }) => (
                <Form>
                  <Field name="client" component={FormikAutocomplete}
                    freeSolo
                    options={clients.list}
                    textFieldProps={{ label: 'Add or choose a client', fullWidth: true, margin: 'normal', variant: 'outlined' }}
                    getOptionLabel={(option: any) => {
                      if (option && typeof option === 'string') {
                        return option
                      }
                      if (option && option.inputValue) {
                        return option.inputValue
                      }
                      // Regular option
                      return option.name ? option.name : ''
                    }}
                    renderOption={(option:any) => {
                      if (option.inputValue) {
                        return option.title
                      }
                      return option.name
                    }}
                  />

                  <Styled.ChoiceContainer>
                    <Styled.Choice
                      className={ scenarioType === "climate" ? 'active' : '' }
                      onClick={this.setScenarioType.bind(this, 'climate')}>
                        Weather profile
                    </Styled.Choice>
                    { user.permissions.canCreateGreenhouseCalculationScenario && (
                      <Styled.Choice
                        className={ scenarioType === "calculation" ? 'active' : '' }
                        onClick={this.setScenarioType.bind(this, 'calculation')}>
                          Greenhouse calculation
                      </Styled.Choice>
                    )}
                  </Styled.ChoiceContainer>

                  <Field component={TextField} name="name" label="Name of the scenario" variant="outlined" type="text" />

                  <Button type="submit" variant="contained" color="secondary" disableElevation>
                    Save and create
                  </Button>
                </Form>
              )}
          </Formik>
        </Styled.FormContainer>
      </>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  clients: state.clients,
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  {
    createScenario: (type: "climate" | "calculation", data: any) => ScenarioActions.Create(type, data),
    createClient: (title: string) => ClientActions.Create(title)
  }
)(ScenarioCreate)