import React, { useEffect, MouseEvent } from "react"
import { connect } from "react-redux"
import { useConfirm } from 'material-ui-confirm';
import { format } from "date-fns";

import { AppState } from "../../store"
import { ScreenActions } from "../../store/screen/actions"
import { IScreen } from '../../interfaces/screen'

import * as Styled from "./styled"

import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Box,
  Typography,
} from "@material-ui/core"
import { Link } from '../Link'
import { useNotify } from "../../Hooks"

interface IScreensTableProps {
  screens: IScreen[]
  duplicateScreen: (id: number) => Promise<any>
  deleteScreen: (id: number) => Promise<any>
  loadScreenByType: (type: string) => void
  screenType: any
}

const ScreensTable = ({
  screens, 
  loadScreenByType, 
  deleteScreen, 
  duplicateScreen,
  screenType
}: IScreensTableProps) => {
  const confirm = useConfirm()
  const notify = useNotify()

  useEffect(() => {
    loadScreenByType(screenType)
  }, [loadScreenByType, screenType])

  const handleDuplicate = (e: MouseEvent<HTMLAnchorElement>, id: number) => {
    e.preventDefault()
    duplicateScreen(id).then(() => {
      loadScreenByType(screenType)

      notify('The screen has been duplicated', 'success')
    })
  }

  const handleDelete = (e: MouseEvent<HTMLAnchorElement>, id: number) => {
    e.preventDefault()
    confirm({ 
      confirmationText: 'Yes',
      cancellationText: 'No',
      title: 'Are you sure you want to delete this screen?'
    })
      .then(() => { 
        deleteScreen(id).then(() => {
          loadScreenByType(screenType)
          
          notify('The screen has been deleted', 'success')
        })
    })
  }

  return (
    <Box mt={4}>
      <TableContainer>
        <Table>
          <TableHead>
            <Styled.ThRow>
              <Styled.Th>Name</Styled.Th>
              <Styled.Th>Create</Styled.Th>
              <Styled.Th>Note</Styled.Th>
              <Styled.Th align="right">Actions</Styled.Th>
            </Styled.ThRow>
          </TableHead>
          <TableBody>
          {screens && screens.map((item: any, key: number) =>
            <Styled.TdRow key={key}>
              <Styled.Td>
                <Typography variant="caption">{item.name}</Typography>
              </Styled.Td>
              <Styled.Td>{format(Date.parse(item.creationDate), 'd MMMM yyyy HH:mm')}</Styled.Td>
              <Styled.Td>{item.note}</Styled.Td>
              <Styled.Td align="right">
                <Link to={"/screen/" + item.id }>
                  <img src="/assets/img/icon-edit.svg" width="25" height="25" className="td__icon" alt="Edit screen" />
                </Link>

                <a href="#duplicate-screen" onClick={(e: any) => handleDuplicate(e, item.id)}>
                  <img src="/assets/img/icon-copy.svg" width="25" height="25" className="td__icon" alt="Duplicate screen" />
                </a>

                <a href="#delete-screen" onClick={(e: any) => handleDelete(e, item.id)}>
                  <img src="/assets/img/icon-delete.svg" width="25" height="25" className="td__icon" alt="Delete screen" />
                </a>
              </Styled.Td>
            </Styled.TdRow>
          )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  screens: state.screens.list,
});

export default connect(
  mapStateToProps,
  {
    deleteScreen: (id: number) => ScreenActions.Delete(id),
    duplicateScreen: (id: number) => ScreenActions.Duplicate(id),
    loadScreenByType: (type: string) => ScreenActions.FetchByType(type),
  }
)(ScreensTable)
