import * as React from "react"
import { Link } from '../Link'
import { Container, Grid } from "@material-ui/core"

import * as Styled from "./styled"
import { IUser } from "../../interfaces/user"

interface INavigationProps {
  user: IUser
  history: any
}

class Navigation extends React.Component<INavigationProps> {

  constructor(props: any) {
    super(props)

    this.showBackdrop = this.showBackdrop.bind(this);
    this.hideBackdrop = this.hideBackdrop.bind(this);
  }

  showBackdrop(e: any) {
    let el = document.getElementById("js-toggle")
    if (el) el.classList.add("show")
  }

  hideBackdrop(e: any) {
    const el = document.getElementById("js-toggle");
    if (el) el.classList.remove("show")
  }

  navItemIsActive(pathname: string, exact?: boolean) {
    const currentPathname = this.props.history.location.pathname
    if (exact) {
      return currentPathname === pathname
    } else if (currentPathname.startsWith(pathname)) {
      return true
    }
    return false
  }

  render() {
    const { user } = this.props

    return (
      <Styled.NavigationItems>
        <Link className={`nav__link ${this.navItemIsActive('/', true) ? 'active' : ''}`} to="/">Scenarios</Link>
        {user.permissions.canSeeEquipmentLibraryNavigationItem && (
          <div className={`nav__link ${this.navItemIsActive('/screen') ? 'active' : ''} dropdown`} onMouseEnter={this.showBackdrop} onMouseLeave={this.hideBackdrop}>
            Equipment library
            <div className="dropdown__menu">
              <Container>
                <Grid container>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <div><Link className="dropdown__menu__item" to="/screens">Screens</Link></div>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        )}
        {/* <Link className={`nav__link ${this.navItemIsActive('/crops') ? 'active' : ''}`} to="#">Crops</Link>
        <Link className={`nav__link ${this.navItemIsActive('/strategy') ? 'active' : ''}`} to="#">Strategy</Link> */}

        <div className="backdrop" id="js-toggle"></div>
      </Styled.NavigationItems>
    )
  }
}

export default Navigation
