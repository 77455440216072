import styled, { AnyStyledComponent } from "styled-components"
import { Typography } from '@material-ui/core'

export const HeroWrapper: AnyStyledComponent = styled.header`
  ${({ theme }) => `
    background-color: #ffffff;
    padding: 3.25em 0;
    line-height: 36px;

    div.cta {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    
    div.cta--secondary {
      a, button {
        background-color: #F2F2F2;
        color: ${ theme.palette.primary.main };
        &:active,
        &:hover {
          background-color: #C2C2C2;
        }
      }
    }
  `}
`

export const Title: AnyStyledComponent = styled(Typography)`
  span {
    color: #9B9B9B;
  }
`