import React from "react"
import * as Styled from './styled'

const ReportFirstPage = () => {

  return (
    <Styled.Wrapper>
      <Styled.FrontPage />
    </Styled.Wrapper>
  )
}

export default ReportFirstPage
