import * as Types from "./types"

const initialState: Types.IStrategyState = {
  list: undefined
};

export function strategyReducer(
  state = initialState,
  action: Types.StrategyActionTypes
): Types.IStrategyState {
  switch (action.type) {

    case Types.STRATEGY_FETCHALL:
      return {
        list: action.payload
      }

    default:
      return state;
  }
}
