import styled, { AnyStyledComponent } from "styled-components"

export const FormContainer: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    .MuiButton-root,
    .MuiTextField-root {
      width: 100%;
      margin-top: 1em;
    }

    .MuiButton-root {
      padding: 1em;
      text-align: left;
    }

    .MuiInputBase-root {
      font-family: "Avenir Next Regular", Arial, sans-serif;
      font-size: 16px;
    }
    .MuiButton-label {
      display: block;
      color: ${ theme.palette.primary.main };
      font-family: "Avenir Next Medium", Arial, sans-serif;
      text-transform: none;
      font-size: 16px;
    }
  `}
`
