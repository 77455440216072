import * as Types from "./types";

const initialState: Types.NotificationState = {
  list: [],
};

export function notificationReducer(
  state = initialState,
  action: any
): Types.NotificationState {
  switch (action.type) {
    case Types.ENQUEUE_SNACKBAR:
      return {
        ...state,
        list: [
          ...state.list,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case Types.CLOSE_SNACKBAR:
      return {
        ...state,
        list: state.list.map((notification: any) => (
          (action.dismissAll || notification.key === action.key)
            ? { ...notification, dismissed: true }
            : { ...notification }
        )),
      };

    case Types.REMOVE_SNACKBAR:
      return {
        ...state,
        list: state.list.filter(
          (notification: any) => notification.key !== action.key,
        ),
      };
        
    default:
      return state;
  }
}
