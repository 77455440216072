import * as React from "react"
import { connect } from "react-redux"
import { AppState } from "../../store"
import { Container, Grid, Link} from "@material-ui/core"
import { IUser } from "../../interfaces/user"

import * as Styled from "./styled"
import Navigation from "../Navigation"
import { UserActions } from "../../store/user/actions"
import { IClient } from "../../interfaces/client"

interface IHeaderProps {
  user: IUser
  logout: any
  history: any
  title?: string
  client?: IClient
  report?: boolean
}

class Header extends React.Component<IHeaderProps> {
  onLogout = (e: any) => {
    e.preventDefault();
    this.props.logout();
  }

  render() {
    const { user, history, title, client, report } = this.props
    return (
      <Styled.HeaderWrapper>
        <div className="upper">
          <Container>
            <Grid container alignItems={report ? "center" : undefined}>
              <Grid item xs={3}>
                <Styled.Logo>
                  <Link href="/">
                    <img src="/assets/img/kubo-logo.png" width="75" height="41" id="kubo-logo" alt="Logo Kubo" />
                  </Link>
                </Styled.Logo>
              </Grid>
              { report ? (
                <Grid item xs={9}>
                  <Styled.Title variant="h1">{ title }</Styled.Title>
                  <Styled.SubTitle variant="h2">{ client && client.name }</Styled.SubTitle>
                </Grid>
              ) : user.isLoggedIn && (
                <>
                  <Grid item xs={6}>{ user.isLoggedIn && <Navigation user={user} history={history} /> }</Grid>
                  <Grid item xs={3}>
                    <Styled.Meta>
                      <span className="name">
                        <img src="/assets/img/icon-user.svg" width="25" height="25" alt="user icon" />
                        { user.name }
                      </span>
                      <span className="divider">|</span>
                      <Link href="#" onClick={this.onLogout.bind(this)}>Logout</Link>
                    </Styled.Meta>
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
        </div>
      </Styled.HeaderWrapper>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.user.user
})

export default connect(
  mapStateToProps,
  { logout: () => UserActions.Logout() }
)(Header)
