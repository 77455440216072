import { IStrategy } from "../../interfaces/strategy"

export interface IStrategyState {
  list?: IStrategy[]
}

export const STRATEGY_FETCHALL = "STRATEGY_FETCHALL"

interface IFetchStrategys {
  type: typeof STRATEGY_FETCHALL;
  payload: IStrategy[];
}

export type StrategyActionTypes = IFetchStrategys;
