import _ from "lodash"
import React from "react"
import { IScenario } from "../../../interfaces/scenario"
import { Field } from "formik"
import { Grid, Box } from "@material-ui/core"
import { TextField } from "formik-material-ui"

import { DefaultPanelContainer, DefaultPanelHeader, DefaultPanelBody } from "../DefaultPanel"

interface IGraphDataTabProps {
  scenario: IScenario
  setValues: any
  values: any
  setFieldValue: any
  disabledForm: boolean
}

const GraphDataTab = ({ scenario, disabledForm }: IGraphDataTabProps) => {
  return (
    <Box pt={4} p={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DefaultPanelContainer expanded={true}>
            <DefaultPanelHeader prefix="strategy-climate-daytime" name="Climate day time" icon={false} />
            <DefaultPanelBody>
              <Grid container spacing={2}>
              {
                _.map(scenario.strategy.climate.dayTime, (field: any, name: string) => {
                  if (name === "averageTemperature" || name === "relativeHumidityAverage") {
                    return (
                      <Grid item xs={3} key={name}>
                        <Field component={TextField} name={"strategy-climate-dayTime-" + name} label={name} variant="outlined" fullWidth defaultValue={field} disabled={disabledForm} />
                      </Grid>
                    )
                  }
                })
              }
              </Grid>
            </DefaultPanelBody>
          </DefaultPanelContainer>
        </Grid>

        <Grid item xs={12}>
          <DefaultPanelContainer expanded={true}>
            <DefaultPanelHeader prefix="strategy-climate-nighttime" name="Climate night time" icon={false} />
            <DefaultPanelBody>
              <Grid container spacing={2}>
              {
                _.map(scenario.strategy.climate.nightTime, (field: any, name: string) => {
                  if (name === "averageTemperature" || name === "relativeHumidityAverage") {
                    return (
                      <Grid item xs={3} key={name}>
                        <Field component={TextField} name={"strategy-climate-nighttime-" + name} label={name} variant="outlined" fullWidth defaultValue={field} disabled={disabledForm} />
                      </Grid>
                    )
                  }
                })
              }
              </Grid>
            </DefaultPanelBody>
          </DefaultPanelContainer>
        </Grid>
      </Grid>
    </Box>
  )
}

export default GraphDataTab;