import styled, { AnyStyledComponent } from "styled-components"

export const Container: AnyStyledComponent = styled.main`
  ${({ theme }) => `
    background-color: ${ theme.palette.primary.light };
    padding-top: 1em;
  `}
`

export const NavigationItems: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: flex-end;
    height: 100%;
    justify-content: space-around;
  `}
`

export const Tab: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    font-size: 16px;
    padding-left: 4em;
    padding-right: 4em;
    padding-bottom: 0.7em;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    border-bottom: 4px solid ${ theme.palette.primary.light };
    opacity: 70%;
    cursor: pointer;

    &.active,
    &:active,
    &:hover {
      border-bottom: 4px solid ${ theme.palette.background.default };
      opacity: 100%;
    }
  `}
`

export const TabContent: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    background-color: #fff;
    margin: 2em 0 4em 0;
    display: none;

    &.active {
      display: block;
    }
  `}
`

