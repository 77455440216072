import styled, { AnyStyledComponent } from "styled-components"
import MuiListItem from '@material-ui/core/ListItem'
import { Typography } from "@material-ui/core"

export const Wrapper = styled.div`
  padding-top: 20px;
`

export const Scroller = styled.div`
  max-height: 450px;
  overflow: auto;
`

export const ListItem: AnyStyledComponent = styled(MuiListItem)`
  ${({ theme }: any ) => `
    border-left: 9px solid transparent;
    &:hover {
      background: #F7F7F7;
      border-left-color: ${theme.palette.secondary.main};
    }
  `}
`

export const LatLong: AnyStyledComponent = styled(Typography)`
  ${({ theme }: any ) => `
    color: ${theme.palette.primary.light};
  `}
`