import React from "react"
import { Container, Grid, Box } from "@material-ui/core"

import Button from '../components/Button'
import Header from "../components/Header"
import Hero from "../components/Hero"
import ScreenTypesTable from "../components/ScreenTypesTable"
import ScreensTable from "../components/ScreensTable"

interface IScreensProps {
  history: any
  match: any
}

const Screens = ({ history, match: { params: { type }} }:IScreensProps) => (
  <Box mb={6}>
    <Header history={history} />
    <Hero title={`${type ? `<span>Screens /</span> ${type}` : 'Screens'}`}>
      <Grid container spacing={2} alignItems="center" justify="flex-end">
      { type && (
        <Grid item xs={6} className="cta cta--secondary">
          <Button to="/screens" variant="contained" color="secondary" disableElevation fullWidth>To overview</Button>
        </Grid>
        )}
        <Grid item xs={6} className="cta">
          <Button to={`/screen/create/${type || ''}`} variant="contained" color="secondary" disableElevation fullWidth>Add screen</Button>
        </Grid>
      </Grid>
    </Hero>

    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          {type ? (
            <ScreensTable screenType={type} />
          ) : (
            <ScreenTypesTable />
          )}
        </Grid>
      </Grid>
    </Container>
  </Box>
)

export default Screens
