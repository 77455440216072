import React from 'react'
import { Box, Typography } from "@material-ui/core"
import { IGraphItem, IGoogleFormatter } from '../../../../interfaces/graph'
import Chart from "react-google-charts"
import * as Styled from '../../styled'

interface ITemperaturesExtremeWarmRow {
  percentageHigher: number
  percentageLower: number
  temperature: number
  totalHoursHigherThanTemperature: number
  totalHoursLowerThanTemperature: number
  totalHoursWithSameTemperature: number
}

export const TemperaturesExtremeWarm = ({ resultData: data, description, name, pageBreakAfter }: IGraphItem) => {

  let responseObj: any = {};
  responseObj.cols = [
    {id: 'temperature', label: 'Temperature [°C]', type: 'number'},
    {id: 'count', label: 'Hours [h]', type: 'number'},
    {id: 'above', label: 'Hours higher [h]', type: 'number'},
    {id: 'percentage', label: 'Percentage of year higher [%]', type: 'number'},
  ];

  const sortedData: ITemperaturesExtremeWarmRow[] = data.sort((a: ITemperaturesExtremeWarmRow, b: ITemperaturesExtremeWarmRow) => (a.temperature > b.temperature) ? 1 : ((b.temperature > a.temperature) ? -1 : 0));

  let arrLength = sortedData.length;

  let endPoint = arrLength < 20 ? arrLength : 20;

  let trialObj = [];

  for (let i: number = (arrLength - endPoint); i < arrLength; i++) {
    trialObj.push(
      {
        c: [
          {v: sortedData[i].temperature},
          {v: Number((sortedData[i].totalHoursWithSameTemperature).toFixed(0))},
          {v: Number((sortedData[i].totalHoursHigherThanTemperature).toFixed(0))}, // copied from cold, lower is basicly higher
          {v: sortedData[i].percentageHigher},
        ],
      });
  }

  responseObj.rows = trialObj;

  const options = {
    allowHtml: true,
    cssClassNames: {headerRow: 'headerClass'},
    width: 900,
  };

  const gradientLevels: any[] = []
  const levels = 100
  for (var i = 0; i <= levels; i++) {
    gradientLevels.push([ i, i+1, i < 50 ? 'black' : 'white', `rgba(255,0,0,${i / 100})`])
  }

  const formattedColumns: IGoogleFormatter[] = [{ 
    type: "BarFormat",
    column: 1, 
    options: { width: 120, colorPositive: 'red', drawZeroLine: 'true' },
  },
  {
    type: "ColorFormat",
    column: 3,
    options: { suffix: '%' },
    ranges: gradientLevels,
  }]
  
  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          data={responseObj}
          loader={<div>Loading Chart</div>}
          width={900}
          height="auto"
          chartType="Table"
          options={options}
          formatters={formattedColumns}
        />
      </Box>
    </Styled.Container>
  )
}