import * as Types from "./types"

const initialState: Types.IClientState = {
  list: undefined
};

export function clientReducer(
  state = initialState,
  action: Types.ClientActionTypes
): Types.IClientState {
  switch (action.type) {

    case Types.CLIENT_FETCHALL:
      return {
        list: action.payload
      }

    default:
      return state;
  }
}
