import React from 'react'
import { useDispatch } from 'react-redux'

import {
  IconButton
} from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete'
import { NotificationActions } from '../store/notification/actions'

export const useNotify = () => {
  const dispatch = useDispatch()
  const addNotification = (notification: any) => dispatch(NotificationActions.Enqueue(notification))
  const closeNotification = (key: number) => dispatch(NotificationActions.Close(key))

  return (message: string, variant: string) => addNotification({
    message,
    options: {
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 2000,
        key: new Date().getTime() + Math.random(),
        variant,
        action: (key: number) => (
          <IconButton aria-label="delete" onClick={() => closeNotification(key)}>
            <DeleteIcon />
          </IconButton>
        ),
    },
  })
}