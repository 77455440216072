import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { NotificationActions } from '../../store/notification/actions';
import { AppState } from '../../store'

const Notifier = () => {
  let displayed: any = [];
  
  const dispatch = useDispatch();
  const notifications = useSelector((store: AppState) => store.notifications.list || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: number) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: number) => {
    displayed = [...displayed.filter((key: any) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }: any) => {
			if (dismissed) {
			// dismiss snackbar using notistack
				closeSnackbar(key);
				return;
			}

			// do nothing if snackbar is already displayed
			if (displayed.includes(key)) return;

			// display snackbar using notistack
			enqueueSnackbar(message, {
				key,
				...options,
				onClose: (event, reason, myKey) => {
					if (options.onClose) {
						options.onClose(event, reason, myKey);
					}
				},
				onExited: (event: any, myKey: number) => {
					// removen this snackbar from redux store
					dispatch(NotificationActions.Remove(myKey));
					removeDisplayed(myKey);
				},
			});

			// keep track of snackbars that we've displayed
			storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return null
}

export default Notifier
