import React from "react"
import { Container, Grid, Box, Typography } from "@material-ui/core"

import Header from "../components/Header"
import Hero from "../components/Hero"

interface IErrorProps {
  history: any
  match: any
}

const Screens = ({ history }: IErrorProps) => (
  <Box mb={6}>
    <Header history={history} />
    <Hero title="Something went wrong"></Hero>

    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <Box py={2}>
            <Typography variant="body1">Please contact your development team.</Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  </Box>
)

export default Screens
