import styled, { AnyStyledComponent } from "styled-components"
import { IconButton } from "@material-ui/core"

export const Container: AnyStyledComponent = styled.div`
  ${({ theme }) => `
    background-color: #fff;
    width: 450px;
    padding: 2em;
    margin: 2em auto 0 auto;
    position: relative;
    &:focus {
      outline: 0;
    }
  `}
`

export const CloseIcon = styled(IconButton)`
  position: absolute;
  right:0;
  top:0;
`
