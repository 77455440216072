import _ from "lodash"
import React, { useState, useEffect } from "react"

import { connect } from "react-redux"
import { AppState } from "../../../store"
import { flatten, unflatten } from "flat"

import { CropActions } from "../../../store/crop/actions"
import { IScenario } from "../../../interfaces/scenario"
import { FastField } from "formik"
import { TextField } from "formik-material-ui"

import {
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Typography,
} from "@material-ui/core"

interface ICropTabProps {
  scenario: IScenario
  crops: any,
  fetchCropValues: any
  setValues: any
  values: any
  setFieldValue: any
  disabledForm: boolean
}

const CropTab = ({scenario, crops, setValues, fetchCropValues, values, setFieldValue, disabledForm}: ICropTabProps) => {
  const prefix = "cropValues";
  const [selectValue, setSelectValue] = useState(0)
  const [cropValues, setCropValues] = useState(scenario.cropValues)

  useEffect(() => {
    // get id op selected crop
    // TODO: refactor to prevent uncontrolled warning
    const selectedCrop = _.find(crops, { name: cropValues.name })
    if (selectedCrop) setSelectValue(selectedCrop.id)
  }, [])

  const handleChange = (e: any) => { 
    const id = e.target.value
    setSelectValue(id)
    setCropValues(undefined)

    // unflatten, set new form fields re-set
    const formValues: any = unflatten(values, { delimiter: "-" })
    formValues.cropValues.properties = {}

    fetchCropValues(id).then((res: any) => {
      // update current state object for field loop
      setCropValues(res)

      // update formik values with new retrieved items
      formValues.cropValues = res;
      const flatFormValues = flatten(formValues, { delimiter: "-" })
      setValues(flatFormValues);
    })
  }

  return (
    <Box pt={4} p={4}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
        { crops ?
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="selectCrop">Select a crop</InputLabel>
            <Select labelId="selectCrop" label="Select a crop" name={prefix + "-name"} onChange={handleChange} value={selectValue} disabled={disabledForm}>
              {
                _.map(crops, (item: any, key: number) => {
                  return <MenuItem value={item.id} key={prefix + "-cropSelection-" + key}>{item.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          : "Loading..."}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        { cropValues ?
          <>
            {
              _.map(cropValues.properties, (fields: any, name: string) => {
                return (
                  <Grid item xs={12} key={prefix + "-" + name}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2" color="primary">{ name }</Typography>
                      </Grid>
                      {
                        _.map(fields, (value: any, subTitle: string) => {
                          return (
                            <Grid item xs={3} key={prefix + "-" + name + "-" + subTitle}>
                              <FastField component={TextField} name={prefix + "-properties-" + name + "-" + subTitle} label={subTitle} variant="outlined" fullWidth disabled={disabledForm} />
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  </Grid>
                )
              })
            }
          </>
          : <Box p={2} pt={2}>Loading...</Box>
        }
      </Grid>
    </Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  crops: state.crops.list
});

export default connect(
  mapStateToProps,
  {
    fetchCropValues: (id: number) => CropActions.Fetch(id)
  }
)(CropTab)