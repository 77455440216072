import React, { useState, useEffect } from 'react'

import { Field } from 'formik'
import { connect } from "react-redux"
import { compose, withProps, lifecycle } from 'recompose'
import debounce from 'lodash/debounce'
import { withGoogleMap, GoogleMap } from "react-google-maps"
import Geocode from "react-geocode"
import MuiPlacesAutocomplete, { geocodeByPlaceID } from 'mui-places-autocomplete'
import { Box, Button, Grid, Typography } from '@material-ui/core'

import { AppState } from "../../store"
import { ILocation, IPreviousLocation } from '../../interfaces/scenario'
import { ScenarioActions } from "../../store/scenario/actions"
import * as Styled from './styled'
import PreviousLocations from './PreviousLocations'
import { useNotify } from '../../Hooks'

interface IGoogleLocation {
  lat: number
  lng: number
}

interface IMapProps {
  center: IGoogleLocation
  fetchLocations: () => void
  height?: string
  locations: IPreviousLocation[]
  city: string
  country: string
  zoom?: any
  disabledForm: boolean
  setFieldValue: any
}

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || ''
const GOOGLE_MAP_URL=`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&amp;libraries=places`

Geocode.setApiKey(GOOGLE_API_KEY);

const MapWithControl: any = compose(
  withProps({
    googleMapURL: GOOGLE_MAP_URL,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: '815px' }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs: any = {}

      this.setState({
        bounds: null,
        markers: [],
        onMapMounted: (ref: any) => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          })
        },
      })
    },
  }),
  withGoogleMap
)((props: any) => {

  const onChange = () => {
    props.onBoundsChanged()
    props.debounceChange(props.center)
  }

  let options = props.isDisabled ? {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    scaleControl: false,
    draggable: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  } : {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
  }

  return (
    <GoogleMap
      defaultCenter={{ lat: 52.1561113, lng: 5.3878266 }}
      defaultOptions={options}
      center={props.coordinates ?? { lat: 52.1561113, lng: 5.3878266 }}
      onCenterChanged={onChange}
      ref={props.onMapMounted}
      zoom={props.zoom}
      >
      <Styled.CrossHair>
        <span className="line line--x"></span>
        <span className="line line--y"></span>
        <i />
      </Styled.CrossHair>
    </GoogleMap>
    )
  }
)

type TCombinedProps = IMapProps

const Map = ({ 
  center, 
  fetchLocations,
  height,
  locations, 
  city, 
  country,
  zoom,
  disabledForm,
  setFieldValue,
}: TCombinedProps) => {
  const initialPosition: IGoogleLocation = {
    lat: center.lat,
    lng: center.lng,
  }

  const [coordinates, setCoordinates] = useState(initialPosition)
  const [chosenCity, setChosenCity] = useState(city)
  const [chosenCountry, setChosenCountry] = useState(country)
  const [locationIsSet, setLocationIsSet] = useState(false)
  const [search, setSearch] = useState('')
  const notify = useNotify()

  useEffect(() => {

    fetchLocations()

    if (coordinates && chosenCity && chosenCountry) {
      setLocationIsSet(true)
    }

  }, [fetchLocations])

  const setFormFieldValue = (coordinates: IGoogleLocation, id?: number) => {
    setFieldValue("location-latitude", coordinates.lat)
    setFieldValue("location-longitude", coordinates.lng)
    if (id) {
      setFieldValue("location-id", id)
      setLocationIsSet(true)
    } else {
      setLocationIsSet(false)
    }
  }

  const setAddressInfo = (lat: number, lng: number) => {
    Geocode.fromLatLng(lat.toString(), lng.toString())
      .then((response: any) => {
        const address = response.results[0]
        const data = {
          city: address && address.address_components.length > 3 ? address.address_components[3].long_name : 'Unknown city',
          country: address && address.address_components.length > 1 ? address.address_components[address.address_components.length - 2].long_name : address.address_components[0].long_name,
        }
        setChosenCity(data.city)
        setChosenCountry(data.country)

        setFieldValue("location-city", data.city);
        setFieldValue("location-country", data.country);
        setLocationIsSet(true)
      },
      (error: any) => {
        notify('No address found', 'error')
        console.error(error);
      })
  }

  const onChangeInput = (e: any, type: "lat" | "lng") => {
    let newCoordinates = coordinates
    newCoordinates[type] = e.target.value
    setCoordinates(newCoordinates)
    
    setFormFieldValue(coordinates)
  }

  const onHandleChange = (lat: number, lng: number) => {
    setCoordinates({ lat, lng })
    setFormFieldValue({ lat, lng })
  }

  const onSuggestionSelected = (suggestion: any) => {
    // get the lat/lng of the first suggested location
    geocodeByPlaceID(suggestion.place_id).then((results: any) => {
      const { geometry } = results[0]

      const coordinates: IGoogleLocation = {
        lat: geometry.location.lat(),
        lng: geometry.location.lng(),
      }

      setCoordinates(coordinates)
      setFormFieldValue(coordinates)
    }).catch((err: any) => {
      // Handle any errors that occurred when we tried to get geospatial data for a selected
      // suggestion...
      console.log(err)
    })
  }

  const onSetLocation = () => {
    if (!coordinates.lat || !coordinates.lng) {
      notify('Incorrect lat / lng combination', 'error')
      return false
    }
    setFieldValue("location-id", "0")
    onUpdateCenter(coordinates)
    setAddressInfo(coordinates.lat, coordinates.lng)
  }

  const onClickLocation = (coordinates: IGoogleLocation, searchValue: string) => {
    setSearch(searchValue)
    setCoordinates(coordinates)
    setAddressInfo(coordinates.lat, coordinates.lng)
  }

  const onUpdateCenter = (coordinates: IGoogleLocation) => {
    setCoordinates({ 
      lat: parseFloat(`${coordinates.lat}`), 
      lng: parseFloat(`${coordinates.lng}`) 
    })
  }

  const onChangeAutoComplete = (e: any) => {
    setLocationIsSet(false)
    setSearch(e.target.value)
  }

  let debouncer = debounce((center) => {
    if (center) {
      onHandleChange(center.lat(), center.lng())
      // clear autocomplete
      setSearch('')
    }
  }, 500)

  return (
    <div>

      <Grid container spacing={0}>
        <Grid item md={4}>
          <Box px={2}>
            {disabledForm ? (
              <Box pt={2}>
                <Typography color="primary" variant="h2" gutterBottom>Selected location</Typography>
              </Box>
            ) : (
              <>
                <Box pt={2} pb={1}>
                  <Typography color="primary" variant="h2" gutterBottom>Select a location</Typography>
                  <Typography gutterBottom>
                    Please enter a city or the longitude and latitude.
                  </Typography>
                </Box>

                <div style={{position: 'relative', zIndex: 2}}>
                  <MuiPlacesAutocomplete
                    onSuggestionSelected={onSuggestionSelected}
                    renderTarget={() => (<div />)}
                    textFieldProps={{
                      fullWidth: true,
                      label: 'Search location',
                      name: 'search',
                      variant: "outlined",
                      value: !locationIsSet ? search : (chosenCity && chosenCountry && (`${chosenCity} / ${chosenCountry}`)),
                      onChange: onChangeAutoComplete,
                    }}
                  />
                </div>
              </>
            )}

            <Box pt={2}>
              <Grid container spacing={1} justify="space-between" wrap="nowrap">
                <Grid item>
                    {coordinates && (
                      <Box pb={0.5}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Styled.TextField  
                              variant="outlined"
                              label={"Latitude"}
                              disabled={disabledForm}
                              value={coordinates.lat}
                              onChange={(e: any) => onChangeInput(e, 'lat')}
                            />
                            <Field type="hidden" name="location-latitude" />
                          </Grid>
                          <Grid item xs={6}>
                            <Styled.TextField 
                              variant="outlined" 
                              label={"Longitude"} 
                              disabled={disabledForm} 
                              value={coordinates.lng} 
                              onChange={(e: any) => onChangeInput(e, 'lng')}
                            />
                            <Field type="hidden" name="location-longitude" />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  <Typography color="textSecondary" variant="body2">
                    {chosenCity && chosenCountry && (
                      `${chosenCity} / ${chosenCountry}`
                    )}
                  </Typography>
                </Grid>
                {!disabledForm &&(
                  <Grid item>
                    <Button variant="contained" color="secondary" disabled={locationIsSet} onClick={onSetLocation}>Set</Button>
                  </Grid>
                )}
              </Grid>
            </Box>

            { !disabledForm && <PreviousLocations items={locations} onClick={onClickLocation} setFormFieldValue={setFormFieldValue} />}
          </Box>
        </Grid>

        <Grid item md={8}>
          <Box position="relative">
            <MapWithControl
              coordinates={coordinates}
              isDisabled={disabledForm}
              onMapChanged={onHandleChange}
              debounceChange={debouncer}
              zoom={12}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  locations: state.scenarios.locations,
})
const mapDispatchToProps = ({
  setLocation: (location: ILocation) => ScenarioActions.SetLocation(location),
  fetchLocations: () => ScenarioActions.FetchLocations(),
})

export default connect(mapStateToProps, mapDispatchToProps)(Map)
