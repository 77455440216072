import { Dispatch } from "redux"
import { KuboUserService } from "../../services/api/Kubo/UserService"
import { USER_LOADING, USER_LOGIN, USER_FETCH, USER_LOGOUT, IToken } from "./types"

export const UserActions = {
  Authenticate: (username: string, password: string) => {
    return async (dispatch: Dispatch) => {
      const data = await KuboUserService.Authenticate(username, password)
      dispatch(UserActions.dispatchLogin(data))
      return true
    }
  },

  ReloadAuth: () => {
    return async (dispatch: Dispatch) => {

      // LocalStorage always converts data to a string; unless the key doesn't exist.
      let accessToken = localStorage.getItem('accessToken') ?? "null"
      let refreshToken = localStorage.getItem('refreshToken') ?? "null"

      if ("null" !== accessToken) {
        const data = {
          access_token: accessToken,
          refresh_token: refreshToken
        } as IToken
        dispatch(UserActions.dispatchLogin(data))
        return true
      } else {
        return false
      }
    }
  },

  Fetch: () => {
    return async (dispatch: Dispatch) => {
      const data = await KuboUserService.Fetch()
      dispatch(UserActions.dispatchFetch(data))
      return data
    }
  },

  Logout: () => {
    return async (dispatch: Dispatch) => {
      await KuboUserService.Logout()
      dispatch(UserActions.dispatchLogout())
    }
  },

  dispatchLogin: (data: any) => ({
    type: USER_LOGIN,
    payload: data
  }),

  dispatchLoading: (data: boolean) => ({
    type: USER_LOADING,
    payload: data
  }),

  dispatchFetch: (data: any) => ({
    type: USER_FETCH,
    payload: data
  }),

  dispatchLogout: () => ({
    type: USER_LOGOUT
  })
}
