import React from 'react'
import { ITextItem } from '../../../interfaces/graph'
import { Typography } from "@material-ui/core"
import * as Styled from '../styled'

const TextItem = ({ description, name, pageBreakAfter}: ITextItem) => {
  if (!name) return null
  
  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2" gutterBottom>{name}</Typography>
      <Typography variant="body1" dangerouslySetInnerHTML={{ __html: description }} gutterBottom />
    </Styled.Container>
  )
}

export default TextItem