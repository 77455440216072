import { IUser } from "../../interfaces/user";

import {
  UserState,
  USER_LOGIN,
  USER_FETCH,
  USER_LOGOUT,
  UserActionTypes,
  IToken
} from "./types"

const initialState: UserState = {
  tokens: {} as IToken,
  user: {} as IUser
}

export function userReducer(
  state = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {

    case USER_LOGIN:
      localStorage.setItem('accessToken', action.payload.access_token)
      localStorage.setItem('refreshToken', action.payload.refresh_token)
      return {
        tokens: action.payload,
        user: state.user
      }

    case USER_FETCH:
      action.payload.isLoggedIn = true;
      return {
        tokens: state.tokens,
        user: action.payload
      }

    case USER_LOGOUT:
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      return initialState

    default:
      return state;
  }
}
