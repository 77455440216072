import { Dispatch } from "redux"
import { KuboScreenService } from "../../services/api/Kubo/ScreenService"
import { IScreen } from '../../interfaces/screen'
import * as Types from "./types"

export const ScreenActions = {
  Fetch: (id: number) => {
    return async (dispatch: Dispatch) => {
      const data = await KuboScreenService.Fetch(id)
      dispatch(ScreenActions.dispatchFetch(data))
      return data
    }
  },

  FetchTypes: () => {
    return async (dispatch: Dispatch) => {
      const data = await KuboScreenService.FetchTypes()
      dispatch(ScreenActions.dispatchFetchTypes(data))
    }
  },

  FetchByType: (type: string) => {
    return async (dispatch: Dispatch) => {
      const data = await KuboScreenService.FetchByType(type)
      dispatch(ScreenActions.dispatchFetchByType(data))
      return data
    }
  },

  CreateOrEdit: (screen: IScreen) => {
    return async (dispatch: Dispatch) => {
      return await KuboScreenService.Update(screen.id, screen)
    }
  },

  Delete: (id: number) => {
    return async (dispatch: Dispatch) => {
      return await KuboScreenService.Delete(id)
    }
  },

  Duplicate: (id: number) => {
    return async (dispatch: Dispatch) => {
      return await KuboScreenService.Duplicate(id)
    }
  },

  dispatchFetch: (data: any) => ({
    type: Types.SCREEN_FETCH,
    payload: data
  }),

  dispatchFetchTypes: (data: any) => ({
    type: Types.SCREEN_FETCHTYPES,
    payload: data
  }),

  dispatchFetchByType: (data: any) => ({
    type: Types.SCREEN_FETCHBYTYPE,
    payload: data
  }),
}
