import React, { useState, useEffect } from "react"
import { Form, Field, Formik } from 'formik'
import { Checkbox, TextField } from 'formik-material-ui'
import { connect } from "react-redux"
import { AppState } from "../../../store"
import { ScenarioActions } from "../../../store/scenario/actions"
import { IScenario, IContent, IPlaceholder, IPlaceholderItem } from "../../../interfaces/scenario"
import { useNotify } from "../../../Hooks"
import * as Styled from "./styled"

import { unflatten } from "flat"

import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import ExpandMore from '@material-ui/icons/ExpandMore'

interface IProps {
  history: any
  scenario: IScenario
}

interface IStateProps {
  activeTab: string
  content: IContent
}

interface IDispatchProps {
  fetchContent: (id: number) => Promise<any>
  fetchPlaceholders: (id: number) => Promise<any>
  updateContent: (content: IContent) => Promise<any>
  setActiveTab: (tab: string) => void
}

type TGraphTabCombined = IProps & IStateProps & IDispatchProps

const GraphTab = ({
  activeTab,
  content,
  history,
  scenario,
  fetchContent,
  fetchPlaceholders,
  updateContent,
  setActiveTab,
}: TGraphTabCombined) => {
  const [expanded, setExpanded] = React.useState<string>('');
  const [expandedPH, setExpandedPH] = useState<string | false>()
  const [placeholders, setPlaceholders] = useState<IPlaceholder[]>([])
  const notify = useNotify()

  useEffect(() => {
    fetchPlaceholders(scenario.id).then((data) => setPlaceholders(data.categories))
    fetchContent(scenario.id)
    
    if (scenario.state === 'Finished') {
      setActiveTab('graph')
    }
  }, [fetchContent, fetchPlaceholders, setActiveTab, scenario])
  
  const toggleCategory = (key: string) => (event: React.ChangeEvent<{}>, newExpanded: string) => {
    setExpandedPH(newExpanded ? key : false)
  }

  const handleChange = (key: string) => {
    setExpanded(key)
  }
  
  const renderPlaceholders = () => {
    if (!placeholders) return null
    
    return placeholders.map((item: IPlaceholder, i: number) => (
      <Styled.Panel square expanded={expandedPH === 'cat' + i} onChange={toggleCategory('cat' + i)} key={`placeholder-${i}`}>  
        <Styled.PanelSummary expandIcon={<ExpandMore fontSize="small" />}>
          {item.name}
        </Styled.PanelSummary>
        <Styled.PanelDetails>
          <List>
            {item.placeholders.map((p: IPlaceholderItem, index: number) => (
              <ListItem key={`placeholder-item-${i}-${index}`}>
                <ListItemText
                  primary={
                    <>
                      <Styled.PlaceholderKey variant="body1">{p.name}</Styled.PlaceholderKey>
                      <Typography variant="body2">{p.description}</Typography>
                    </>
                  }
                  secondary={`value: ${p.value}`}
                />
              </ListItem>
            ))}
          </List>
        </Styled.PanelDetails>
      </Styled.Panel>
    ))
  }

  const getInitialValues = (content: IContent) => {
    return {
      id: content.id,
      content: content.content,
    }
  }

  const doFormikSubmit = (values: any, { setSubmitting }: any) => {
    setSubmitting(true)

    const data: any = unflatten(values, {delimiter: "-"})
    updateContent(data).then(() => {
      history.push("/")
      notify("Report graphs & texts saved", "success")

      setSubmitting(false)
    }).catch((err) => {
      notify("Something went horribly wrong...", "error")
      setSubmitting(false)
    })
  }

  const SaveAndShowButtons = ({ handleSubmit }: any) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={8}></Grid>
        <Grid item xs={2}>
          <Button href={`/report/${scenario.token}`} target="_blank" rel="nofollow noopener noreferrer" variant="contained" color="secondary" fullWidth>Show report</Button>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="secondary" fullWidth onClick={handleSubmit}>Save</Button>
        </Grid>
      </Grid>
    )
  }

  const ExpandableIcon = (panel: string) => {
    return (
      <span onClick={() => handleChange(panel)}>
        <ExpandMore fontSize="small" />
      </span>
    )
  }

  if (!content || !content.id) return null
  return (
    <Container>
      <Formik
        initialValues={getInitialValues(content)}
        onSubmit={doFormikSubmit}>
        {({ setFieldValue, handleSubmit, ...props }) => (
          <Form>
            { activeTab === 'graph' && (
            <Styled.TabContent>
              <Box p={2} position={"relative"}>
                <SaveAndShowButtons handleSubmit={handleSubmit} />
                <Grid container spacing={2}>
                  <Grid item xs={4} component="aside">
                    <Typography variant="h2" gutterBottom>Placeholders</Typography>
                    { renderPlaceholders() }
                  </Grid>

                  <Grid item xs={8}>
                    <Field type="hidden" name="id" />
                    <Typography variant="h2" gutterBottom>Graphs & texts</Typography>
                    {
                      content && content.content && content.content.map((value: any, key: number) => {
                        return (
                          <Styled.Panel square expanded={expanded === 'panel' + key} key={key}>
                            <Styled.PanelSummary expandIcon={ExpandableIcon('panel' + key)}>
                              <Field component={Checkbox} name={`content[${key}][visible]`} defaultChecked={value.visible} />
                              <Styled.PanelTitle aria-controls={"panel" + key + "d-content"} id={"panel" + key + "d-header"} onClick={() => handleChange('panel' + key)}>
                                {value.name}
                              </Styled.PanelTitle>
                            </Styled.PanelSummary>
                            <Styled.PanelDetails padding="true">
                              <Field 
                                component={TextField} 
                                name={`content[${key}][description]`}
                                variant="outlined" 
                                multiline
                                rows={6} 
                                fullWidth 
                              />
                            </Styled.PanelDetails>
                          </Styled.Panel>
                        )
                      })
                    }
                  </Grid>
                </Grid>
                <SaveAndShowButtons handleSubmit={handleSubmit} />
              </Box>
            </Styled.TabContent>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  )
}

const mapStateToProps = (state: AppState) => ({
  content: state.scenarios.content,
  activeTab: state.scenarios.activeTab,
});

export default connect(
  mapStateToProps,
  {
    fetchPlaceholders: (id: number) => ScenarioActions.FetchPlaceholders(id),
    fetchContent: (id: number) => ScenarioActions.FetchContent(id),
    updateContent: (content: IContent) => ScenarioActions.UpdateContent(content),
    setActiveTab: (tab: string) => ScenarioActions.SetActiveTab(tab),
  }
)(GraphTab)