import * as React from "react"
import { Container, Grid } from "@material-ui/core"

import { Field } from "formik"
import { TextField } from "formik-material-ui"

import * as Styled from "./styled"

interface IEditHeaderProps {
}

class Header extends React.Component<IEditHeaderProps> {
  render() {
    return (
      <Styled.Container>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Field component={TextField} name="name" label="Name of the screen" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={9}>
              <Field component={TextField} name="note" label="Notes" variant="outlined" fullWidth />
            </Grid>
          </Grid>
        </Container>
      </Styled.Container>
    )
  }
}

export default Header
