import { IStructure } from "../../interfaces/structure"

export interface IStructureState {
  list?: IStructure[]
}

export const STRUCTURE_FETCHALL = "STRUCTURE_FETCHALL"

interface IFetchStructures {
  type: typeof STRUCTURE_FETCHALL;
  payload: IStructure[];
}

export type StructureActionTypes = IFetchStructures;
