import styled, { AnyStyledComponent } from "styled-components"
import { TableRow, TableCell } from "@material-ui/core"

export const ThRow: AnyStyledComponent = styled(TableRow)`
  ${({ theme }) => `
    th:first-child {
      border-top-left-radius: 6px;
    }
    th:last-child {
      border-top-right-radius: 6px;
    }
  `}
`

export const Th: AnyStyledComponent = styled(TableCell)`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.light};
    color: #fff;
    font-size: 12px;
    padding: 6px 16px;
  `}
`

export const TdRow: AnyStyledComponent = styled(TableRow)`
  ${({ theme }) => `
  `}
`

export const Td: AnyStyledComponent = styled(TableCell)`
  ${({ orb, theme }: any) => `
    background-color: ${theme.palette.common.white};
    font-size: 14px;

    img {
      margin-right: 1em;
    }

    ${orb === 'true' ? `
      padding: 0;
      width: 40px;
    ` : '' }
  `}
`

export const Orb: AnyStyledComponent = styled.div`
  ${({ type }: any) => `
    &:after {
      content: " ";
      width: 10px;
      height: 10px;
      margin: 0 auto;
      display: block;
      border-radius: 50%;
      ${type === 'draft' ? 'background-color: #FFCC01;' : ''}
      ${type === 'collecting' ? 'background-color: #CCCCCC;' : ''}
      ${type === 'collected' ? 'background-color: #76B0E0;' : ''}
      ${type === 'generated' ? 'background-color: #7CBF68;' : ''}
    }
  `}
`