import React, { useEffect } from "react"
import { connect } from "react-redux"

import { AppState } from "../../store"
import { ScreenActions } from "../../store/screen/actions"
import { IScreenType } from '../../interfaces/screen'

import * as Styled from "./styled"

import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Box,
  Typography,
} from "@material-ui/core"
import { Link } from '../Link'

interface IScreenTypesTableProps {
  screenTypes: IScreenType[]
  loadScreenTypes: () => void
}

const ScreenTypesTable = ({
  screenTypes, 
  loadScreenTypes, 
}: IScreenTypesTableProps) => {

  useEffect(() => {
    loadScreenTypes()
  }, [loadScreenTypes])

  return (
    <Box mt={4}>
      <TableContainer>
        <Table>
          <TableHead>
            <Styled.ThRow>
              <Styled.Th>Name</Styled.Th>
            </Styled.ThRow>
          </TableHead>
          <TableBody>
          {screenTypes && screenTypes.map((item: any, key: number) =>
            <Styled.TdRow key={key}>
              <Styled.Td>
                <Link to={`/screens/${item.name.toLowerCase()}`}>
                  <Typography variant="caption" color="textPrimary">{item.name}</Typography>
                </Link>
              </Styled.Td>
            </Styled.TdRow>
          )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  screenTypes: state.screens.screenTypes,
});

export default connect(
  mapStateToProps,
  {
    loadScreenTypes: () => ScreenActions.FetchTypes(),
  }
)(ScreenTypesTable)
