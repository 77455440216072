import styled, { AnyStyledComponent } from "styled-components"
import { TableRow, TableCell } from "@material-ui/core"

export const ThRow: AnyStyledComponent = styled(TableRow)`
  ${({ theme }) => `
    th:first-child {
      border-top-left-radius: 6px;
    }
    th:last-child {
      border-top-right-radius: 6px;
    }
  `}
`

export const Th: AnyStyledComponent = styled(TableCell)`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.light};
    color: #fff;
    font-size: 12px;
    padding: 6px 16px;
  `}
`

export const TdRow: AnyStyledComponent = styled(TableRow)`
  ${({ theme }) => `
  `}
`

export const Td: AnyStyledComponent = styled(TableCell)`
  ${({ theme }) => `
    background-color: ${theme.palette.common.white};
    font-size: 14px;
    &.orb {
      width: 30px;
      padding: 0;
      &:after {
        content: " ";
        width: 10px;
        height: 10px;
        margin: 0 auto;
        display: block;
        border-radius: 50%;
      }
    }

    &.orb--draft:after {
      background-color: #FFCC01;
    }
    &.orb--collecting:after {
      background-color: #CCCCCC;
    }
    &.orb--collected:after {
      background-color: #76B0E0;
    }
    &.orb--generated:after {
      background-color: #7CBF68;
    }

    img {
      margin-right: 1em;
    }
  `}
`