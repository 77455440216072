import React from 'react'
import { Box, Typography } from "@material-ui/core"
import Chart from "react-google-charts"
import { IGoogleFormatter, IGraphItem } from '../../../../interfaces/graph'
import * as Styled from '../../styled'

interface ITemperatureHumidityRow {
  bc: boolean // badcell
  h: number // humidity
  temp: number // temperature
  yc: number // yearCount
}

export const TemperatureHumidity = ({ resultData: data, description, name, pageBreakAfter }: IGraphItem) => {
  let responseObj: any = {};
  responseObj.cols = [{
    id: 'temperature',
    label: 'Temperature',
    type: 'number'
  }];

  for (let i: number = 0; i < 21; i++) {
    responseObj.cols.push({
      id: i * 5,
      label: + i * 5 + '%',
      type: 'number',
      value: i * 5 / 100
    });
  }

  const maxTemp: number = Math.max.apply(null, data.map((obj: ITemperatureHumidityRow) => obj.temp));
  const minTemp: number = Math.min.apply(null, data.map((obj: ITemperatureHumidityRow) => obj.temp));
  const maxCount: number = Math.max.apply(null, data.map((obj: ITemperatureHumidityRow) => Number((obj.yc).toFixed(0))));

  const amountOfCols = responseObj.cols.length
  const amountOfRows = Math.ceil(maxTemp - minTemp + 1)

  // create 2d array with all positions to plot the values on
  let dummyTable = Array.from(Array(amountOfRows), () => Array(amountOfCols).fill(null))

  data.map(( item: ITemperatureHumidityRow, index: number ) => {
    let row = Math.floor(item.temp - minTemp);
    let col = responseObj.cols.findIndex((obj: any) => {
      return obj.value === item.h;
    });

    dummyTable[row][0] = {
      label: '<div style="background: #000e85; color: #fff; text-align:center">' + Math.round(item.temp) + '°C' + '</div>',
      value: item.temp,
    }

    let value = Number((item.yc).toFixed(0)) === 0 ? ' ' : Number(item.yc).toFixed(0)
    let formated = (Number((item.yc).toFixed(0)) === 0 || item.bc === false)
      ? '<div style="text-align: center;">' + value + '</div>'
      // : item.activeCool === true
      //     ? '<div style="background-color: rgba(255, 255, 0, 0.25); border-radius: 25%; border: 1px solid #444; text-align: center;">' + item.yearCount + '</div>'
          : '<div style="background-color: rgba(255, 255, 255, 0.25); border-radius: 25%; border: 1px solid #444; text-align: center;">' + Number((item.yc).toFixed(0)) + '</div>';

    dummyTable[row][col] = {
      label: formated,
      value: value,
    }

  })

  responseObj.rows = []

  dummyTable.map((r: any) => {
    responseObj.rows.push({
      c: [
        ...r.map((c: any) => {
          if (c) {
            return { v: c.value, f: c.label }
          }
          return { v: null }
        })
      ]
    })
  })

  const options = {
    allowHtml: true,
    cssClassNames: {headerRow: 'headerClass'},
    width: 820,
  };

  const gradientLevels: any[] = []
  const levels = 20
  for (var i = 0; i < levels; i++) {
    const perc = i / levels 
    const nextPerc = ((i+1) / levels)
    gradientLevels.push([ maxCount * perc, maxCount * nextPerc + 1, 'black', `rgba(255,0,0,${perc})`])
  }

  const formattedColumns: IGoogleFormatter[] = []
  responseObj.cols.map((col: any, index: number) => {
    if (index > 0) {
      formattedColumns.push({
        type: "ColorFormat",
        column: index,
        ranges: gradientLevels
      })
    }
  })

  return (
    <Styled.Container after={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Chart
          data={responseObj}
          loader={<div>Loading Chart</div>}
          width={820}
          height="100%"
          chartType="Table"
          options={options}
          formatters={formattedColumns}
        />
      </Box>
    </Styled.Container>
  )
}