import React from 'react'
import { Box, Typography, Table, TableHead, TableRow, TableBody } from "@material-ui/core"
import * as Styled from './styled'
import { IGraphItem } from '../../../../interfaces/graph'

interface IEnergyCostsTotal {
  co2Totals: {
    co2AdditionRequiredTotal: number
    co2AvailableTotal: number
    co2CostsTotal: number
    co2NeedsTotal: number
  }
  electricityTotals: {
    activeCoolingTotal: number
    ahuTotal: number
    generalEquipmentTotal: number
    grid: number
    lightsTotal: number
    totalElectricityCosts: number
  }
  heatTotals: {
    ahuTotal: number
    boilerTotal: number
    lightsTotal: number
    totalHeatCosts: number
  }
}

interface IEnergyCostsLine {
  co2: {
    co2Needs: number
    co2Available: number
    co2AdditionRequired: number
    cost: number
  }
  electricity: {
    activeCooling: number
    ahu: number
    cogen: number
    costs: number
    generalEquipment: number
    grid: number
    lights: number
    solar: number
  }
  heat: {
    ahu: number
    boiler: number
    heatCost: number
    lights: number
    pipeRail: number
    surplusHeat: number
    wasteHeat: number
  }
  month: string
  monthNo: number
  totalCost: number
}

interface IEnergyCostsRow {
  items: IEnergyCostsLine[]
  totals: IEnergyCostsTotal
}

export const EnergyCosts = ({ resultData, description, name, pageBreakAfter }: IGraphItem) => {
  if (!resultData) return null
  const data: IEnergyCostsRow = resultData[0] 
  if (!data) return null
  
  const tableColors: any = {
    lightblue: '#7e9bc8',
    blue: '#638ec6',
    darkblue: '#3c6494',
    grey: '#757171',
    green: '#63c384',
    yellow: '#ffc000',
  }

  let dataTable: any = {}
  dataTable.header = [
    [
      {label: '', colSpan: 2, rowSpan: 2, bgcolor: '#ffffff'},
      {label: 'Heat (MJ/m2)', colSpan: 7, bgcolor: '#3c6494'},
      {label: 'Electricity (kWh/m2)', colSpan: 8, bgcolor: '#3c6494'},
      {label: 'CO2 (kg/m2)', colSpan: 4, bgcolor: '#3c6494'},
      {label: '', rowSpan: 2, bgcolor: '#ffffff'},
    ],
    [
      {label: 'Needs', colSpan: 2, bgcolor: '#3c6494'},
      {label: 'Input', colSpan: 3, bgcolor: '#4978b1'},
      {label: 'Output', bgcolor: tableColors.lightblue},
      {label: 'Cost', bgcolor: tableColors.yellow},
      {label: 'Needs', colSpan: 4, bgcolor: '#3c6494'},
      {label: 'Input', colSpan: 2, bgcolor: '#4978b1'},
      {label: 'Output', bgcolor: tableColors.lightblue},
      {label: 'Cost', bgcolor: tableColors.yellow},
      {label: 'Needs', bgcolor: '#3c6494'},
      {label: 'Avail.', bgcolor: '#3c6494'},
      {label: 'Short', bgcolor: '#3c6494'},
      {label: 'Cost', bgcolor: tableColors.yellow},
    ],
    [
      {label: 'Month', vertical: 'true', bgcolor: '#4472c4'},
      {label: 'Month (text)', vertical: 'true', bgcolor: '#4472c4'},
      {label: 'AHU', vertical: 'true', width: '2x', bgcolor: '#3c6494'},
      {label: 'Pipe rail', vertical: 'true', bgcolor: '#3c6494'},
      {label: 'Lights', vertical: 'true', bgcolor: '#4978b1'},
      {label: 'Waste heat', vertical: 'true', bgcolor: '#4978b1'},
      {label: 'Boiler', vertical: 'true', width: '2x', bgcolor: '#4978b1'},
      {label: 'Surplus heat', vertical: 'true', bgcolor: tableColors.lightblue},
      {label: 'Heat cost (€/m2)', vertical: 'true', bgcolor: tableColors.yellow},
      {label: 'Lights', vertical: 'true', bgcolor: '#3c6494'},
      {label: 'AHU', vertical: 'true', bgcolor: '#3c6494'},
      {label: 'Active cooling', vertical: 'true', bgcolor: '#3c6494'},
      {label: 'General equipment', vertical: 'true', bgcolor: '#3c6494'},
      {label: 'Cogen', vertical: 'true', bgcolor: '#4978b1'},
      {label: 'Grid', vertical: 'true', width: '2x', bgcolor: '#4978b1'},
      {label: 'Solar', vertical: 'true', bgcolor: tableColors.lightblue},
      {label: 'Electricity cost (€/m2)', vertical: 'true', bgcolor: tableColors.yellow},
      {label: 'CO2 needs', vertical: 'true', bgcolor: '#3c6494'},
      {label: 'CO2 available', vertical: 'true', bgcolor: '#3c6494'},
      {label: 'CO2 too short', vertical: 'true', bgcolor: '#3c6494'},
      {label: 'CO2 cost (€/m2)', vertical: 'true', bgcolor: tableColors.yellow},
      {label: 'Total cost (€/m2)', vertical: 'true', bgcolor: tableColors.yellow},
    ]
  ]

  const formatNumber = (value: number, decimal: number = 1) => {
    return (value > 0 ? value.toFixed(decimal) : '-')
  }

  const calculatePerc = (value: number, total: number) => {
    return (total > 0 ? ((value / total) * 100) : 0)
  }

  dataTable.body = data.items

  let sumTotalCosts = 0
  data.items.map((row: IEnergyCostsLine) => {
    sumTotalCosts += row.totalCost
  })

  const maxAHU: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.heat.ahu))
  const maxLights: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.heat.lights))
  const maxBoiler: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.heat.boiler))
  const maxHeatCost: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.heat.heatCost))
  const maxELights: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.electricity.lights))
  const maxEAHU: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.electricity.ahu))
  const maxEAC: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.electricity.activeCooling))
  const maxEGE: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.electricity.generalEquipment))
  const maxEGrid: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.electricity.grid))
  const maxECosts: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.electricity.costs))
  const maxCo2Needs: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.co2.co2Needs))
  const maxCo2Available: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.co2.co2Available))
  const maxCo2AdditionRequired: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.co2.co2AdditionRequired))
  const maxCo2Cost: number = Math.max.apply(null, dataTable.body.map((obj: IEnergyCostsLine) => obj.co2.cost))

  return (
    <Styled.Container before={pageBreakAfter ? "always" : "auto"}>
      <Typography variant="h2">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box pb={2}>
        <Styled.TableContainer>
          <Table>
            <TableHead>
            {dataTable.header.map((row: [], rowIndex: number) => (
              <TableRow key={rowIndex}>
                {row.map((cell: any, cellIndex: number) => (
                  <Styled.Th 
                    component="th" 
                    {...cell}
                    key={cellIndex}>
                      <div><span>{cell.label}</span></div>
                  </Styled.Th>
                ))}
              </TableRow>
            ))}
            </TableHead>
            <TableBody>
              {dataTable.body.map((row: IEnergyCostsLine, rowIndex: number) => (
                <Styled.TableRow key={rowIndex}>
                  <Styled.Td>{row.monthNo}</Styled.Td>
                  <Styled.Td>{row.month}</Styled.Td>
                  <Styled.Td perc={calculatePerc(row.heat.ahu, maxAHU)} percbg={tableColors.blue}><span>{formatNumber(row.heat.ahu)}</span></Styled.Td>
                  <Styled.Td>{formatNumber(row.heat.pipeRail)}</Styled.Td>
                  <Styled.Td perc={calculatePerc(row.heat.lights, maxLights)} percbg={tableColors.blue}><span>{formatNumber(row.heat.lights)}</span></Styled.Td>
                  <Styled.Td>{formatNumber(row.heat.wasteHeat)}</Styled.Td>
                  <Styled.Td perc={calculatePerc(row.heat.boiler, maxBoiler)} percbg={tableColors.blue}><span>{formatNumber(row.heat.boiler)}</span></Styled.Td>
                  <Styled.Td>{formatNumber(row.heat.surplusHeat)}</Styled.Td>
                  <Styled.Td perc={calculatePerc(row.heat.heatCost, maxHeatCost)} percbg={tableColors.yellow}><span>{formatNumber(row.heat.heatCost)}</span></Styled.Td>
                  <Styled.Td perc={calculatePerc(row.electricity.lights, maxELights)} percbg={tableColors.green}><span>{formatNumber(row.electricity.lights)}</span></Styled.Td>
                  <Styled.Td perc={calculatePerc(row.electricity.ahu, maxEAHU)} percbg={tableColors.green}><span>{formatNumber(row.electricity.ahu)}</span></Styled.Td>
                  <Styled.Td perc={calculatePerc(row.electricity.activeCooling, maxEAC)} percbg={tableColors.green}><span>{formatNumber(row.electricity.activeCooling)}</span></Styled.Td>
                  <Styled.Td perc={calculatePerc(row.electricity.generalEquipment, maxEGE)} percbg={tableColors.green}><span>{formatNumber(row.electricity.generalEquipment)}</span></Styled.Td>
                  <Styled.Td>{formatNumber(row.electricity.cogen)}</Styled.Td>
                  <Styled.Td perc={calculatePerc(row.electricity.grid, maxEGrid)} percbg={tableColors.green}><span>{formatNumber(row.electricity.grid)}</span></Styled.Td>
                  <Styled.Td>{formatNumber(row.electricity.solar)}</Styled.Td>
                  <Styled.Td perc={calculatePerc(row.electricity.costs, maxECosts)} percbg={tableColors.yellow}><span>{formatNumber(row.electricity.costs)}</span></Styled.Td>
                  <Styled.Td perc={calculatePerc(row.co2.co2Needs, maxCo2Needs)} percbg={tableColors.grey}><span>{formatNumber(row.co2.co2Needs)}</span></Styled.Td>
                  <Styled.Td perc={calculatePerc(row.co2.co2Available, maxCo2Available)} percbg={tableColors.grey}><span>{formatNumber(row.co2.co2Available)}</span></Styled.Td>
                  <Styled.Td perc={calculatePerc(row.co2.co2AdditionRequired, maxCo2AdditionRequired)} percbg={tableColors.grey}><span>{formatNumber(row.co2.co2AdditionRequired)}</span></Styled.Td>
                  <Styled.Td perc={calculatePerc(row.co2.cost, maxCo2Cost)} percbg={tableColors.yellow}><span>{formatNumber(row.co2.cost)}</span></Styled.Td>
                  <Styled.Td perc={0} percbg={tableColors.yellow}><span>{formatNumber(row.totalCost)}</span></Styled.Td>
                </Styled.TableRow>
              ))}
                <Styled.TableRow total="true">
                  <Styled.Td>Total</Styled.Td>
                  <Styled.Td></Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.heatTotals.ahuTotal)}</Styled.Td>
                  <Styled.Td>-</Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.heatTotals.lightsTotal)}</Styled.Td>
                  <Styled.Td>-</Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.heatTotals.boilerTotal)}</Styled.Td>
                  <Styled.Td>-</Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.heatTotals.totalHeatCosts)}</Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.electricityTotals.lightsTotal)}</Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.electricityTotals.ahuTotal)}</Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.electricityTotals.activeCoolingTotal)}</Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.electricityTotals.generalEquipmentTotal)}</Styled.Td>
                  <Styled.Td></Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.electricityTotals.grid)}</Styled.Td>
                  <Styled.Td>-</Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.electricityTotals.totalElectricityCosts)}</Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.co2Totals.co2NeedsTotal)}</Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.co2Totals.co2AvailableTotal)}</Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.co2Totals.co2AdditionRequiredTotal)}</Styled.Td>
                  <Styled.Td>{formatNumber(data.totals.co2Totals.co2CostsTotal)}</Styled.Td>
                  <Styled.Td>{formatNumber(sumTotalCosts)}</Styled.Td>
                </Styled.TableRow>
            </TableBody>
          </Table>
        </Styled.TableContainer>
      </Box>
    </Styled.Container>
  )
}